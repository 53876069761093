// ------------------------------------------------------------------------- Giving form

body.donate, body.truthpartners
{
  /* Header */
  .donation-header {
    background-color: @primary-color;
    color: @white-00;
    padding: 20px 0 20px 0;
    text-align: left;

    .donation-header__link{
      display: inline-block;
      width: 80px;
      height: 40px;
      margin-right: 20px;
      vertical-align: middle;

      .donation-header__logo{
        display: block;
        width: 100%;
        height: 100%;

        path {
          fill: @white-00;
        }

      }
    }

    .donation-header__title{
      margin: 0;
      display: inline-block;
      vertical-align: middle;
      padding-left: 20px;
      border-left: 1px solid @blue-lighten-04;
    }

  }

  /* Snippet */
  .donation-message {
    padding: 20px;
    background-color: @muted-background;
  }

  .donation-amount {
    margin: 20px 0 20px 0;
    .give_monthly__wrapper {
      margin: 20px 0;
    }
  }

  .donate-attention {
    display: inline-block;
    padding: 5px 10px;
    background: @ui-yellow-lighten-01;
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
  .donate-attention--gap {
    margin-top: 12px;
  }
  .donate-attention--bottom-gap {
    margin-bottom: 20px;
  }
  .donate-attention--muted {
    background: @white-darken-01;
    color: @taupe-lighten-01;
  }

  /* re-order look inside button */
  .look-inside_launch {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    img {
      height: 200px;
      width: 100%;
      object-fit: contain;
      object-position: center center;
    }

    .btn {
      margin-top: -35px;
      span {
        display: none;
      }
    }
  }

  /* truthpartner explanation callout area */
  .explanation{
    border: 1px solid @border-color;
    border-radius: 8px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    @media(min-width: @screen-sm-max) {
      flex-direction: row;
    }

    .explanation__info {
      box-sizing: border-box;
      padding: 20px 20px 0 20px;
      @media(min-width: @screen-sm-max)
      {
        flex-direction: row;
        padding: 0 0 0 20px;
      }
      flex: 1;

      * {
        line-height: 1.3em;
        margin-top: 12px;
      }
      a {
        text-decoration: underline;
      }
    }
    .explanation__image {
      flex: 0.5;
      img {
        width: 100%;
        max-width: 360px;
        height: auto;
        border-radius: 8px;
      }
    }
  }

  .app_station_picker_form {
    margin-bottom: 20px;
  }
  .how_do_you_listen_picker .help-block {
    display: none;
  }
}


.payment-monthly {
  .hide-monthly {
    display: none !important;
  }
}


.donate_sidebar {
  padding: 5px 30px;
  h5 {
    margin-bottom:10px;
  }
  .donate-file-list {
    list-style: none;
    .donate-file-list__item {
      i {
        font-size: 24px;
        vertical-align: middle;
        padding-right: 5px;
      }
      margin: 5px;
    }
  }
}

.signin_well {
  a.btn {
    margin: 0 15px;
  }
  @media (max-width: @screen-sm-max) {
    text-align: center;
  }
}

/** Handle payment type form display toggles **/
.giving-form {
  .volitional {
    display: none;
  }

  &.payment-cc {
    .volitional.is-cc {
      display: block;
    }
  }
  &.payment-paypal {
    .volitional.is-paypal {
      display: block;
    }
  }
  &.payment-monthly {
    .volitional.is-monthly {
      display: block;
    }
    .volitional.not-monthly {
      display: none !important;
    }
  }
  &.payment-mail {
    .volitional.is-mail {
      display: block;
    }
    .mail-disabled {
      opacity: .3;
      cursor: not-allowed;
    }
  }

  .tp-by-mail {
    background: @muted-background;
    border: 1px solid @border-color;
    border-radius: 2px;
    overflow: auto;
    padding: 20px;
    max-width: 600px;
    margin-bottom: 60px;
    margin-top: 30px
  }

}

.donate-jsform {
  a.active
  {
    font-weight:bold;
    &::before
    {
      content:'\25BA';
    }
  }
  counter-reset: section;

  .step-number::before {
    counter-increment: section;
    content: counter(section);
  }

  .monthly_toggle {
    margin-left: 10px;
    .monthly_toggle__header {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .monthly_toggle__label {
      font-weight: bold;
      vertical-align: middle;
      margin-left: 5px;
    }
    .monthly_toggle__message {
      margin-left: 24px;
      font-size: 1em;
    }

    .give_monthly__wrapper.disabled {
      opacity: .4;
      cursor: not-allowed;
    }
  }

  .payment-type {
    overflow: auto;
    width: 100%;
    margin-bottom: 10px;
    .payment-type__wrapper {
      float: left;
      margin-right: 15px;
      &.disabled {
        cursor: not-allowed;
      }
      &.active {
        .payment-type__label {
          .tfl-btn-primary;
          // The .tfl-btn-primary styling results in button 2px shorter than its sibling inactive button.
          // Add an extra border to compensate.
          border: 1px solid @btn-primary-border;
        }
      }


    }
    .payment-type__input {
      opacity: 0;
      position: fixed;
      width: 0;
    }
    .payment-type__label {
      width: 125px;
      text-align: center;
    }
    &.tp {
      .payment-type__label {
        width: 160px;
        .small {
          font-size: 12px;
          vertical-align: middle;
          margin-left: 2px;
        }
      }
    }
  }

  .form-section__saved_payments {
    margin-bottom: 35px;
  }

  .form-section {
    h6 {
      color: @black;
      font-weight: bold;
      font-size: 16px;
      margin-top: 30px;
    }
  }

  .billing_address_box,
  .shipping_address_box {
    line-height: 1.25em;
    font-size: 1em;
    text-indent: 10px;

    .billing_address_box__details,
    .shipping_address_box__details {
      float: left;
    }
  }
}


.form-hidden {
  display: none;
  &.has-error {
    display: block;
  }
}


ul.vflag-container {
  overflow:hidden;
  margin:0;

  li
  {
    float:left;
    margin-right:10px;
  }
}

.donation_change_link {
  height: 100%;
  vertical-align: top;
  text-align: right;
  cursor: pointer;
}


body.donate {
  .footer-nav-subscribe-outer-wrapper, .social-wrapper, .promotions
  {
    display: none;
  }
  .available-formats
  {
    height:auto;
    overflow:hidden;
  }
  #subnav-toggle .visible-titles .caret
  {
    margin-top:16px;
  }
  #subnav-toggle .visible-titles
  {
    height: 40px;
  }
  #subnav-items .sidebar-nav li.active a, #subnav-items li.section-title
  {
    padding-left:10px;
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled]
.form-control {
  color: @muted-text;
  background-color: @white-darken-02;
}
.how_do_you_listen ul,
.recurrence-day {
  margin-top:0 !important;
  > li {
    display: inline;
  }
  label {
    .btn;
    margin-bottom: 10px;
  }
}
.form-horizontal {
  .label-col {
    .make-sm-column(4);
  }
  .input-col {
    .make-sm-column(8);
  }
  .gift-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    .label-col {
      margin-left: -7px;
    }
    .input-col {
      margin-right: -7px;
    }
    @media (max-width: @screen-xs-max) {
      .form-control {
        margin-bottom: 0;
      }
    }
  }
  .cc-wrapper {
    [class^="ti-"] {
      right: 25px;
    }
  }
  .cc-expiration-select {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(3);
  }
  .form-group-ccv .input-col > input {
    max-width: 100px;
  }
}
.form-compact {
  .form-giving-method {
    .make-sm-column(6);
  }
  .cc-expiration {
    .make-sm-column(4);
    @media (min-width: @screen-sm-min) {
      padding-left: 0;
    }
    .no-label-wrapper {
      @media (min-width: @screen-sm) {
        margin-left: 0;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .form-group-ccv {
    .make-sm-column(2);
    @media (min-width: @screen-sm-min) {
      padding-left: 0;
    }
  }

  .form-section-free {
    padding-top: @grid-gutter-width;
    padding-bottom: @grid-gutter-width;
    .product-thumbnail {
      img {
        max-height: 180px;
        margin-bottom: @grid-gutter-width;
      }
    }

  }
}
.form-group {
  ul {
    .list-unstyled;
  }
}

.station_filter_highlight {
  border-color: @green-lighten-03;
  border-width: 2px;
  font-weight: bold;
  font-style: italic;
  .box-shadow(inset 0 0 5px rgba(0,0,0,.5));
}

section.how_do_you_listen_picker
{
  ul.nav-tabs.station_selection_type, .local_stations_wrap, .local_stations_wrap ul, ul.stationpick, .well
  {
    margin:0;
  }
  .tab-content
  {
    background: @muted-background;
    border:1px solid @border-color;
    border-top:0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding:15px;
  }
  .nav-tabs > li > a
  {
    border:1px solid @border-color;
    border-bottom:none;
  }
  .nav-tabs > li.active > a
  {
    background: @muted-background;
  }
  .tabcontainer
  {
    overflow:hidden;

    .official
    {
      padding-right:10px;
    }
    .networks
    {
      padding:10px;
      background: @muted-background;
    }
    label.select_station_lbl
    {
      padding-top:0 !important;
    }
    .radio
    {
      min-height:0;
    }
  }
}

.cc-wrapper {
  position: relative;
  .form-control {
    padding-right: 30px;
  }
  .ti-lock {
    position: absolute;
    right: 9px;
    top: 9px;
    color: @link-color;
    color: @muted-text;
  }
}
.cc-valid-card-wrapper {
  .make-sm-column(3);
  .make-xs-column(2);
}

.cc-exp-month {
  .make-sm-column(5);
  .make-xs-column(5);
  padding:0 4px 0 0;
}
.cc-exp-year {
  .make-sm-column(7);
  .make-xs-column(7);
  padding: 0;

}
.no-label-wrapper {
  .make-sm-column(8);
  .make-sm-column-offset(4);
  @media (max-width: @screen-xs-max) {
    clear: both;
    .alert {
      margin-top:0;
    }
  }
}
.step-wrapper {
  .make-sm-column(2);
  .make-xs-column(8);
}
.stepper-wrapper {
  .make-sm-column(2);
  .make-xs-column(4);
}
.gift-calc-wrapper {
  .make-sm-column(2);
}
.gift-calc-display-wrapper {
  .make-sm-column(4);
}

.shipping-info {
  padding-bottom: @grid-gutter-width;
  display: block;
  color: @muted-text;
  .well;
  background-color: rgba(242, 241, 237, .5);
  h3 {
    margin-top: 0;
    .flag {
      position: relative;
      top: -2px;
    }
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
    > li {
      font-size: 1.1em !important;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.required-key {
  text-transform: none !important;
  letter-spacing: 0;
  sup {
    top: -0.2em;
  }
}

sup.required {
  color: @brand-danger;
}

.giving-form, .standard-form {
  @media (max-width: @screen-xs-max) {
    .form-control {
      margin-bottom: 5px;
    }
    .gift-amount.form-control {
      margin-bottom:0;
    }
  }
  .foreign-note {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 0 0 0;
  }
  h4 {
    text-transform: uppercase;
    font-size: 20px;
    .kern-wide;
    @media (max-width: @screen-xs-max) {
      margin-top: @line-height-computed;
      margin-bottom: @line-height-computed;
    }

    &.only-checkboxes {
      margin-bottom: 0.5em;
    }
  }
  .form-group-free {
    h4 {
      font-size: 23px;
      text-transform: none;
      margin: 0 0 .5em;
    }
    .clearfix;
    .product-thumbnail {
      @media (min-width: @screen-sm-min) {
        width: 33%;
        float: left;

        padding-left:0;
        padding-right: 5%;
      }

      text-align: center;
      i[data-content]
      {
        color: @support-color;
        font-size:2.5rem;
      }
    }
    div.radio {
      float: left;
      padding-left: 0;
      li {
        .clearfix;
        display: block;
        margin-bottom: 10px;
        margin-bottom: 5px;
      }
    }
  }
  .from-group-cd {
    div.radio {
      width: 100% !important;
    }
  }
  .initials-input {
    .form-control {
      max-width: 100px;
    }
  }
  .row-xtra-info {
    padding-bottom: @grid-gutter-width;
  }

  @media (max-width: @screen-sm-max) {
    &.form-horizontal {
      .radio {
        ul > li {
          display: block;
          float: none !important;
          clear: both;
        }
        .available-formats {
          height: auto;
          .format {
            display: block;
            float: none;
            width: auto;
          }
        }
      }
    }
  }
}

// Payment iFrame additions
#new_card__wrapper {
  width: 100%;
}

.cc_container {
  width: 100%;

  .clover_container {
    width: 100%;

    .error {
      color: @red-00;
      font-weight: bold;
    }


    iframe {
      width: 100%;
      background-color: @white-darken-01;
      border-radius: 2px;
      border: 1px solid @white-darken-05;
      height: 240px;
      padding-left: 5px;
    }
  }

  .iframe_container,
  .eft_iframe_container {
    background: transparent url("/static/images/ajax-loader.gif") 50% 50% no-repeat;
    min-height: 180px;
  }

  .iframe_wrapper {
    max-width: 600px;

    .ccv_help_block {
      text-align: right;
      margin-top: 0;
      margin-right: 0;
      padding-top: 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .cc_errors {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
    padding-top: 15px;
    border-top: none;
    margin: 10px 0 0 0;
    li {
      line-height: 1;
      text-indent: 10px;
    }
  }

  .cc_privacy {
    float: left;
    text-indent: 0px;
    display: inline-block;
    font-size: 14px;
    line-height: 16px
  }
  .ccv_help {
    float: right;
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
  }
}

.giving-form {
  .mom-product {
    width: 33%;
    float: left;
    padding:20px;
  }
  .mom-options {
    width: 50%;
    float: left;
    .mom-message {
      font-size: 16px;
      line-height: 1.5em;
    }
  }

  .alert-message.payment-encoded {
    background: @muted-background;
    border: 1px solid @border-color;
    color: @muted-text;
    border-radius: 2px;
    padding: 20px 30px;
    p {
      .btn {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: @screen-md-max) {
      .mom-product, .mom-options
      {
        width:100%;
        float:none;
        padding:20px;
      }
  }
  .input-group {
    width: 100%;
  }
  .list-group-item {
    font-size: 1em;
  }
  iframe, object, embed {
    max-width: 100%;
  }
  .wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  .wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .btn-donate {
    width: 100%;
    padding: 15px;
    font-size: 1.5em;
    margin-top: 20px;
    @media (max-width: @screen-tiny-max) {
      font-size: 1.2em;
    }
  }
  h5 {
    font-weight: normal;
    color: @muted-text;
    font-size: 14px;
    &.product-title {
      color: @text-color;
      margin-bottom: 0;
      font-size: 18px;
    }
    &.resource_offering {
      font-size: 18px;
      color: @text-color;
      margin-top: 15px;
    }
  }
  hr {
    margin: 35px 0 40px 0;
    &.spacing {
      border: none;
    }
  }
  .giving-method-label {
    margin-bottom: 0;
  }
  @media (max-width: @screen-xs-max) {
    .Resources {
      ul {
        li {
          width: 100%;
          label {
            width: 100%;
          }
        }
      }
    }
  }
  .hold-gift-text {
    position: relative;
    background-color: @muted-background;
    border: none;
    color: #a78447;
    font-size: .85em;
    padding: 15px;
    margin-bottom: 30px;
  }
}

a.bordered {
  border: 1px solid @support-color;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    background-color: @muted-background;
  }
}

.giving-methods {
  margin-top: 0 !important;
  margin-bottom:0 !important;
  .giving-method {
    margin-right: 10px;
    margin-bottom:0;
    &:nth-child(3) {
      margin-right:0;
    }
    > a {
      border: 1px solid @muted-background;
      color: @muted-text;
      position: relative;
      min-width: 106px;
      text-align: center;
      padding-bottom: 20px;
      @media (max-width: @screen-tiny-max) {
        min-width: 80px !important;
      }
      &:hover {
        border-color: @muted-text;
      }
      [class^="ti-"], [class*=" ti-"] {
        // height: 30px;
        // width: 30px;
        display: inline-block;
        color: currentColor;
        font-size: 26px;
      }
      > span {
        background: @muted-background;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left:0;
        height: 20px;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    &.active {
      > a, a:focus {
        border-color: @brand-success;
        background-color: @white-00;
        color: @brand-success;
        position: relative;
        > span {
          background-color: @brand-success;
          color: @white-00;

        }
      }
      &:after {
        content:"";
        position: absolute;
        z-index: 2;
        bottom: -5px;
        left: 50%;
        margin-left: -10px;
        display: inline-block;
        height: 5px;
        width: 20px;
        font-size: 20px;
        line-height: 5px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid @brand-success;
      }

      &:hover {
        > a {
          background-color: @white-00;
          border-color: darken(@brand-success, 10%);
          color: darken(@brand-success, 10%);

          &:after {
            color: darken(@brand-success, 10%);
          }
        }
      }
    }
  }
}

ul.tp-actions {
  list-style: none;
  border-top: 2px solid @border-color;
  border-bottom: 2px solid @border-color;
  padding: 5px 0 15px 0;
  img
  {
    max-width: 200px;
  }
  li {
    border-bottom: 1px solid @border-color;
  }
  ul.list-unstyled {
    li {
      border-bottom: none;
    }
  }
  .radio {
    li {
      border-bottom: none;
    }
  }
  ul.tp-cancel {
      list-style: none;
      li {
          padding: 3px;
          border: none;
      }
  }
}
.explainbox-textarea {
  min-width: 80%;
}

.gift-wrapper {
  .input-group {
    .input-group-addon {
      background-color: @warm-gray-lighten-03;
      color: @white-00;
    }
  }
  //padding: @line-height-computed @line-height-computed*1.5 0;
  max-width: 400px;
  .btn-primary {
      min-width: 13.75%;
      margin-bottom: 4px;
      &.input-group-addon {
        width: 60px;

        &:hover {
          background-color: @blue-lighten-02;
        }
      }
      @media (max-width: @screen-xs-max) {
        min-width: 33%;
        margin-right: .5%;
        &.input-group-addon {
          /*
          width: 45px;
          max-width: 45px;
          */
          padding: 0;
          min-width: 45px
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media (max-width: @screen-tiny-max) {
        margin-right: .5% !important;
      }
      @media (min-width: @screen-sm-min) {
        min-width: 16.467%;
        margin-right: .2%;
        &:last-child {
          margin-right: 0;
        }
      }
      &.selected {
        background: @blue-00 !important;
        border-color: @blue-darken-01;
        font-weight: bold;
        position: relative;
        .box-shadow(inset 0 2px 1px rgba(0,0,0,.1));
      }
  }
  .help-block {
    margin-bottom: 0;
  }
}

.form-row {
  .make-row();
}
.form-row-cc {
  padding-top:@grid-gutter-width;
  padding-bottom:@grid-gutter-width;
}

.row-name {
  .make-row;
  > div {
    .make-sm-column(6);
  }
}
.row-phone-email {
  .make-row;
  > div {
    .make-sm-column(6);
    @media (max-width: @screen-xs-max) {
      &.email-group .control-label {
        margin-top: 1em !important;
      }
    }
  }
}
.row-xtra-info {
  .make-row;
  > div {
    .make-xs-column(12);
    .make-md-column(4);
    .add-margin-bottom-except-last;
  }
}
.row-city-state-zip {
  .make-row;
  > div {
    .make-xs-column(12);
    .make-sm-column(4);
    .add-margin-bottom-except-last;
  }
}
.subscriptions {
  .form-group {
    .group-option {
      @media (min-width: @screen-sm-min) {
        padding-right: 0;
        &:nth-child(3) {
          padding-right: 5px;
        }
      }
      @media (max-width: @screen-xs-max) {
        width: 100%;
        margin-bottom: 5px;
      }
      @media (min-width: @screen-sm-min) {
        width: 33%;
      }
    }
    .group-options {

      label {
        .btn;
        text-align: left;
        border: 1px solid @muted-background;
        background-color: @white-00;
        color: @taupe-darken-01;
        // .btn-default;
        white-space: normal;
        display: block;
        &:hover {
          border-color: @blue-00;
        }

        &.checked {
          background-color: @muted-background;
          border-color: @blue-00;
          color: @text-color;
        }
      }
    }
  }
}

.how_do_you_listen {
  padding-bottom: @grid-gutter-width !important;
}

.errorlist {
  .list-unstyled;
}

.alert {
  .errorlist {
    margin-top:0;
    margin-bottom:0;
    li {
      margin-bottom: 0;
      font-weight: normal;
    }
  }
}

.valid-cards {
  .img-retina("/images/bg-1x.png", "/img/bg-2x.png", 100px, 100px);
}

.tp-login-head {
  .login-info {
    p {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .monthly_givers_wrapper {
    background: @white-00;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);
    border-radius: 8px;
    overflow: auto;
    .img-responsive {
      float: right;
      max-height: 150px;
      padding: 10px;
    }
    .monthly_givers_info {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      padding: 40px;
      margin-right: 150px;
    }
    @media (max-width: @screen-md-max) {
      .monthly_givers_info {
        margin-right: 0;
        padding: 25px;
      }
    }
    @media (max-width: @screen-xs-max) {
      .img-responsive {
        float: none;
        text-align: middle;
        margin: 0 auto;
        display: block;
      }
      .monthly_givers_info {
        padding: 0 10px 10px 10px;
        text-align: center;
      }
    }
  }
}




@media (min-width: @screen-sm-min) {
  .cc-expiration-select:nth-of-type(1) {
    @media (max-width: @screen-xs-max) {
      margin-bottom: @line-height-computed;
    }
  }
}


.copy-address {
  margin-left: 5.5em;
  font-size: 75%;
  text-transform: none;

  label {
    font-weight: normal;
  }
}

.preview-donation {
  .label-col {
    .make-sm-column(3);
  }
  .input-col {
    .make-sm-column(9);
  }
  @media (min-width: @screen-sm) {
    .form-horizontal .control-label {
      text-align: left;
    }

  }
  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    color: @text-color;
    background-color: darken(@well-bg, 3%);
  }
  .input-group-addon {
    background-color: darken(@well-bg, 7%);
  }
}

#ccvModal {
  @media (min-width: @screen-sm-min) {
    .modal-dialog {
      width: 80%;
    }
  }
}

#ccvModal, #ccprivacyModal {
  @media (max-width: @screen-xs-min) {
    max-width: 90%;
    left: 5%;
    margin-left:0;
  }
  h1, h2, h3, h4, h5, p, ol, ul {
    margin: 1em 0;
  }
  .modal-content {
    padding: 15px;
    @media (min-width: @screen-sm-min) {
      padding: 50px;
    }
    .page {
      background: @white-00;
      font-size: 14px;
      font-family: @font-family-sans-serif;
      padding: 15px;
    }
  }
  @media (min-width: @screen-sm-min) {
    .modal-dialog {
      border-radius: 2px;
      .modal-body {
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .close {
    font-size: 24px;
    background: transparent;
    text-shadow: none;
    color: @text-color !important;
    width: 34px;
    height: 34px;
    text-align: center;
    margin-bottom:10px;
    position: absolute;
    top: 10px;
    right:10px;
    z-index: 4;
    @media (max-width: @screen-xs-max) {
      position: relative;
      top:0;
      right:0;
    }
  }
}

.intl-tel-input .country-list {
  width: auto !important;
}

@important-msg-bg:  #FEDC63;

#premium_message {
    padding: 5px;
    display: block;
    background-color: @important-msg-bg;
    text-align: center;
    margin-top: 10px;
    position: relative;
    border-radius: 3px;
    &:after {
      content:"";
      display: block;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 20px solid @important-msg-bg;
      position: absolute;
      top:-20px;
      left: 45px;
      z-index: 100;
      overflow: overlay;
    }
}
.monthlyformupdate {
  #premium_message{
    &:after {
      display: none;
    }
  }
}
.monthly_message {
  padding: @grid-gutter-width/3;
  display: block;
  background-color: @important-msg-bg;
  text-align: center;
  margin-top: 5px;
  position: relative;
  &:after {
    content:"";
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-bottom: 12px solid @important-msg-bg;
    position: absolute;
    top:-12px;
    left: 182px;
    z-index: 1;
    @media (max-width: @screen-xs-max) {
      left: 75%;
    }
  }
  &.right::after
  {
    left:80%;
    @media (max-width: @screen-xs-max)
    {
      left: 50%;
    }
  }
}

.important-msg {
  display: block;
  background-color: @important-msg-bg;
  line-height: 1.5;
  padding: 15px;
  position: relative;
  margin-bottom: 0;
  &:after {
    content:"";
    display: block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 60px solid @important-msg-bg;
    position: absolute;
    top: 50%;
    left: -60px;
    margin-top: -8px;
    z-index: 3;
  }
}

.donate-review-page {
  .donate_review {
    padding-top: 25px 10px;
    max-width: 800px;
    margin: 0 auto;

    h4 {
      margin-top: 30px;
    }

    .review_section {
      margin-top: 25px;

      .review_heading {
        width: 100%;
        padding: 10px 0;

        .review_title {
          text-transform: uppercase;
          font-weight: normal;
          line-height: 1;
          color: @muted-text;
          font-size: 18px;
          display: inline-block;
        }

        .review_edit {
          float: right;
          display: inline-block;
        }

        button {
          padding: 2px 20px;
        }
      }
      .review_body {
        background-color: @muted-background;
        border-top: 1px solid @border-color;

        .review_row {
          border-bottom: 1px solid @border-color;
          padding: 0 15px;
          height: 45px;
          vertical-align: middle;
          line-height: 45px;
          clear: both;

          .review_label {
            text-transform: uppercase;
            font-size: 14px;
            color: @muted-text;
            display: inline-block;
          }

          .review_value {
            font-weight: bold;
            color: @text-color;
            float: right;
            display: inline-block;

            .money {
              font-size: 22px;
            }
          }
        }
        .review_row_full {
          border-bottom: 1px solid @border-color;
          padding: 10px 0;
          vertical-align: middle;

          .review_label {
            text-transform: uppercase;
            font-size: 14px;
            color: @muted-text;
            display: block;
            padding: 5px 15px;
          }

          .review_value {
            font-weight: bold;
            color: @text-color;
            display: block;
            padding: 5px 10px;

            address {
              margin-left: 30px;
            }
          }
          .address-indentation {
            margin-left: 30px;
          }
        }
      }
    }
    .review-group {
      width: 100%;
      margin: 40px 0;
      .btn {
        width: 100%;
        @media (max-width: @screen-tiny-max)
        {
          font-size:90%;
        }
      }
    }
  }
}

/* Apple Pay */
/* CSS */
.apple-pay-button {
  display: inline-block;
  width: 100%;
  height: 40px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: donate; /* Use any supported button type. */
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}
