#searchpage {
   
    .container {
        small.results {
            margin-top: 0;
            margin-bottom: 10px;
        }
    

        .search_controls {

            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .search_controls__sort {
                flex: 0 0 auto;
                
                ul {
                    width: 100%;
                    padding: 10px 5px;
                    font-size: 14px;
                    line-height: 32px;
                    color: @taupe-darken-01;

                    li {
                        padding: 0 10px;
                        &:hover {
                            background-color: @white-darken-02;
                            opacity: 0.8;
                            cursor: pointer;
                        }
                        &.active {
                            &::after {
                                content: "\24";
                                font-size: 14px;
                                line-height: 32px;
                                display: inline-block;
                                float: right;
                                font-family: "tfl-global" !important;
                                font-style: normal !important;
                                font-weight: normal !important;
                                font-variant: normal !important;
                                text-transform: none !important;
                                speak: none;
                                -webkit-font-smoothing: antialiased;
                            }
                        }
                    }
                }
                
            }

            .search_controls__filter {
                flex: 0 0 auto;

                .filters_balloon {
                    background-color: @blue-00;
                    position: absolute;
                    bottom: 20px;
                    left: 80px;
                    font-size: 14px;
                    color: white;
                    padding: 5px;
                    text-align: center;
                    border-radius: 15px;
                    height: 30px;
                    width: 30px;
                    z-index: 10;
                }


                ul {
                    min-width: 280px;
                    padding: 10px;

                    li {
                        margin: 10px 0;
                        min-height: 32px;
                        color: @taupe-darken-01;

                        .search_filter_input {
                            display: none;
                        }

                        .option {
                            background-color: @white-darken-01;
                            padding: 12px;
                            border-radius: 5px;

                            &:hover {
                                cursor: pointer;
                                opacity: .8;
                            }

                            &.addt {
                                background-color: @white-00;
                                margin: 5px 0;
                            }

                            label {
                                display: flex;
                                gap: 8px;
                                justify-content: flex-start;
                                align-items: center;
                                cursor: pointer;
                                font-weight: normal;
                                font-size: 13px;
                                line-height: 13px;
                                padding: 0;
                                margin: 0;

                                .filter_option_text {
                                    flex: 1 1 auto;
                                
                                    &::before {
                                        content: " ";
                                        display: inline-block;
                                        flex: 0 0 auto;
                                        vertical-align: text-top;
                                        width: 16px;
                                        height: 16px;
                                        border: 2px solid @taupe-darken-01;
                                        border-radius: 3px;
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }

                        .secondary-option {
                            transition: all .1s ease-in;
                            height: 0;
                            overflow: hidden;
                            display: block;
                            margin-left: 13px;

                            label {
                                font-weight: normal;
                                font-size: 12px;

                                input {
                                    margin-right: 5px;
                                    accent-color: @blue-00;
                                }
                            }
                        }                        

                        &.active {
                            .option {
                                background-color: @blue-00;
                                color: @white-00;
                                font-weight: bold;

                                label {                                
                                    .filter_option_text {
                                        &::before {
                                            // ti-check
                                            content: "\24";
                                            font-size: 13px;
                                            border-color: @white-00;
                                            color: @white-00;
                                            font-family: "tfl-global" !important;
                                            font-style: normal !important;
                                            font-weight: normal !important;
                                            font-variant: normal !important;
                                            text-transform: none !important;
                                            speak: none;
                                            line-height: 1;
                                            -webkit-font-smoothing: antialiased;
                                        }                                    
                                    }
                                }
                            }
                            .secondary-option {
                                height: 64px;
                                transition: all .3s ease-in;
                                padding-top: 10px;
                            }
                        }

                        &.divider {
                            height: 1px;
                            background-color: @white-darken-05;
                            padding: 0;
                            min-height: 1px;
                            margin: 10px 0;
                        }

                        &.small_header {
                            font-size: 12px;
                            height: 12px;
                            min-height: 12px;

                            &.default_filters_header {
                                margin: 5px 0 10px 0;

                                .close_filters {
                                    font-size: 18px;
                                    margin-top: -5px;
                                    float: right;
                                    cursor: pointer;
                                    
                                    &:hover {
                                        opacity: 0.8;
                                    }
                                }
                            }
                        }

                        &.action_buttons {
                            margin-top: 15px;

                            button:active {
                                box-shadow: none;
                            }

                            .action_button__clear {
                                width: 25%;
                                text-align: right;
                                font-size: 12px;
                                float: right;
                                border-radius: 0;
                                border-color: transparent;

                                &:hover {
                                    opacity: .8;
                                }
                            }
                            .action_button__all {
                                background-color: @taupe-darken-01;
                                color: @white-00;
                                border-radius: 0;
                                width: 50%;
                                font-size: 12px;

                                &:hover {
                                    opacity: .8;
                                }
                            }
                        }
                    }
                }
            }

            button {
                min-width: 100px;
                font-size: 13px;
                color: @taupe-darken-01;

                &.btn-sort {
                    min-width: 168px;
                    text-align: left;

                    .svg-icon__wrapper {
                        float: right;
                        padding-right: 5px;

                    }
                }

                .svg-icon__wrapper {
                    height: 14px;
                    width: 14px;
                    position: relative;
                    padding-left: 5px;

                    svg {
                        object-fit: cover;
                        height: 100%;
                    }
                }
            }

        }
        .search_filter_pills {
            width: 100%;
            margin-bottom: 10px;

            .search_filter_pill {
                display: none;
                font-size: 12px;
                padding: 3px 10px;
                background-color: @taupe-00;
                color: @white-00;
                margin-right: 5px;


                &.active {
                    display: inline-block;

                    &:hover {
                        opacity: .8;
                        cursor: pointer;
                    }
                
                    &::after {
                        content: "✕";
                        padding-left: 10px;
                    }
                
                };
            }

        }


        &.search_results_container {
            &.loading {
                opacity: .3;
            }
            .scripture_suggestion {
                i {
                    font-size: 24px;
                    position: relative;
                    top: 5px;
                }
                a {
                    font-weight: bold;
                }
                color: @black-00;
            }
        }

        .search-results-wrapper {
            .search-result {
                margin: 0 0 (@line-height-computed) 0;
                padding-bottom:(@line-height-computed);
                border-bottom: 1px solid darken(@muted-background, 10%);

                &.featured-result {
                    padding: 25px;
                    background: @muted-background;
                    border: 1px solid @border-color;

                    h5 {
                        text-transform: uppercase;
                        font-style: italic;
                        font-weight: normal;
                        margin: 0;
                        padding: 0 0 5px 0;
                        color: @taupe-darken-01;
                    }

                    .search-title {
                        font-size: 22px !important;
                    }

                    .archive-highlight {
                        padding-top: 20px;
                        padding-left: 0;
                        border-left: none;
                    }

                }

                .archive-support {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    @media (min-width: @screen-sm-min) { 
                        margin-bottom: 10px;
                    }
                    @media (min-width: @screen-lg-min) {
                        margin-bottom: 0;
                    }
                    
                    .archive-photo-wrapper {
                        max-width: 325px;
                        min-width: 225px;
                        flex: 1 1 auto;
                        float: none;
                    }

                    .archive-description-wrapper {
                        min-width: 250px;
                        flex: 1 1 auto;
                        float: none;
                        margin-bottom: 0;

                        hr {
                            display: none;
                        }

                    }
                }
                .archive-highlight {
                    padding: 5px 0;
                    font-size: 16px;
                    line-height: 24px;
                    padding-left: 10px;
                    border-left: 4px solid @taupe-lighten-02;

                    em {
                        font-weight: bold;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    // XXS Screen Updates
    @media (max-width: @screen-xxs-max) {
        .container.search-header {
            padding: 10px 0;

            .search-sub-form {
                .btn-wrapper {
                    padding-right: 0;
                }
            }
        }
    }



}