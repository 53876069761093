.row.Error
{
    width:100%;
    margin:0 auto;

    .Feature
    {
        padding:2rem;

        p
        {
            min-height:70px;
        }
        img
        {
            display:block;
            width:100%;
            height:auto;
        }
    }

    p
    {
        color:#999;
        font-size:1.5rem;
        line-height:2rem;
    }

    input.form-control
    {
        width:140px;
        display:inline-block;
    }
}

.row.Error.Form
{
    margin-top:2rem;
}
@media(max-width:769px)
{
    .row.Error .Feature img
    {
        display:none;
    }
    .row.Error .Feature p
    {
        min-height:0;
    }
}