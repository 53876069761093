/*
Progress Bar
*/
.checkout-progress {
  position: relative;
  display: block;
  margin: 45px 0 45px 0;
  padding: 12px 0 0 0;
  border: 0;

  color: @taupe-darken-03;
  font-family: @font-family-sans-serif;
  font-size: 0;

  @media print {
    display: none;
  }

  &::before,
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12px;
    border-radius: 20px;
    background: @tan-lighten-05;
  }
  &::after {
    background: @green-00;
  }

  /* define width of green progress bar for each stage */
  &.checkout-progress--stage-1::after {
    width: 10%;
  }
  &.checkout-progress--stage-2::after {
    width: 30%;
  }
  &.checkout-progress--stage-3::after {
    width: 50%;
  }
  &.checkout-progress--stage-4::after {
    width: 70%;
  }
  &.checkout-progress--stage-5::after {
    width: 100%;
  }

  .checkout-progress__stage {
    position: static;
    display: inline-block;
    width: 20%;
    padding: 18px 0 0 0;
    margin: 0;
    list-style-type: none;
    vertical-align: top;
    font-size: 16px;
    text-align: center;
  }

  /* put a cart icon over the active stage */
  .checkout-progress__stage--active::before {
    content: " ";
    display: block;
    position: absolute;
    top: -13px;
    z-index: 100;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background: @green-00 url(/static/images/responsive/icon-nav-cart.svg)
      no-repeat center center;
    transform: translateX(-50%);
  }

  /* adjust the position of the cart icon */
  .checkout-progress__stage--active:nth-child(1)::before {
    left: 10%;
  }
  .checkout-progress__stage--active:nth-child(2)::before {
    left: 30%;
  }
  .checkout-progress__stage--active:nth-child(3)::before {
    left: 50%;
  }
  .checkout-progress__stage--active:nth-child(4)::before {
    left: 70%;
  }
  .checkout-progress__stage--active:nth-child(5)::before {
    left: auto;
    right: 0;
    transform: none;
  }

  /* On mobile, rather than trying to fit all 5 stages on the screen, just show the bars, the cart icon, and the active stage by... */
  @media (max-width: @screen-sm) {
    /*...first hiding all the stages and... */
    .checkout-progress__stage {
      display: none;
      width: 100%;
    }
  }
  /* ...then show (and bold) the active stage.  */
  .checkout-progress__stage--active {
    font-weight: 900;
    display: inline-block;
  }

  /* change the color on all completed stages */
  .checkout-progress__stage--done {
    color: @taupe-darken-01;
    font-weight: 600;
  }
}

.checkout {

  h1 {
    @media print {
      display: none;
    }
  }

  .header-simple {
    background: @primary-color;
    padding: 30px 0 30px 0;

    @media print {
      display: none;
    }

    .header-simple__logo {
      display: inline-block;
      width: 300px;
      height: 37px;
      background: url(/static/images/responsive/truth-for-life.svg) no-repeat left center;
      font-size: 0;
      vertical-align: middle;
    }
  }

  .body-simple {
    padding-bottom: 100px;
  }

  /*** checkout components ***/

  /*
    Asterisk
    */
  .checkout-required::after {
    content: "*";
    display: inline;
    margin: 5px;
    vertical-align: top;
    color: @ui-red-00;
    font-size: 16px;
    font-weight: 600;
  }

  /*
    Title
    */
  .checkout-title {
    margin: 0 0 24px 0;
    padding: 0;

    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: normal;
  }

  /*
    Radio
    */
  .checkout-radio {
    position: relative;
    display: flex;

    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;

    .checkout-radio__icon,
    .checkout-radio__text,
    .checkout-radio__edit {
      display: block;
      position: relative;
      z-index: 100;
      margin: 0;
      padding: 10px;
      box-sizing: border-box;
    }
    .checkout-radio__icon {
      appearance: none;
      -webkit-appearance: none;
      white-space: nowrap;

      cursor: pointer;
      padding-right: 0;

      &::before,
      &::after {
        content: " ";
        display: inline-block;
        width: 17px;
        height: 16px;
        box-sizing: border-box;
        border: 2px solid @warm-gray-lighten-04;
        border-radius: 17px;
      }
      &::after {
        background: @ui-blue-00;
        opacity: 0;
        margin-left: -17px;
        transition: all 0.4s;
      }

      &:checked {
        cursor: default;
      }
      &:checked::before,
      &:checked::after {
        border-color: @ui-blue-00;
      }
      &:checked::after {
        opacity: 1;
        transform: scale(0.6);
      }

      &:focus {
        outline: none !important;
      }

      &:checked ~ .checkout-radio__fill {
        background: @white-darken-01;
      }
      &:checked ~ .checkout-radio__text {
        cursor: default;
      }
    }

    .checkout-radio__text {
      flex-grow: 1;
      cursor: pointer;
    }

    .checkout-radio__fill {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px solid @white-darken-02;
      box-sizing: border-box;
      transition: all 0.4s;
    }
    .checkout-radio__icon:hover ~ .checkout-radio__fill,
    .checkout-radio__text:hover ~ .checkout-radio__fill {
      opacity: 0.5;
      background: @white-darken-01;
    }

    .checkout-radio__text {
      display: flex;
      flex-direction: column;
    }
    .checkout-radio__head {
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      font-weight: 700;
      line-height: 20px;
    }
    .checkout-radio__body {
      margin: 0;
      padding: 0;
    }
    .checkout-radio__head .checkout-radio__info {
      display: inline-block;
      color: @blue-00;
    }
    .checkout-radio__body .checkout-radio__info {
      display: block;
      color: @blue-00;
    }
    .checkout-radio__edit {
      align-self: flex-start;
      color: @blue-00;
      text-decoration: underline;
    }

    @media (max-width: @screen-sm) {
      flex-wrap: wrap;

      .checkout-radio__text {
        flex-wrap: wrap;
        max-width: 80%;
      }
      .checkout-radio__edit {
        width: 100%;
        text-align: right;
      }
    }
  }

  /*
    Form Controls
    */
  .checkout-field-group {
    display: flex;
    gap: 20px;

    @media (max-width: @screen-sm-max) {
      flex-direction: column;
    }
  }
  .checkout-field {
    position: relative;
    padding-bottom: 24px;
    flex: 1;
    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;

    .intl-tel-input {
      display: block;
    }

    .checkout-field__input {
      display: block;
      width: 100%;
      appearance: none;
      box-sizing: border-box;
      border: 1px solid @white-darken-05;
      padding: 10px 0px 10px 20px;
      border-radius: 4px;

      box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);

      color: @taupe-darken-03;
      font-size: 16px;
      line-height: 24px;
    }
    select.checkout-field__input {
      padding-right: 30px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7'%3E%3Cpath d='M12 0H0L6 7L12 0Z' fill='%23A7A0A0' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 20px top 50%;
    }
    .checkout-field__label {
      color: @taupe-darken-03;
      font-size: 16px;
      font-weight: bold;
    }
    .checkout-field__input[type="checkbox"] {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;

      border: 1px solid @white-darken-05;
      margin: 0;

      background: @white-darken-02;
      appearance: none;

      &:checked {
        border: 1px solid @ui-blue-darken-01;
        background: @ui-blue-00;
      }
      &:checked::before {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 20px;
        height: 10px;
        border: 3px solid @white-00;
        border-top: 0;
        border-right: 0;
        transform: scale(0.6) rotate(-45deg);
      }
      & + label {
        padding-left: 30px;
      }
    }

    .checkout-field__input[type="submit"] {
      display: block;
      padding: 15px 0 15px 0;
      width: 100%;
      border: none;
      color: @white-00;
      background: @green-00;
      border-radius: 4px;
      font-weight: bolder;
      font-size: 16px;
      cursor: pointer;

      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    .checkout-field__actions {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
    }
    .checkout-field__action {
      color: @blue-00;
      text-decoration: underline;
    }
    .checkout-field__action:last-child {
      text-align: right;
    }

    .checkout-field__tooltip {
      padding-top: 24px;
      color: @taupe-darken-03;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  /*
    Address Editor
    */
  .checkout-editor {
    margin-bottom: 72px;
    border: 1px solid @white-darken-02;
    border-radius: 4px;

    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;

    .checkout-editor__title {
      padding-left: 30px;
      font-family: @font-family-sans-serif;
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;
    }
    .checkout-editor__options {
      margin: 0px 20px 0px 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .checkout-editor__add {
      position: relative;
      color: @blue-00;
      display: inline-block;
      margin: 24px 0px 24px 40px;
      padding-left: 30px;
    }
    .checkout-editor__add::before {
      content: "+";
      display: block;
      position: absolute;
      left: 15px;
      text-decoration: none;
    }
    .checkout-editor__footer {
      margin: 0;
      border-top: 1px solid @white-darken-02;
      padding: 10px 10px 10px 30px;
      background: @white-darken-01;
    }
    .checkout-editor__update {
      display: inline-block;
      padding: 5px 10px;
      border: none;
      border-radius: 4px;
      background: @blue-00;
      cursor: pointer;
      color: @white-00;
      font-weight: bolder;
    }
  }

  /*
    Donate Bar
`*/
  .checkout-donate {
    display: flex;
    flex-wrap: wrap;

    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;

    .checkout-donate__label,
    .checkout-donate__input,
    .checkout-donate__button {
      position: relative;
      height: 44px;
      padding: 0 15px 0 15px;
      box-sizing: border-box;
      margin: 0;
      border: 0;
      border-radius: 0;
      line-height: 44px;
      font-size: 14px;
      vertical-align: top;
    }

    .checkout-donate__label {
      border: 1px solid @white-darken-05;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      background: @white-darken-02;

      color: @taupe-darken-03;
      font-weight: 900;
      text-align: center;
    }
    .checkout-donate__input {
      max-width: 150px;
      border: 1px solid @white-darken-05;
      border-left: none;
      background: @white-00;
      color: @taupe-darken-03;
      font-weight: 600;
    }
    .checkout-donate__button {
      //color: @white-00;
      font-weight: 900;
      //border-right: 1px solid @blue-darken-01;
      //background: @blue-00;
      color: @taupe-darken-03;
      background-color: @white-darken-02;
      border: 1px solid @white-darken-05;

      &:hover {
        background-color: @blue-lighten-02;
        color: @white-00;
      }
    }
    .checkout-donate__button[data-active="true"] {
      border-right: 1px solid @blue-darken-01;
      background: @blue-00;
      color: @white-00;
    }
    .checkout-donate__button:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: none;
    }
    @media (max-width: @screen-sm) {
      .checkout-donate__label {
        border-bottom-left-radius: 0px;
      }
      .checkout-donate__input {
        flex-grow: 1;
        min-width: 75%;
        max-width: 100%;
        border-top-right-radius: 4px;
      }
      .checkout-donate__input + .checkout-donate__button {
        border-bottom-left-radius: 4px;
      }
      .checkout-donate__button {
        width: 25%;
      }
      .checkout-donate__button:last-child {
        border-top-right-radius: 0px;
      }
    }
  }

  /*
    Product (donation product with look inside)
    */
  .checkout-product {
    display: flex;

    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;

    .checkout-product__view {
      position: relative;
      width: 100%;
      max-width: 330px;
      text-align: center;
    }
    .checkout-product__button {
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      padding: 5px 10px;

      cursor: pointer;
      background: @white-darken-02;
      border: 1px solid @taupe-lighten-04;
      border-radius: 4px;

      color: @taupe-darken-03;
      font-size: 14px;
      font-weight: 600;
    }
    .checkout-product__image {
      width: 100%;
      max-width: 250px;
      display: inline-block;
      margin: auto;
    }

    .checkout-product__info {
      flex: 1;
    }
    .checkout-product__name {
      margin: 0 0 8px 0;
      color: @blue-00;
      font-family: @font-family-slab;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-transform: none;
    }
    .checkout-product__cost {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: @taupe-darken-01;
    }
    .checkout-product__options {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px 20px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
    @media (max-width: @screen-sm) {
      flex-direction: column;

      .checkout-product__view {
        max-width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  /*
    Cart Item
    */
  .checkout-cart-item {
    border: 1px solid @white-darken-02;
    border-radius: 4px;
    display: flex;
    gap: 30px;
    padding: 20px;
    margin-bottom: 24px;

    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;

    .checkout-cart-item__image {
      width: 100%;
      max-width: 150px;
      height: auto;
      align-self: flex-start;

      @media print {
        display: none;
      }
    }
    .checkout-cart-item__info {
      flex: 1;
    }
    .checkout-cart-item__name {
      color: @blue-00;
      font-family: @font-family-slab;
      font-size: 22px;
      font-weight: 900;
      line-height: 24px;
    }
    .checkout-cart-item__type {
      color: @taupe-darken-01;
      font-size: 14px;
      line-height: 24px;
      font-weight: 900;
    }
    .checkout-cart-item__cost {
      color: @taupe-darken-03;
      font-size: 16px;
      line-height: 24px;
    }
    .checkout-cart-item__count {
      color: @taupe-darken-01;
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: @screen-sm) {
      flex-direction: column;
    }
  }

  .checkout-summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    color: @taupe-darken-03;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    &.checkout-summary--final {
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
    }

    .checkout-summary__label {
      width: 70%;
    }
    .checkout-summary__price {
      width: 30%;
      text-align: right;
    }
    .checkout-summary__divide {
      display: block;
      width: 100%;
      border-top: 1px solid @taupe-lighten-04;
      padding: 0;
      margin: 12px 0 12px 0;

      &.checkout-summary__divide--small {
        width: 30%;
      }
    }
  }

  /*** checkout section and section overrides ***/
  /*
    Section
    */
  .checkout-section {
    padding: 0px 0 48px 0;
    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;

    @media print {
      padding: 0;
    }

    .checkout-section__title {
      margin: 0 0 24px 0;
      padding: 0;

      color: @taupe-darken-03;
      font-family: @font-family-sans-serif;
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;
    }
    .checkout-section__box {
      border: 1px solid @white-darken-02;
      border-radius: 4px;
      padding: 20px;
      margin: 0 0 24px 0;
      display: flex;
    }
    .checkout-section__text {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      color: @taupe-darken-03;
      font-size: 16px;
      line-height: 24px;
    }
    .checkout-section__head {
      width: 100%;
      font-weight: 900;
    }
    .checkout-section__body {
      flex-grow: 1;
      max-width: 300px;
    }
    .checkout-section__edit {
      align-self: flex-start;
      color: @blue-00;
      text-decoration: underline;
    }
    .checkout-section__type {
      display: inline-block;
      font-weight: 100;
      color: @taupe-darken-01;
    }
    .checkout-section__body .checkout-section__type {
      display: block;
      font-size: 14px;
    }
  }

  /*
    Payment override
    */
  .checkout--payment {
    .checkout-section__options {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      gap: 20px 40px;

      @media (max-width: @screen-sm) {
        flex-direction: column-reverse;
      }
    }
    .checkout-radio__text {
      padding-right: 18px;
    }
    .checkout-radio__body {
      letter-spacing: 20px;
      padding-top: 8px;
    }
  }

  /*
    Shipping review boxes
    */
  .checkout--information-ship {
    .checkout-section__box {
      flex-direction: column;
      & > .checkout-field {
        padding-bottom: 0;
      }
      .hideit {
        padding-top: 24px;
      }
    }
  }

  /*
    Donation Product override
    */
  .checkout--product {
    .checkout-radio__body {
      color: @taupe-darken-01;
      font-size: 12px;
    }
    .checkout-radio__icon {
      padding-top: 15px;
    }
    .checkout-radio__text {
      padding-top: 15px;
      padding-right: 15px;
      padding-bottom: 12px;
    }

    .checkout-radio__fill {
      border: none;
    }
    .checkout-radio__icon:checked ~ .checkout-radio__fill {
      border: 1px solid @white-darken-02;
    }

    .modal-body img {
      max-width: 768px;
      display: block;
      width: 100%;
      margin: 0 auto 20px auto;
    }
  }

  /*
    Payconnex override
    */
  .checkout--creditcard {
    max-width: 615px;

    .cc_container {
      margin: 0;
      padding-right: 5px;
    }
  }

  /*
    Shipping override
    */
  .checkout--shipping {
    .checkout-section__leadin {
      font-weight: bold;
      margin: 0;
    }

    .checkout-section__errors {
      color: @ui-red-00;
    }

    .checkout-section__notify {
      display: inline-block;
      margin: 8px 0 0 0;
      padding: 1px 4px;
      background: @ui-yellow-lighten-01;
      border-radius: 2px;
      font-weight: bolder;
      font-size: 14px;
      line-height: 24px;
    }
    .checkout-section__options {
      margin-top: 12px;
    }
    .checkout-radio__icon {
      padding-left: 0;
      padding-right: 0;
    }
    .checkout-radio__text {
      flex-direction: row;
      gap: 10px;
    }
    .checkout-radio__head {
      width: auto;
    }
    .checkout-radio__info {
      color: @taupe-darken-01;
      font-weight: 500;
    }
  }

  /*** checkout aside areas and overrides ***/
  .checkout-aside {
    display: block;
    padding: 24px;
    margin: 0;
    border: 1px solid @white-darken-02;
    border-radius: 4px;
    background: @white-darken-01;

    .checkout-aside__title {
      margin: 0;
      color: @taupe-darken-01;
      font-family: @font-family-sans-serif;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }
    .checkout-aside__subtitle {
      margin: 0 0 12px 0;
      color: @taupe-darken-01;
      font-family: @font-family-sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .checkout-field__label {
      font-size: 14px;
      line-height: 20px;
    }
  }

  /*
    Need Help
    */
  .checkout--help {
    margin-top: 20px;

    @media (min-width: @screen-sm-max) {
      margin-top: 0;
      max-width: 293px;
      float: right;
    }

    .checkout-aside__title {
      text-transform: none;
    }
  }

  /*
    Confirmation Total
    */
  .checkout--total {
    background: none;

    .checkout-aside__title {
      color: @taupe-darken-03;
      margin-bottom: 12px;
    }

    #store_place_order_form {
      margin-top: 24px;
    }
  }

  /*
    Success Total
    */
  .checkout--summary {
    background: @white-darken-01;

    @media (min-width: @screen-sm-max) {
      margin-top: 45px;
    }

    .checkout-aside__title {
      color: @taupe-darken-03;
      margin-bottom: 12px;
    }
  }

  /*
    Success Downloads
    */
  .checkout--downloads {
    @media print {
      display: none;
    }
  }

  /*
    Create an Account
    */
  .checkout--account {
    padding: 0;
    border: none;
    background: none;

    @media print {
      display: none;
    }
    @media (min-width: @screen-sm-max) {
      margin-top: 40px;
      max-width: 293px;
      float: right;
    }

    .checkout-aside__title {
      color: @taupe-darken-03;
      margin-bottom: 12px;
    }

    .checkout-aside__text {
      font-size: 14px;
      line-height: 20px;
      color: @taupe-darken-03;
    }

    .checkout-aside__link {
      display: inline-block;

      padding: 5px 20px;
      border: 1px solid @taupe-lighten-04;

      border-radius: 4px;
      cursor: pointer;
      background: @white-darken-01;

      color: @taupe-darken-03;
      font-size: 14px;
      font-weight: 700;
    }

    .checkout-aside__alert {
      margin-top: 45px;
      padding: 15px 20px;
      border: 1px solid @blue-lighten-08;
      border-radius: 4px;
      background: @blue-lighten-09;

      color: @blue-darken-02;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .thanks-promo {
    padding-bottom: 40px;

    a.btn {
      display: inline-block;

      padding: 5px 20px;
      border: 1px solid @taupe-lighten-04;

      border-radius: 4px;
      cursor: pointer;
      background: @white-darken-01;

      color: @taupe-darken-03;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
