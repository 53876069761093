@mejs_asset_path: "/static/js/responsive/lib/mediaelement/icons/";
@mejs_asset_path_lightui: "@{mejs_asset_path}lightui/";

// Handle the media type toggles
.media-types {
  text-align: center;
    font-size: 16px;

  .listen-watch-slidebox {
    text-align: center;
    font-size: 0.75em;
    background-color: @white-darken-03;
    color: @muted-text;
    border-radius: 16px;
    font-size: 16px;
    margin: 0 auto 15px auto;
    line-height: 16px;
    padding: 0;
    width: fit-content;
    display: table;

    .btn-media {
      display: inline-block;
      padding: 5px 16px;
      border-radius: 16px;
      margin: 0;
      text-decoration: none;
      color: @muted-text;
      line-height: 1;
      cursor: pointer;
      font-size: 16px;
      border: 1px solid transparent;
      &:hover {
        text-decoration: none;
      }

      &.active {
        background: linear-gradient(180deg, @white-00 0%, @white-darken-02 100%);
        border: 1px solid @white-darken-05;
      }
    }
  }
}

// Allows for absolute positioning on overlays
.media-player {
  position: relative;
  img {
    border-radius: 0;
    background-color: @primary-color;
  }
}
// Prevents random exteme width on control bar
.mejs-container {
  max-width: 100% !important;
  min-width: 100% !important;
}
// Forces proper aspect ration and sizing on video
.video-container {
  .mejs-container:not(.mejs-container-fullscreen) {
    padding-top: 56.25%;
    padding-bottom: 40px;
  }
  // Properly positions poster image on video
  .mejs-poster {
    background-size: 100%;
    background-position: left top;
  }
  .mejs-controls {
    bottom: 0;
  }
}


// Prevents disabled player from being used
.media-player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 150;

  .action-box {
    color: @white-00;
    height: 40px;
    width: 100%;
    background-color: @blue-darken-02;
    bottom: 0;
    left: 0;
    position: absolute;
    vertical-align: middle;
    text-align: right;
    padding-right: 8px;
    padding-top: 3px;
    span {
      text-transform: uppercase;
      font-weight: bold;
      display: inline-block;
      font-size: 12px;
      min-width: 284px;
      @media (max-width: @screen-tiny-max) {
        min-width: 0;
      }
    }
    .overlay-icon-wrapper {
      display: inline-block;
      background-color: @white-00;
      height: 24px;
      width: 24px;
      border-radius: 12px;
      text-align: center;
      vertical-align: middle;
      background-image: url('@{mejs_asset_path}icons8-default_size_ball.svg');
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  &:hover {
      .action-box {
        cursor: pointer;
      }
  }
}

.mejs-overlay-play {
  opacity: 0;
}

.mejs-button.disabled,
.mejs-button.disabled > button {
  cursor: not-allowed;
  opacity: 0.4;
}


// Persistent Player
.scroll-player-wrapper {
  width: 320px;
  padding: 0;
  position: fixed;
  z-index: 500;
  right: 1em;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  @media (min-width: 768px) {
    width: 400px;
    .mejs-time-rail {
      max-width: 400px !important;
    }
  }

  @media (max-width: @screen-tiny-max) {
    width: 100%;
    margin: 0;
    padding: 0;
    right: 0;
    left: 0;
    .mejs-time-rail {
      right: 0 !important;
      max-width: none !important;
    }
  }

  .app-miniplayer-content {
    position: relative;
    &:hover {
      .mejs-controls {
        visibility: visible;
      }
    }
  }
  &.remove {
    display: none;
  }
  &.show-me {
    bottom: 0;
  }
  &.hide-me {
    bottom: -400px;
  }

  .scroll-player-content-toggle-arrow {
      background-image: url('@{mejs_asset_path}icons8-expand_arrow.svg');
      background-position: center center;
      background-repeat: no-repeat;
      height: 66px;
      width: 40px;
  }

  &.video {
    .scroll-player-content-toggle-arrow {
      display: none;
    }
    .scroll-player-header {
      cursor: default !important;
    }
    .mejs-controls {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 98%;
      .mejs-defaultplayer-button {
          bottom: 0;
      }
    }
  }

  .scroll-player-title-slide {
    width: 96%;
    font-weight: 700;
    font-size: 0.6em;
    line-height: 1.25em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    padding: 8px 16px;
    z-index: -1;
    background: rgb(76, 79, 79);
    color: @white-00;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    opacity: 0;
    transition: all 0.15s ease-out;
  }

  .scroll-player-header {
    padding: 8px;
    background-color: @blue-darken-02;
    color: @white-00;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;
    height: 66px;
    cursor: pointer;
  }

  &.slide-down {
    .scroll-player-content-toggle-arrow {
      background-image: url('@{mejs_asset_path}icons8-collapse_arrow.svg');
    }
    .app-miniplayer-content {
      height: 8px;
      &:hover {
        .mejs-controls {
          visibility: hidden;
        }
      }
    }
    .audio-container {
      max-height: 8px;
    }
  }

  .scroll-player-title {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.25em;
    flex-grow: 1;
    text-indent: 10px;
  }
  .scroll-player-content {
    padding: 0 0 8px 0;
  }
  .scroll-player-icon {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 0 0 8px;
    cursor: pointer;
  }

  .mejs-audio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: transparent;
    overflow: hidden;
    .mejs-overlay-play {
      display: none;
    }
  }

  .mejs-container:not(.mejs-container-fullscreen) {
    padding-bottom: 8px;
  }

  // Handle miniplayer controls
  .mejs-controls {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 56.25%;
    background-color: rgba(33,33,33,0.7);
    visibility: hidden;
    z-index: 10;

    // Control Icons
    .mejs-playpause-button {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        >button {
          position: relative;
          height: 80px;
          width: 80px;
        }
        &.mejs-play {
            >button {
              background: transparent url('@{mejs_asset_path}overlay-play.svg') no-repeat center center;
            }
        }
        &.mejs-pause {
            >button {
              background: transparent url('@{mejs_asset_path}overlay-pause.svg') no-repeat center center;
            }
        }
    }
    .mejs-skip {
        position: absolute;
        top: 35%;
        right: 25%;
        @media (max-width: @screen-xs-max) {
          top: 30%;
        }
        >button {
              background: transparent url('@{mejs_asset_path}overlay-skip.svg') no-repeat center center;
              height: 48px;
              width: 48px;
        }
    }
    .mejs-previous {
        position: absolute;
        top: 35%;
        right: 75%;
        z-index: 3;
        @media (max-width: @screen-xs-max) {
          top: 30%;
        }
        >button {
            background: transparent url('@{mejs_asset_path}overlay-previous.svg') no-repeat center center;
            height: 48px;
            width: 48px;
            transform: rotate(180deg);
        }
    }

    .mejs-currenttime-container {
      display: inline;
      position: absolute;
      bottom: 8px;
      right: 0;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-align: right;
      color: @white-00;
    }
    .mejs-duration-container {
      display: none;
      position: absolute;
      bottom: 8px;
      left: 35px;
      padding-left: 5px;
    }
    .mejs-volume-button {
      display: none;
      position: relative;
      top: center;
      left: center;
       >.mejs-volume-slider {
        background: black;
        border-radius: 5px;
        bottom: -110px;
        left: 16px;
        margin: 0;
      }
    }
    .mejs-defaultplayer-button {
      display: inline;
      position: absolute;
      top: 0;
      left: 0;

      width: 36px;
      height: 36px;
      >button {
        width: 26px;
        height: 26px;
        background: transparent url('@{mejs_asset_path}icons8-default_size-large.svg') no-repeat center center;
      }
    }
    .mejs-miniplayer-close {
      display: inline;
      position: absolute;
      top: 0;
      right: 20px;
      >button {
        width: 26px;
        height: 26px;
        background-size: cover;
      }
    }

    .mejs-queue-button {
      display: inline;
      position: absolute;
      bottom: 8px;
      left: 0;
      z-index: 2;
      >button {
        width: 26px;
        height: 26px;
        background-size: cover;
      }
    }


    // Handle miniplayer progress bar
    .mejs-time-rail {
      visibility: visible;
      border-radius: 0;
      position: fixed;
      font-size: 16px;
      bottom: 66px;
      right: 1em;
      width: 100%;
      max-width: 320px;
      margin: 0;
      padding: 0;
      height: 8px;
      span:not(.mejs-time-handle):not(.mejs-time-handle-content) {
        border-radius: 0;
      }
      .mejs-time-total {
        height: 8px;
        margin: 0;
        background: @blue-lighten-06;
      }
      .mejs-time-current {
        //background: @primary-color;
        background: @green-00;
      }
      .mejs-time-loaded {
        background: @blue-darken-01;
        opacity: 0.3;
      }
      .mejs-time-handle-content {
        background: @blue-lighten-08;
        border: 6px solid @blue-lighten-08;
        overflow: auto;
        z-index: 555;
      }

    }
  }
}
.broadcast-media-wrapper {
  max-width: 817px
}
.devo-mediaplayer {
  max-width: 250px;
  margin: 0;
  padding: 0;
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    max-width: 215px;
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    max-width: 470px;
  }
  @media (max-width: @screen-xs-max) {
    max-width: 100%;
  }
  .media-player-overlay .action-box span {
    min-width: 0;
  }
}


// Handle Mobile Nav opening
body.nav-open {
  .scroll-player-wrapper {
    right: 270px !important;
  }
}
.post-aquire {
  font-size: 0.75em;
  border-radius: 7px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 0 10px 16px 10px;
  margin: 2em auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (min-width: 768px) {
    flex-direction: column;
    padding: 10px 0 20px 0;
  }
}

.broadcast-player-container {
  .container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}


.light-ui {
  &.light-ui--rounded {
    border-radius: 16px;
    overflow: hidden;
  }
  .mejs-player {
    border-radius: 0 0 16px 16px;
    overflow: hidden;
    background-color: @white-darken-01;

    .mejs-controls {
      background-color: @white-darken-01;
      color: @taupe-darken-01;
    }
    .mejs-time {
      color: @taupe-darken-01;
    }
   .mejs-time-slider {
      background-color: @taupe-lighten-03;
      .mejs-time-total {
         background: pink;
      }
      .mejs-time-loaded {
        background: @taupe-lighten-04;
      }
      .mejs-time-current {
        background: @taupe-lighten-01;
      }
    }
  }

  .media-player-overlay {
    .action-box {
      color: @taupe-darken-01;
      background-color: @white-darken-01;
      border-radius: 0 0 16px 16px;

      .overlay-icon-wrapper {
        background-image: url('@{mejs_asset_path_lightui}icons8-default_size_ball.svg');
      }
    }
  }

  .mejs-play {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-play.svg') no-repeat center center;
      }
  }
  .mejs-pause {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-pause.svg') no-repeat center center;
      }
  }
  .mejs-skip {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-skip.svg') no-repeat center center;
      }
  }
  .mejs-previous {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-previous.svg') no-repeat center center;
      }
  }
  .mejs-replay {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-refresh.svg') no-repeat center center;
      }
  }
  .mejs-mute {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-volume.svg') no-repeat center center;
      }
  }
  .mejs-unmute {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-no_audio.svg') no-repeat center center;
      }
  }
  .mejs-fullscreen-button {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-full_screen.svg') no-repeat center center;
      }
  }
  .mejs-miniplayer-button {
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-miniplayer.svg') no-repeat center center;
      }
  }
  .mejs-defaultplayer-button {
      display: none;
      >button {
        background: transparent url('@{mejs_asset_path_lightui}icons8-default_size.svg') no-repeat center center;
      }
  }
  .mejs-miniplayer-close {
    display: none;
    >button {
      background: transparent url('@{mejs_asset_path_lightui}icons8-delete_sign.svg') no-repeat center center;
    }
  }

  .mejs-queue-button {
    >button {
      background: transparent url('@{mejs_asset_path_lightui}icons8-queue-list.svg') no-repeat center center;
    }
  }

  .mejs-container-fullscreen {
      .mejs-controls {
          .mejs-fullscreen-button {
              >button {
                  background: transparent url('@{mejs_asset_path_lightui}icons8-default_size.svg') no-repeat center center;
              }
          }
      }
  }
}
