// ------------------------------------------------------------------------- Navigation - Sidebar
.sidebar-nav {
  &:not(.force-show) {
    .hidden-xs;
  }
  .well;
  background: @white-darken-01;
  border-color: @muted-background;
  border-radius: 0;
  // font-weight: 500;
  .nav-sidebar {

    a {
      color: @sidebar-nav-color;
      padding: 5px 0;
      display: block;
      &:hover {
        color: @sidebar-nav-active;
        background-color: transparent;
      }
    }
    li {
      &.section-title {
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: @font-family-secondary;
        font-size: 14px;
        font-weight: 800;
        border-top: 1px solid @border-color;
        padding-top: 10px;
        letter-spacing: .07em;
      }
      &.active {
        a,a:focus {
          background: none;
          color: @sidebar-nav-active;
        }
        a {
          &:before {
            color: @sidebar-nav-active;
            .ti;
            color: inherit;
            .transition(~"all 0.2s ease-in-out");
            position: absolute;
            top:10px;
            left:-5px;
          }
        }
      }
    }
    > li {
      > a {
        .transition(~"all 0.2s ease-in-out");
        padding-left: 10px;

        &:before {
          .ti;
          // content: "\f105";
          content: '';
          display: block;
          height: 12px;
          width: 12px;
          background-image: url(/static/images/_svgs/svg-angle-right2.svg);
          background-size: 12px 12px;
          background-position: center center;
          background-repeat: no-repeat;
          fill: @muted-background;
          .opacity(.5);
          position: absolute;
          top:10px;
          left:-5px;
        }
      }
    }
    > li > ul {
      list-style: none;
      padding-left:27px;
      > li {
        position: relative;
      }
      > li > a {
        &:before {
          .ti;
          margin-right: 5px;
          margin-left: -12px;
          color: lighten(@sidebar-nav-color, 25%);
          .transition(~"all 0.2s ease-in-out");
          content: '';
          display: block;
          height: 12px;
          width: 12px;
          background-image: url(/static/images/_svgs/svg-angle-double-right.svg);
          background-size: 20px 16px;
          background-position: center center;
          background-repeat: no-repeat;
          fill: @muted-background;
          .opacity(.5);
          position: absolute;
          top:10px;
          left:-5px;
        }
      }
    }
  }
  .nav .nav {
    .small;
    margin-top: (@line-height-computed * -0.5);
    margin-left: 10px;

    a {
      color: darken(@sidebar-nav-color, 10%);

      &:hover {
        background: none;
        color: @sidebar-nav-active;
      }
    }

    .active a {
      background: none;
      color: @sidebar-nav-active;
    }
    .active a:before {
      content: " ";
      width: 0;
      height: 0;
      border-left: 7px solid @white-00;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      position: absolute;
      left: -30px;
      top: 14px;
    }
  }
}

.promotions {
   @media(max-width: @screen-xs-max) {
    padding-top: @grid-gutter-width;
  }
}

// Display for Small Screen headerSubnav  dropdowns
#subnav-toggle {
  .sidebar-nav {
    &:not(.force-show) {
      .visible-xs;
    }
  }
}
