// -------------------------------------------------------- SOCIAL NAV HORIZONTAL

.ti-addthis {
  font-family: 'FontAwesome';
  position: absolute;
  top: 1px;
  left:12px;
  display: block;
  height: 20px;
  width: 20px;
  font-size: 20px;
  z-index:1;
  .opacity(.8);
}
.icon-roku_logo {
  font-size: 14px;
}
.social-share-wrapper2 {
  // .tiny-label {
  //   float: left;
  //   line-height: 40px;
  //   display: inline-block;
  //   font-family: @font-family-secondary;
  //   font-style: italic;
  //   color: #9E8B83;
  //   position: relative;
  //   margin-right: 5px;
  //   margin-top: -4px;
  // }
  .shareaholic-canvas {
    display: inline-block;
    float: left;
    margin-top: -10px;
    clear: none;
  }
  .shareaholic-share-buttons-container ul.shareaholic-share-buttons {
    float: right !important;
  }
  .shareaholic-share-buttons-heading {
    float: left;
    top: 15px;
    position: relative;
    margin-right: 4px;
    color: #999;
    font-family: @font-family-secondary;
    font-style: italic;
  }
  @media (max-width: @screen-xs-max) {
    display: none;
  }

  @media (min-width: @screen-sm-min) {
    position: absolute;
    top: 4px;
    right: 10px;
    .shareaholic-canvas {
      display: inline-block;
      float: right;
      margin-top: -10px;
    }
  }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    bottom: 52px;
  }
}