// ------------------------------------------------------------------------- Bible
.bible {
  .main-content-wrapper {
    .make-sm-column(7);
  }
  .sidebar-wrapper {
    .sidebar-nav {
      padding: 24px;
    }
    .make-sm-column(5);
    h4 {
      font-size: 16px;
      line-height: 24px;
      padding: 0;
      margin: 0 0 12px 0;
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        font-size: 1.2em;
      }
    }
   .nav-sidebar > li {
      & > a {
        font-size: 14px;
        line-height: 24px;
        padding-left: 0;
        &:before {
          background: none;
        }
      }
    }
  }

  .mobile-bible-form {
    label {
      margin-bottom: 0;

    }
    .bible-search-support {
      display: flex;
      justify-content: space-between;


      .chapter-wrapper,
      .verse-wrapper {
        margin-top: 5px;
        flex: 0 0 auto;
        width: 48%;
      }
    }
  }





  .main-content-wrapper.bible-passages {
    padding-top: 0;


    .crossreference {
      display: none;
    }

    .alert {
      margin-top: 1em;
    }
    .bible-copyright {
      margin-top: 48px;
      font-size: 75%;
      color: @muted-text;
    }

    .passage-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      align-content: space-between;
      height: 48px;
      width: 100%;
      .passage-chapter-wrapper {
        flex: 0 0 auto;
        height: 48px;
        width: 48px;
        a {
          display: inline-block;
          height: 100%;
          width: 100%;
          overflow: auto;

          .scripture-icon {
            height: 24px;
            width: 24px;
            margin: 12px;
          }

          &:hover {
            background-color: @white-darken-01;
          }
        }
      }
      .passage-title {
        flex: 1 1 auto;
        align-self: center;
        text-align: center;
        line-height: 48px;
        vertical-align: middle;
        font-size: 32px;
        letter-spacing: 0;
        font-family: @font-family-serif;
      }
    }

    ul.bible-tablist {
        display: flex;
        border-bottom: 1px solid @taupe-lighten-04;
        margin-top: 32px;
        height: 48px;
        margin-bottom: 15px;
        padding-bottom: 0;

        li {
          border: none;
          width: 140px;
          text-align: center;
          height: 100%;
          a {
            display: inline-block;
            height: 100%;
            width: 100%;
            border: none;
            border-bottom: 4px solid transparent;
            transition: all 0.25s ease;
            margin: 0;
            padding: 0;
            border-radius: 0;
            cursor: pointer;
            background: transparent;

            color: @taupe-darken-03;
            font-size: 14px;
            font-weight: 800;
            font-family: @font-family-sans-serif;
            letter-spacing: 1px;
            line-height: 48px;
            vertical-align: middle;

            @media(max-width:@screen-md-min)
            {
              font-size:13px;
              text-align:center;
              padding:0 2px 10px 2px;
            }

            .circle_count {
              font-size: 12px;
              font-weight: 800;
              letter-spacing: 0;
              line-height: 12px;
              text-align: center;
              background-color: @white-darken-02;
              border-radius: 6px;
              padding: 2px 5px;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
        li.active {
          a {
            color: @green-darken-01;
            border-bottom-color: @green-darken-01;
          }
        }
    }

    h3 {
      font-size: 1.3em;
    }
    h3.section.psalm-book {
      text-transform: uppercase;
      font-size: 1.3em;
      color: @taupe-darken-01;
    }
    h4.psalm-title {
      text-transform: uppercase;
      font-size: .9em;
      color: @muted-text;
      font-family: @font-family-base;
    }
    .passage {
      a.footnote {
        font-size: 12px;
        padding-left: 2px;
        vertical-align: top;
        position: static;
        top: auto;
        text-decoration: none;
        vertical-align: top;
        color: @taupe-darken-01;
        &:before {
          content: "[";
        }
        &:after {
          content: "]";
        }
      }

      sup.v {
        font-weight: bold;
        color: @muted-text;
      }
      
      .selah {
        float: right;
        margin-left: .5rem;
        text-indent: initial;
        font-style: italic;
      }

      .yahweh,
      .divine-name {
        font-family: @font-family-sans-serif;
        font-variant: small-caps;
      }
      .end-line-group {
        display: block;
        margin-bottom: 1em;
      }
      p {
        text-indent: 1em;
      }

      p.line {
        margin: 0;
        text-indent: 0;
      }
      .line-group {
        margin-top: 1em;
        display: block;
      }
      p.indent1 {
        text-indent: -3.5em;
        padding-left: 5.5em;
      }
      p.indent1 sup.v {
        margin-left: -32px;
        padding-right: 23px;
      }
      /* Special handling for Psalm 42:6, Psalm 48:2 */
      p.indent1 sup.v.not-first {
        margin-left: 0;
        padding-right: 0;
      }
      p.indent2 {
        text-indent: -4em;
        padding-left: 7em;
      }
      p.indent2 sup.v {
        margin-left: -48px;
        padding-right: 39px;
      }
      /* Special handling for Psalm 42:6, Psalm 48:2 */
      p.indent2 sup.v.not-first {
        margin-left: 0;
        padding-right: 0;
      }
      p.indent3 {
        text-indent: -5em;
        padding-left: 9em;
      }
      .bible-browser-bottom {
        width: 100%;
        .expand-chapter-link {
          width: 144px;
          background-color: @white-darken-02;
          border-radius: 10px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 5px auto;
          span {
            text-transform: uppercase;
            margin-left: 10px;
            font-size: 10px;
            line-height: 16px;
            color: @taupe-00;
            flex: 1 1 auto;
            font-weight: 800;
            letter-spacing: 0;
          }
          img {
            flex: 0 0 auto;
            height: 14px;
            width: 16px;
            margin-right: 10px;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }


  .bible-browser {
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    @media (min-width: @screen-md-min) {
      margin-top: (@line-height-computed * -1.8);
    }
    .next-chapter,
    .previous-chapter {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
  .related-sermons {

    position: relative;
    border-radius: @border-radius-base;
    h4 {
      margin-top: 0;

      [class^="ti-"] {
        color: @taupe-lighten-02;
        top: 4px;
        position: relative;
      }
    }
  }
  #related-sermons-top {
    background-color: darken(@well-bg, 6%);
    padding: 28px 20px;
    margin: -20px -20px 30px -20px;
    border-radius: 2px;
  }
  #related-sermons-bottom {
    margin-top: 10px;
  }

  .divider-vertical {
    color: @muted-text;
  }
  #audio_bible_block {
    margin-bottom: 20px;
  }
  .ui360 {
    display: inline-block;
    border-radius: 3px;
  }
  #verse_selection {
    margin: -8px 12px 0 0;
    .btn;
    .btn-sm;
    .btn-default;
  }
  .esv-text p {
    text-indent: 15px !important;
  }

  .errorlist {
    .list-unstyled;
  }
  .verse-num {
    vertical-align: super !important;
    color: @muted-text;
  }
  .footnotes {
    .well;
    .small;
    margin-top: (@line-height-computed * 2);

    h3.footnotes-title {
        color: @taupe-darken-01;
        font-family: @font-family-sans-serif;
        font-size: 14px;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        padding: 0;
        margin-top: 0;
    }

    .footnote {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
      color: @black-00;
      position: relative;
      top: auto;
    }

    .footnote-marker {
      font-size: 12px;
      padding-left: 2px;
      color: @taupe-darken-01;
      &:after {
        content: ". ";
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .footnote-ref {
      font-weight: bold;
    }
    .footnote-ref, .footnote-details {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  @media (min-width: @screen-sm-min) {
    .chapter-wrapper {
      padding-right:5px;
    }
    .verse-wrapper,
    .action-wrapper {
      padding-left:0;
    }
  }


  // Bible Sermon and Series rules
  .bible-sermon-wrapper,
  .bible-series-wrapper {
    .passage-results-info {
      font-size: 16px;
      line-height: 24px;
      color: @grey-darken-02;
      margin: 48px 0 24px 0;
      width: 100%;
      border-bottom: 2px solid @taupe-lighten-04;
      display: block;
    }
  }





  // Passage Lookup
  .passage-lookup-wrapper {
    padding-bottom: 12px;
    margin-bottom: 36px;

    .passage-lookup-form {
      background-color: @white-darken-01;
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      letter-spacing: 0;
      padding: 20px 19px;
      display: flex;
      justify-content: flex-start;
      align-content: stretch;
      width: 100%;
      overflow: hidden;
      margin: 0;

      input, select {
        border-radius: 4px;
        display: inline-block;
      }

      label {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        margin-right: 4px;
      }

      .passage-lookup-book {
        min-width: 150px;
        flex: 1 1 auto;
        padding-right: 5px;
        select {
          max-width: 160px;
        }
      }
      .passage-lookup-chapter,
      .passage-lookup-verse {
        flex: 1 1 auto;
        padding: 0 5px;
        input {
          min-width: 20px;
          max-width: 100px;
        }
      }
      .passage-lookup-button {
        flex: 0 0 auto;
        align-self: flex-end;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0;
      }
    }
  }
}

.bible-audio-player {
  min-height: 48px;
  margin-top: 0;
  .audio-container,
  .app-main-player {
    > img {
      display: none;
    }
  }
  .media-player-overlay,
  .media-player-overlay .action-box {
    position: relative;
  }
  .mejs-overlay-button,
  .mejs-overplay-play {
    display: none;
  }
}
