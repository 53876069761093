article.daily {

  position:relative;

  /* upper tabs row ********************************************************************/
  .daily-header-wrapper {
    border-bottom: 2px solid @border-color;
    margin-bottom: 45px;

    ul.nav {
      display: flex;
      border-bottom:none;
      padding:0 15px;

      li {
        border: none;
        width: 25%;

        a {
          position:relative;
          height:100%;

          padding: 0 0 17px 30px;
          margin:0;
          border: none;
          border-bottom: 6px solid transparent;
          transition: all 0.25s ease;

          cursor: pointer;
          background:transparent;

          color: @taupe-darken-03;
          font-size: 16px;
          line-height: 1.2em;
          letter-spacing: 0;

          @media(max-width:@screen-md-min)
          {
            font-size:13px;
            text-align:center;
            padding:0 2px 10px 2px;
          }

          /* tab icon */
          img {
            position:absolute;
            top:0;
            left:0;
            display: block;
            height: 24px;
            width: 24px;
            @media(max-width:@screen-md-min)
            {
              position:relative;
              margin:0 auto;
            }
          }
          &.program img {
            padding:3px; // this is the mic icon, needs padded
          }

          /* bold title */
          strong {
            display: block;
            padding-top:5px;
            font-weight: 900;
          }
          &:hover strong {
            opacity: 0.7;
          }
        }
      }
      li.active {
        a.program {border-bottom-color: @program;}
        a.devotional {border-bottom-color: @devotional;}
        a.bible_reading {border-bottom-color: @biblereading;}
        a.alistair_begg_devotional {border-bottom-color: @alistairbeggdevo;}
      }

      @media (max-width: @screen-tiny-max) {
        padding: 0;

        li {
          a {
            font-size: 10px;
          }
        }
      }

    }
  }

  /* lower tab content ********************************************************************/
  // upper "hero" area
  .content-hero
  {
    display:block;
    width:100%;
    height:auto;
    border-radius:16px;
    overflow: hidden;
    background:@white-darken-03;
  }
  .media-options-support {
    margin-top: 10px;
    a.byline
    {
      display:block;
      float:left;
      margin:5px 10px 0 0;
      white-space: nowrap;

      &::before
      {
        content: " ";
        display:inline-block;
        vertical-align: middle;
        width:40px;
        height:40px;
        border-radius: 20px;
        border:3px solid @blue-lighten-09;
        margin-right:5px;
        background:url(/static/images/responsive/alistair-begg-byline.jpg) no-repeat center center;
        background-size: cover;
      }
    }
  }
  // lower content area
  .content-body
  {
    margin-top:25px;
    margin-bottom:45px;
  }

  // titles
  .content-cluster
  {
    margin-bottom:24px;

    .content-cluster__date
    {
      color: @taupe-darken-01;
      margin: 0;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      font-size: 16px;
    }
    .content-cluster__title
    {
      color: @primary-title;
      margin: 0;
      line-height: 48px;
      font-size: 32px;
      font-weight: bolder;
      letter-spacing: 0;
    }
    .content-cluster__scripture
    {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      color: @taupe-darken-01;
    }
    .content-cluster__feature
    {
      display:inline-block;

      font-size: 12px;
      line-height: 24px;
      color: @taupe-darken-01;
      font-weight: normal;
      letter-spacing: 0;
      margin: 0;
    }
    .content-cluster__feature:before
    {
      content:"•";
      padding:0 4px 0 1px;
      display:inline-block;
    }
    .content-cluster__feature:first-child:before
    {
      display:none;
    }
  }

  //lead-in bible passage
  .content-passage p
  {
    display:block;
    margin-bottom: 11px;

    color: @green-darken-01;
    font-family:@font-family-slab;
    font-size:20px;
    line-height: 24px;
  }
  .content-passage a
  {
    display:inline-block;
    margin-bottom:20px;

    color:@taupe-darken-03;
    font-family:@font-family-sans-serif;
    font-size:16px;
    font-weight:900;
    text-decoration:none;
  }

  // accordion
  .reading
  {
    position:relative;
    max-width: 960px;
    margin: 0 auto 24px 0;
    border: 1px solid @taupe-lighten-04;
    text-align: center;

    &.play_all_btn {
      background-color: @blue-00;
      color: @white-00;
      width: 100%;
      font-weight: bold;
      min-height: 74px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      img, span {
        height: 24px;
        flex: 0 0 auto;
      }
    }

    h2.section
    {
      text-align:center;
    }

    .reading__upper
    {
      position:relative;
      cursor:pointer;
      text-align:left;
    }
    .reading__title
    {
      padding:24px 0 24px 95px;
      color:@taupe-darken-02;
      font-weight:900;
      font-size:16px;
    }
    .reading__passage
    {
      border-top:1px solid @taupe-lighten-04;
      padding:30px 95px 30px 95px;
      text-align: left;
      @media(max-width:@screen-xs-max)
      {
        padding-left:10px;
        padding-right:10px;
      }

      a.crossreference {
        display: none;
      }
    }
    .reading__link
    {
      display:inline-block;
      background:@white-darken-02;
      border-radius:50px;
      padding:5px 12px 5px 12px;
      margin-bottom: 30px;

      color:@taupe-00;
      text-transform:uppercase;
      text-decoration:none;
      font-weight:900;
      font-size:11px;

      img
      {
        width:1em;
        height:1em;
        vertical-align: baseline;
      }

    }
    .reading__link:hover
    {
      color:@white-darken-02;
      background:@taupe-00;
    }

    .reading-notes
    {
      background:@white-darken-01;
      padding:24px 95px 24px 95px;
      text-align:left;

      .reading-notes__title
      {
        color:@taupe-darken-01;
        font-size:14px;
        font-weight:bolder;
        line-height:24px;
        letter-spacing: 1px;
        text-transform:uppercase;
      }
      .reading-notes__note
      {
        margin-top:10px;
      }
      .reading-notes__marker
      {
        display:inline;
        color: @taupe-darken-01;
        font-size: 12px;
        font-weight: bolder;
        vertical-align: baseline;
        &:after {
          content: ". ";
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .reading-notes__ref
      {
        font-weight:bolder;
      }

    }
    .reading__icon
    {
      display:block;
      width:25px;
      height:25px;
      position:absolute;
      top:20px;
      left:18px;
      right:18px;

      img
      {
        width:100%;
        height:100%;
      }
    }
    .reading__icon--expand
    {
      right:auto;
    }
    .reading__icon--confirm
    {
      left:auto;
    }

    /* artificially pad the top of footnote and in-copy references to compensate for the sticky nav covering part of the page */
    .footnote a
    {
      position: relative;
      vertical-align: top;
      padding-top: 100px;
      font-size: 12px;
      color: @taupe-darken-01;

      &:before {
        content: "[";
      }
      &:after {
        content: "]";
      }
      &:hover {
        text-decoration: underline;
      }
    }

    a.reading__marker
    {
      display: block;
      position: relative;
      top: -250px;
      visibility: hidden;
    }

    /**** toggle modes****/
    // checked:
    .reading__mode--checked{ display:none; }
    &.reading--check .reading__mode--checked{ display:inline-block; }
    &.reading--check .reading__mode--unchecked{ display:none; }
    // closed:
    .reading__mode--closed{ display:none; }
    .collapsed .reading__mode--closed{ display:inline-block; }
    .collapsed .reading__mode--opened{ display:none; }

  }


  .reading-download
  {
    display:block;
    width:200px;
    padding:8px;
    margin:0 auto;
    border:1px solid lightgrey;
    border-radius:3px;

    color:#403A3A;
    font-weight:bolder;
    font-size:14px;
    text-align:center;
  }

  .bible-reading-credits {
    margin-top: 40px;
  }


  // outro reference
  h2.resource-reference_label
  {
    display: block;
    margin: 15px 0 30px 0;
    color:@green-00;
    font-size: 36px;
    text-align:center;
  }
  .resource-reference .archive-support
  {
    margin-top:24px;
  }
  .archive-time
  {
    display:block;
    position:relative;
    margin-right:95px;
    color: @taupe-darken-01;
    font-size:14px;
    line-height:24px;

    .archive-time_date
    {
      display:inline-block;
      width:40%;
    }
    .archive-time_duration
    {
      display:inline-block;
      position:absolute;
      top:0;
      right:0;
    }
  }


  /* footer email form ********************************************************************/
  .content-email
  {
    background:@grey-darken-02 url(https://info.truthforlife.org/hubfs/_lists/bg-lists.jpg) no-repeat center top;
    background-size: cover;
    padding:65px 95px 65px 95px;
    margin-top:20px;
    margin-bottom:70px;
    border-radius:20px;

    color:@white-00;

    p
    {
      margin:7px;
      font-weight:900;
      font-size:24px;
      line-height:32px;
    }

    form
    {
      display: flex;
      flex-wrap: wrap;

      > div
      {
        flex: 1;
        margin: 7px;
      }

      .hs_error_rollup
      {
        flex-basis: 100%;
        order: 100;
      }

      .hs-error-msg, .hs-error-msgs
      {
        color: @orange-lighten-02;
      }

      .hs-frequency
      {
        flex-basis: 100%;

        .hs-form-checkbox
        {
          position: relative;
          padding-left: 40px;
          margin-bottom: 10px;
          list-style-type: none;

          input
          {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
          }

          &:nth-child(3), &:nth-child(4)
          {
            display: none;
          }

        }

      }

      .hs-email
      {
        min-width:40%;
      }
      .hs-error-msgs
      {
        padding: 10px 0 0 0;
        list-style-type: none;
      }
      .hs-error-msg
      {
        font-weight:lighter;
        font-style: italic;
      }
      input
      {
        display:block;
        width:100%;
        margin:0;
        border:0;
        padding:10px;
        border-radius:5px;
        color:@grey-darken-02;
        line-height:24px;
      }
      input[type='submit']
      {
        margin-top:28px;
        background:@green-00;
        color:@white-00;
        font-size:14px;
        font-weight:900;
        text-transform:uppercase;
      }
    }

    @media(max-width:@screen-xs-max)
    {
      max-width:100%;
      padding:20px 10px 20px 20px;
      border-radius: 0;

      p
      {
        font-size:18px;
      }
      form
      {
        flex-direction:column;
      }
    }

  }

}
