// ------------------------------------------------------------------------- Archive

.blog-title,
.store-title {
    font-weight: 700;
    font-size: 24px;

    @media (min-width: @screen-md-min) {
        font-size: 30px;
    }
}

.archive-support {
    @media (max-width: @screen-xs-max) {
        max-width: 400px;
    }

    .archive-title,
    .scripture-reference,
    .series {
        a:hover {
            text-decoration: underline;
        }
    }

    h4.archive-title {
        text-transform: none !important;
        .no-link {
            color: @green-00;
        }
    }
    .make-row();
    position: relative;
    padding-top: 0;

    .series {
        color: @bold-link;
        margin-top: @line-height-computed;
    }

    .small.series {
        font-size: 0.9em;
    }

    .archive-date {
        color: @taupe-00;
        font-family: @font-family-sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0px;
    }
/*
    @media (min-width: @screen-sm-min) {
      .archive-description-wrapper {
        .library-binding {
          opacity: 0;
          transition: opacity 0.10s ease-out;
        }
      }

      &:hover {
        .archive-description-wrapper {
          .library-binding {
            opacity: 1;
            transition: opacity 0.25s ease-in;
          }
        }
      }
    }
*/
    .favorites-toggle {
        position: relative;
        text-align: center;
        img {
            cursor: pointer;
            width: auto;
            height: auto;
            vertical-align: top;
        }
    }

    .favorite-info-box {
        .favorites-toggle {
            position: relative;
        }
        .dropdown.favorite-info-box {
            .dropdown-menu {
            }
        }
    }

    .expired {
        &.archive-description-wrapper,
        &.archive-photo-wrapper {
            & > * {
                opacity: 0.2;
            }
            hr {
                opacity: 1;
            }
        }
        .site_notification {
            position: relative;
            min-height: 50px;
            opacity: 1;
            top: -50px;
            right: 0;
            width: 90%;
            transform: none;
            transition: none;
            z-index: 3;
            .notification_message {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.store-support {
    // padding-top: 20px;
    .make-row();
    position: relative;
    padding-top: 30px;

    @media (max-width: @screen-xs-max) {
        margin-top: 7px;
    }

    .series {
        color: @bold-link;
        margin-top: @line-height-computed;
    }

    .small.series {
        font-size: 0.9em;
    }

    .archive-date {
        position: absolute;
        right: @grid-gutter-width / 2;
        top: -2px;
    }
}

.featured-product {
    .archive-support,
    .store-support {
        .archive-date {
            top: -40px;
        }
    }
}

.archive-photo-wrapper {
    .make-xs-column(12);

    @media (max-width: @screen-xs-max) {
        margin-bottom: 15px;
        max-width: 400px;
    }
    a {
        display: block;
    }
}

.archive-photo-wrapper,
.store-photo-wrapper {
    .make-xs-column(12);
    .make-sm-column(5);
    .make-md-column(4);
    //.make-lg-column(3);
    margin-top: 3px;
    .text-left;

    strong {
        display: block;
        text-align: center;
        font-size: 18px;
        font-family: @font-family-secondary;
    }

    .distribution-options {
        @media (min-width: @screen-lg-min) {
            .visible-lg {
                display: inline-block !important;
            }
        }
    }

    @media (max-width: @screen-xs-max) {
        .text-left;

        > a {
            .clearfix;
        }

        > a > img {
            margin-left: 0 !important;
            .img-responsive;
        }
    }

    img {
        margin-left: auto;
        margin-right: auto;
        border-radius: 14px;
        width: 100%;
        .img-responsive;
        @media (max-width: @screen-xs-max) {
            border-radius: 10px;
            width: 100%;
        }
    }

    .list-player-progress {
        display: block;
        position: relative;
        top: -14px;
        margin: 0 auto;
        background-color: @white-darken-02;
        height: 4px;
        width: 90%;
        border-radius: 2px;
        overflow: hidden;
        @media (max-width: @screen-xs-max) {
            max-width: 90% !important;
        }
        .list-player-progress-current {
            background-color: @green-00;
            display: block;
            height: 4px;
            border-radius: 2px;
            overflow: hidden;

            &.mark-complete {
              width: 100% !important;
              transition: width 2s;
            }
        }
    }

    small {
        font-size: 12px;
        line-height: 12px;
    }

    .subimage_text {
        text-align: center;
        display: block;
        margin-bottom: 20px;
        margin-top: 5px;
    }
}

.store-photo-wrapper {
    > a {
        display: block;
    }

    img {
        margin: 0 auto;
        display: inline-block;
        width: auto;
    }

    .label {
        clear: both;
    }
}

.search-result {
    .archive-support {
        .archive-photo-wrapper {
            max-height: 225px;
            max-width: 400px;

            img {
                width: auto;
                height: auto;
                object-fit: contain;
            }
        }
    }
}


.archive-description-wrapper,
.store-description-wrapper {
    .make-xs-column(12);
    .make-sm-column(7);
    .make-md-column(8);
    //.make-lg-column(9);

    @media (max-width: @screen-xs-max) {
        margin-bottom: 10px;
    }

    .product-type {
        color: @muted-text;
        margin-bottom: 1em;
    }
}

.archive-description-wrapper {
    p {
        font-family: @font-family-base;
        font-weight: normal !important;
        font-size: 1.1em !important;
        line-height: 1.65 !important;
        text-align: left !important;
        color: @green-darken-02 !important;
        margin-bottom: 1em;
    }
}

@media (min-width: @screen-sm-min) {
    .archive-description-wrapper {
        h4 {
            font-size: 22px !important;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .archive-description-wrapper {
        h4 {
            font-size: 18px !important;
        }
    }
}

// ----------------------- Product Detail
.product-detail,
.lg-photo-wrapper {
    .store-photo-wrapper {
        .make-xs-column(12);
        .make-sm-column(12);
        .make-md-column(6);
        .make-lg-column(5);

        a.product-image-wrap {
            margin: 0 auto;
            display: block;
            text-align: center;

            > img {
                display: inline-block;
                margin: 0 auto;
            }
        }
    }

    .store-description-wrapper {
        .make-xs-column(12);
        .make-sm-column(12);
        .make-md-column(6);
        .make-lg-column(7);
    }
}

.archive-date,
.store-date {
    color: @muted-text;
    font-size: @font-size-small;
    font-family: @font-family-secondary;
    display: block;
    line-height: 2.5;
    margin-left: 15px;
    margin-bottom: 10px;

    &.archive-date-detail {
        margin-left: 0;
    }
}

@media (max-width: @screen-xs-max) {
    #options {
        .btn-primary-call {
            max-width: 100%;
            width: 100% !important;
        }
    }
}

.distribution-options {
    .text-center;
    min-width: 50px;
    background: @muted-background;

    a {
        color: @muted-text;

        &:hover {
            color: @bold-link;
            text-decoration: none;
        }
    }
}

a.title-subscribe {
    font-size: @font-size-large;
    font-family: @font-family-sans-serif;
    color: @social-share-links;
    font-weight: 500;
    .transition(~"all 0.2s ease-in-out");

    &:hover {
        color: @rss;
    }
}

.archive-title,
.store-title {
    margin-top: 0;
    margin-bottom: 0;
    text-transform: none;

    a {
        color: @link-color;
    }
}

.archive-description-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .archive-title {
        margin-top: 0.2em;
        flex: 0 0 auto;
        text-transform: none;
    }
    .archive-subtitle {
      font-size: 16px;
      line-height: 20px;
    }
    .library-binding {
        justify-content: flex-start;
    }

    .scripture-reference {
        flex: 0 0 auto;
        margin: 4px 0 0 0;

        a,
        .selected_scriptures {
            color: @taupe-darken-01 !important;
            font-weight: bold;
            letter-spacing: 0;
            font-size: 14px !important;
            line-height: 17px !important;
        }

        [class^="ti-"],
        [class*=" ti-"] {
            // .svg-position-fix;
            color: @muted-text;
        }
    }

    .download-mobile {
        font-size: 14px;

        .download-mobile-link {
            color: @taupe-00;
            font-weight: bold;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .sku {
        flex: 0 0 auto;
        font-size: 12px;
        color: @taupe-darken-01;
        font-weight: 500;
        margin: 5px 0;
        padding: 0;
        line-height: 14px;
        display: block;

        .resource_type {
            text-transform: none;
        }

        .sku-dot {
            color: @taupe-lighten-03;
        }
    }

    .new-content {
      font-family: @font-family-sans-serif;
      background-color: @taupe-darken-01;
      font-size: 12px;
      line-height: 18px;
      padding: 0 12px;
      border-radius: 12px;
      color: @white-00;
      min-width: 150px;
      margin: 0 auto 0 0;
      text-align: center;
      display: block;
    }

    p.series {
        flex: 0 0 auto;
        margin: 0;
        font-size: 14px !important;
        line-height: 17px !important;
        margin: 0;
        padding: 0;
        color: @taupe-darken-01;

        a {
            color: @taupe-darken-01;
        }
    }

    hr {
        flex: 1 1 auto;
        align-self: flex-end;

        width: 100%;
        vertical-align: bottom;
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: 1px solid @white-darken-02;
        border-top: none;
    }

    min-height: 145px;
    @media (min-width: @screen-lg-min) {
        min-height: 190px;
    }

    margin-bottom: 20px;
}

.results {
    display: inline-block;
    color: @muted-text;
    font-style: italic;
    margin-top: @line-height-computed;
    margin-bottom: @line-height-computed;

    @media (max-width: @screen-xs-max) {
        margin-top: 0;
    }
}

.purchase-options {
    margin-bottom: @line-height-computed;
    background: @muted-background;
    margin-top: 10px;

    .available-formats {
        .format {
            background-color: @white-00;
            border-color: @border-color;
            width: 100%;
            min-width: auto;
            .box-sizing(border-box);

            .format-helper {
                width: 100%;
                line-height: 1.5em;
                padding: 5px 6px;
            }

            &.selected {
                border-color: @brand-primary;
            }
        }
    }

    .btn-primary-call {
        white-space: normal;
    }
}

.featured-support {
    .make-row();
}

.featured-wrapper {
    img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;

        @media (min-width: @screen-sm-min) {
            max-height: 220px;
        }

        @media (min-width: @screen-md-min) {
            max-height: 293px;
        }

        @media (min-width: @screen-lg-min) {
            max-height: 360px;
        }
    }

    .make-sm-column(6);
}

.categories {
    margin-top: @line-height-computed;
    .small;

    span {
        color: @muted-text;
    }
}

// -------------------------------------------- Broadcast Landing
.broadcast-media-wrapper {

    .sermon-list-section__container {
        &.broadcast-topics {
            margin: 25px 0 0 0;
        }

        &.broadcast-sermon {
            margin: 35px 0 20px 0;
        }
    }
    .broadcast-topics{
        ul.topics-list {
            li  {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0;
            }
        }
    }
}

// -------------------------------------------- Sermon Landing
.resources_sermons_single-player {
    .sermon-list-section__container {
        &.sermon-topics {
            margin: 25px 0 0 0;
            ul {
                margin: 0;
            }
        }

        &.sermon-series {
            margin: 45px 0 20px 0 ;

            @media (max-width: @screen-tiny-max) {
                flex-direction: column;
            }
        }
    }
    .sermon-topics {
        ul.topics-list {
            margin: 0;
            li  {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0;
            }
        }
    }
}

// -------------------------------------------- Series Landing
.resources_series_single-player {
    p.well {
        display: none;
    }

    .header-support {
        .article-title-wrapper {
            margin: 15px 0 0 0;

            .article-title {
                margin: 0;
            }
            .article-subtitle {
                margin-top: 5px;
                color: @taupe-darken-03;
            }
        }
    }

    .main-content__heroblock {
        margin-bottom: 35px !important;

        .main-content__imgwrapper {
            position: relative;

            .main-content__heroimg {
                transition: .5s ease;
                backface-visibility: hidden;
                opacity: 1;
                text-align: center;
            }
            .main-content__heroimg_overlay {
                opacity: 0;
                transition: .5s ease;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;

                .heroimg_overlay__box {
                    flex: 1 1 0;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    text-align: center;
                    border-radius: 12px;
                    padding: 20px;
                    width: auto;
                    max-width: 250px;
                    position: relative;
                    span {
                        display: block;
                        margin-top: 10px;
                        color: @white-00;
                        padding: 10px 20px;
                        border-radius: 20px;
                        background-color: @taupe-darken-01;
                        border: 2px solid @white-00;
                    }
                }
            }

            &:hover {
                cursor: pointer;
                .main-content__heroimg {
                    opacity: 0.7;
                }
                .main-content__heroimg_overlay {
                    opacity: 1;
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 10px;
                }
            }
        }
    }

    .series-info-wrapper {
        margin-bottom: 24px;
        @media (min-width: @screen-md-min) {
            margin-top: -80px;
        }

        .series-info__date {
            color: @taupe-darken-01;
            margin: 0;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            font-size: 16px;
        }
        .series-info__title {
            color: @green-00;
            margin: 0;
            line-height: 48px;
            font-size: 32px;
            font-weight: normal;
            letter-spacing: 0;
        }
        .series-info__scripture {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            color: @taupe-darken-01;
        }
        .series-info__type {
            small {
                font-size: 12px;
                line-height: 24px;
                color: @taupe-darken-01;
                font-weight: normal;
                letter-spacing: 0;
                margin: 0;
            }
        }
    }
    .series-description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        color: @taupe-darken-03;
    }
    .sermon-list-section__container {
        &.series-topics {
            margin: 25px 0 0 0;

            ul {
                margin: 0;
            }
        }
        &.series-sermons {
            margin: 45px 0 0 0 ;

            @media (max-width: @screen-tiny-max) {
                flex-direction: column;
            }
        }
        &.supplemental-materials {
            margin: 25px 0 0 0;

        }
    }
    .supplemental-materials__table {
        tr:first {
          td {
            border-top-color: transparent;
          }
        }
        td.icon {
            text-align: right;
            width: 36px;
        }
        a {
            color: @taupe-darken-01;
        }
    }

    .series-volumes__wrapper {
        text-align: right;
        margin-top: 5px;
        margin-bottom: 35px;

        .series-volumes__label {
            color: @taupe-darken-01;
        }

        .volume-group__entry  {
            margin: 1px;
        }

        .series_volume_picker {
            width: auto;
            display: inline-block;
        }

    }


    .series-topics ul li {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        color: @taupe-darken-01;
    }
}

// -------------------------------------------- Topics
#body.topics {
    .topic-main-list.topicgroup-topics {
        padding-left: 0;
        .slide-lateral_tile {
            width: 23%;
            padding-bottom: 5px;
            @media (max-width: @screen-tiny-max) {
                padding: 5px;
            }
            .slide-lateral_title {
                font-family: @font-family-sans-serif;
                font-weight: 700;

                @media (min-width: @screen-lg-min) {
                    font-size: 28px;
                    line-height: 32px;
                }
                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    font-size: 22px;
                    line-height: 28px;
                }
            }
            .slide-lateral_thumb {
                height: auto;
                width: 100%;
            }
            @media (max-width: @screen-sm-max) {
                width: auto;
                .slide-lateral_thumb {
                    height: 200px;
                    width: auto;
                }
            }
        }
    }

    .subtopic-wrapper {
        .subtopic-tab-option-wrapper {
            margin-bottom: 20px;
            .subtopic-nav {
                border-bottom: 1px solid @taupe-lighten-04;

                li {
                    list-style: none;
                    border: none;
                    a {
                        border: none;
                        padding: 0;
                        cursor: pointer;
                        color: @taupe-darken-03;
                        .navtitle {
                            font-size: 14px;
                            line-height: 19px;
                            letter-spacing: 0;
                            font-family: @font-family-sans-serif;
                            font-weight: 800;
                            display: inline-block;
                            padding: 15px;
                            transition: all 0.25s ease;
                            text-transform: uppercase;
                            border-bottom-width: 4px;
                            border-color: transparent;
                            border-bottom-style: solid;
                        }
                    }
                    &.active a {
                        background: transparent;
                        color: @green-00;
                        .navtitle {
                            border-color: @green-00;
                            transition: all 0.25s ease;
                        }
                    }
                    @media (max-width: @screen-tiny-max) {
                        width: 49%;
                        a {
                            .navtitle {
                                padding-left: 5px;
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        .recent__subtopic_box {
            margin-top: 35px;
            .recent_subtopic_box--subtopics {
                column-count: 4;
                @media (max-width: @screen-xs-max) {
                    column-count: 3;
                }
                @media (max-width: @screen-tiny-max) {
                    column-count: 2;
                }
                margin-bottom: 25px;
                a {
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0;
                    font-family: @font-family-sans-serif;
                    font-weight: 600;
                    color: @grey-darken-02;
                    &:hover {
                        color: @grey-darken-02;
                        text-decoration: underline;
                    }
                }
            }
        }

        .topicgroup__subtopic_box {
            margin-top: 35px;
            .topicgroup__subtopic_box--title {
                width: 100%;
                h3 {
                    padding: 0;
                    margin-bottom: 5px;
                    margin-top: 15px;
                    a {
                        font-size: 24px;
                        line-height: 32px;
                        letter-spacing: 0;
                        color: @grey-darken-02;
                        &:hover {
                            color: @grey-darken-02;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .topicgroup__subtopic_box--subtopics {
                columns: 165px 5;
                margin-left: 60px;
                margin-bottom: 25px;
                a {
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0;
                    font-family: @font-family-sans-serif;
                    font-weight: 600;
                    color: @grey-darken-02;
                    &:hover {
                        color: @grey-darken-02;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .pagination {
        > li {
            > a {
                font-weight: bold;
                letter-spacing: 0;
                background-image: none;
                border-color: @taupe-lighten-04;
                color: @taupe-darken-01;
                background-color: @white-darken-01;
                &:hover,
                &:focus {
                    background-color: @white-00;
                }
            }
            &.active {
                > a {
                    background-color: @white-00;
                    color: @green-darken-01;
                }
            }
        }
    }
}

#body.topics-details {
    .main-content-support {
        .main-content__topics-wrapper {
            margin: 65px 15px 15px 15px;
            @media (max-width: @screen-xs-max) {
                margin-top: 0;
            }
            .main-content__heroblock {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                align-content: stretch;
                margin-bottom: 32px;
                border-radius: 16px;
                background-color: @white-darken-02;

                .main-content__heroimgwrapper {
                    border-radius: 16px 0 0 16px;
                    width: 25%;
                    flex: 0 0 auto;
                    overflow: hidden;
                    background-size: cover;
                    background-position: top;
                }

                .main-content__herocontent {
                    flex: 1 1 auto;
                    padding: 30px;
                    width: 100%;
                    letter-spacing: 0;
                    font-size: 16px;
                    line-height: 24px;
                    font-family: @font-family-serif;

                    @media (max-width: @screen-xs-max) {
                        padding: 15px;
                    }

                    .main-content__herosupertitle {
                        a {
                            color: @taupe-darken-01;
                            font-weight: normal;
                        }
                    }
                    .main-content__herotitle {
                        font-weight: 800;
                        padding: 0;
                        font-family: @font-family-sans-serif;
                        margin: 0;
                        font-size: 20px;
                        line-height: 24px;
                        color: @taupe-darken-03;
                    }
                    .main-content__herocopy {
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 28px;
                        margin: 24px 0 36px 0;
                        letter-spacing: 0;
                        color: @taupe-darken-03;
                        font-family: @font-family-sans-serif;
                    }

                    .main-content__herobtnwrapper {
                        position: relative;
                        margin: 0 -30px;
                        @media (max-width: @screen-tiny-max) {
                            width: 90%;
                        }
                        .main-content__herobtn {
                            text-align: left;
                            margin-left: 30px;
                            font-weight: 800;
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: 0;
                            div {
                                font-weight: 800;
                                padding: 10px 16px;
                                @media (max-width: @screen-xs-max) {
                                    padding: 8px 5px;
                                }
                                span {
                                    text-transform: uppercase;
                                    display: inline-block;
                                    &:after {
                                        content: url(/static/images/responsive/icons8-chevron-down.svg);
                                        margin-left: 35px;
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                        &.open {
                            .main-content__herobtn {
                                div {
                                    span {
                                        &:after {
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                        }

                        .topicgroup-subtopics__wrapper {
                            position: absolute;
                            width: 100%;
                            top: 40px;
                            left: initial;
                            padding: 20px 20px 10px 20px;
                            border-radius: 16px;
                            @media (max-width: @screen-xs-max) {
                                padding: 15px;
                                max-height: 2400px;
                                overflow-y: auto;
                                overflow-x: hidden;
                                min-width: 250px;
                            }

                            @media (min-width: @screen-xs-min) {
                                column-count: 2;
                            }
                            @media (min-width: @screen-md-min) {
                                column-count: 3;
                            }
                            .subtopic_list_item {
                                min-height: 47px;
                                text-align: right;
                                break-inside: avoid;

                                a {
                                    display: flex;
                                    flex-flow: row nowrap;
                                    justify-content: flex-end;
                                    align-items: flex-start;
                                    align-content: flex-start;
                                    color: @taupe-darken-03;
                                    font-size: 14px;
                                    font-family: @font-family-sans-serif;
                                    line-height: 20px;
                                    letter-spacing: 0;
                                    font-weight: bold;
                                    text-align: right;
                                    vertical-align: top;
                                    padding: 5px;
                                    width: 100%;
                                    margin-bottom: 1px;
                                    &.active {
                                        background-color: @white-darken-01;
                                        border-radius: 6px;
                                    }

                                    &:hover {
                                        background-color: @white-darken-01;
                                        opacity: 0.9;
                                        border-radius: 6px;
                                    }
                                }
                                .resource_name {
                                    text-align: right;
                                    display: inline-block;
                                    flex: 1 1 auto;
                                }
                                .resource_count {
                                    width: 40px;
                                    text-align: center;
                                    background-color: @white-darken-02;
                                    height: 18px;
                                    border-radius: 6px;
                                    display: inline-block;
                                    margin-left: 5px;
                                    flex: 0 0 auto;
                                }
                            }
                        }
                    }
                }
            }

            .main-content__resource_list {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                align-content: stretch;
                @media (max-width: @screen-xs-max) {
                    display: block;
                }
                .resource-list__sidebar {
                    width: 25%;
                    flex: 0 0 auto;

                    @media (max-width: @screen-xs-max) {
                        width: 100%;
                        flex: unset;
                        height: 46px;
                        margin-bottom: 20px;
                        overflow: hidden;
                        transition: all 0.6s ease-in-out;
                        &.open {
                            height: auto;
                            transition: all 0.3s ease-in-out;
                            .topics-sidebar {
                                .topics-sidebar__title {
                                    .arrow-toggle {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }

                    .topics-sidebar {
                        width: 100%;
                        border: 1px solid @taupe-lighten-04;
                        list-style: none;
                        font-family: @font-family-sans-serif;
                        letter-spacing: 0;
                        margin: 0;
                        padding: 0;
                        .topics-sidebar__title {
                            margin: 0;
                            padding: 25px;
                            font-size: 16px;
                            font-weight: 900;
                            line-height: 24px;
                            vertical-align: middle;
                            color: @taupe-darken-03;
                            background-color: @white-darken-01;

                            @media (max-width: @screen-xs-max) {
                                cursor: pointer;
                                padding: 10px;
                                .arrow-toggle {
                                    float: right;
                                    text-align: right;
                                    margin: 9px 0;
                                    content: url(/static/images/responsive/icons8-chevron-down.svg);
                                }
                            }
                        }
                        .topics-sidebar__item {
                            border-top: 1px solid @taupe-lighten-04;
                            padding: 12px 25px;
                            background-color: @white-darken-01;
                            a {
                                font-size: 16px;
                                font-weight: bold;
                                line-height: 24px;
                                vertical-align: middle;
                                color: @taupe-darken-03;
                                display: block;
                                height: 100%;
                                width: 100%;
                                cursor: pointer;

                                &:hover {
                                    text-decoration: none;
                                }
                            }
                            &:hover {
                                background-color: @white-00;
                            }
                            &.active {
                                background-color: @white-00;
                                a {
                                    color: @link-color;
                                }
                                &:hover {
                                    a {
                                        color: @link-hover-color;
                                    }
                                }
                            }
                        }
                    }
                }

                .resource-list__results {
                    padding-left: 30px;
                    flex: 1 1 auto;
                    @media (max-width: @screen-xs-max) {
                        flex: unset;
                        width: 100%;
                        padding: 0;
                    }
                    .resource-list__result-details {
                        width: 100%;
                        border-bottom: 2px solid @taupe-lighten-04;
                        margin-bottom: 40px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        line-height: 24px;
                        font-size: 14px;
                        letter-spacing: 0;
                        font-weight: 600;
                        white-space: nowrap;
                        color: @taupe-darken-01;
                        .results-count__type {
                            font-weight: 800;
                            color: @taupe-darken-03;
                        }
                        .results-sort {
                            width: 200px;
                            height: 36px;
                            @media (max-width: @screen-xs-max) {
                                width: auto;
                            }
                            .topic-results-sort-btn {
                                width: 100%;
                                height: 32px;
                                border-radius: 4px;
                                border: 1px solid @white-darken-03;
                                box-sizing: border-box;
                                text-align: left;
                                @media (max-width: @screen-xs-max) {
                                    padding: 6px;
                                }

                                .sort_by_label {
                                    font-size: 14px;
                                    line-height: 24px;
                                    letter-spacing: 0;
                                    font-weight: 600;
                                    white-space: nowrap;
                                    vertical-align: middle;
                                    &:after {
                                        content: url(/static/images/responsive/icons8-chevron-down.svg);
                                        float: right;
                                        display: inline-block;
                                        text-align: right;
                                        min-width: 20px;
                                    }
                                }
                            }
                            &.open {
                                .topic-results-sort-btn {
                                    .sort_by_label {
                                        &:after {
                                            transform: rotate(180deg);
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                            .topic-results-sort-box {
                                left: auto;
                                right: initial;
                                position: absolute;
                                top: initial;
                                border-radius: 16px;
                                border: 2px solid @white-darken-02;
                                padding: 20px 25px;
                                width: 200px;
                                @media (max-width: @screen-xs-max) {
                                    width: 165px;
                                }
                                .sort_option {
                                    a {
                                        color: @taupe-darken-03;
                                        line-height: 24px;
                                        font-size: 14px;
                                        font-weight: bold;
                                        letter-spacing: 0;
                                        margin: 0;
                                        padding: 0;

                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                    &.active {
                                        a {
                                            font-weight: 900;
                                            background-color: @white-00;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// -------------------------------------------- Sermon Topics
.topic-lists {
    .make-row;

    .link-list {
        .make-sm-column(6);

        .section-letter {
            font-size: 2.5em;
            font-family: @font-family-secondary;
            font-weight: 500;
            border-top: 1px solid @muted-background;
            padding-top: 20px;
            margin-top: 40px;

            &:first-child {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }
        }

        > li {
            margin-bottom: 0;
        }
    }
}

// -------------------------------------------- Series Volumes
.volume-group {
    width: 100%;
    margin-top: 36px;

    .volume-group__header {
        width: 100%;
        background-color: @white-darken-01;
        color: @taupe-darken-03;
        font-weight: 900;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0;
        line-height: 24px;
        padding: 12px 24px;
        border-bottom: 2px solid @taupe-lighten-04;
    }
    .volume-group__items {
        padding: 12px;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .volume-group__entry {
            flex: 0 0 0px;
            border-radius: 8px;
            margin-top: 12px;
            margin-left: 12px;
            display: block;
            color: @green-darken-01;
            font-weight: 900;
            span {
                display: block;
                min-width: 48px;
            }
            &.active {
                color: @white-darken-02;
            }
        }
    }
}

.sermon-list-section__container {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid @taupe-lighten-04;
    margin: 95px 0 45px 0;

    .sermon-list-section__title {
        color: @taupe-darken-03;
        font-family: @font-family-sans-serif;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
    }

    .sermon-list-section__count {
        color: @taupe-darken-02;
        font-family: @font-family-sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

// -------------------------------------------- Scripture
.testaments {
    .make-row();

    .testament {
        .make-md-column(6);

        .link-list {
            .make-row;
            .make-sm-column(6);
        }

        ol {
            margin-top: 0;

            > li {
                position: relative;
                clear: both;
                margin-bottom: 0;
                padding-left: 15px;

                &:before {
                    .ti;
                    content: "\F105";
                    position: absolute;
                    top: 9px;
                    left: -2px;
                    font-size: 14px;
                    color: @muted-text;
                }

                > a {
                    display: block;
                }
            }

            .no-content {
                color: @taupe-00;
            }
        }

        @media (max-width: @screen-sm-max) {
            clear: both;
        }
    }

    .chapter-list {
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        box-shadow: inset 0 10px 40px rgba(0, 0, 0, 0.1);

        a {
            color: @link-color;
        }
        .disabled {
            color: @muted-text;
            cursor: not-allowed;
        }

        @media (min-width: @screen-sm-min) {
            width: 380px;

            a, span {
                display: inline-block;
                width: 30px;
                text-align: right;
                height: 34px;
                line-height: 34px;
            }
        }

        @media (max-width: @screen-xs-max) {
            width: 100%;

            a, span {
                display: inline-block;
                width: 30px;
                text-align: right;
                height: 38px;
                line-height: 38px;
            }
        }

        position: relative;

        .well {
            background-color: @well-bg;
            #gradient
                > .vertical(@start-color: @white-darken-01, @end-color: @white-darken-02);
            clear: both;
            margin: 0;
            border-radius: 2px;
            padding: 15px 10px;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid @white-darken-01;
                position: absolute;
                top: -6px;
                left: 10%;
                z-index: 2;
            }

            @media (max-width: @screen-xs-max) {
                padding: 10px;
            }
        }
    }

    .dropdown-menu {
        position: relative !important;
    }

    @media (max-width: @screen-xs-max) {
        #old-testament > .link-list:last-child {
            .dropdown-menu {
                width: 100%;
            }
        }

        #new-testament > .link-list:last-child {
            .dropdown-menu {
                width: 100%;
            }
        }
    }

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        #new-testament > .link-list:last-child {
            .dropdown-menu {
                position: relative !important;
                left: auto !important;
                right: 0 !important;

                &:before {
                    left: auto;
                    right: 107px;
                }
            }
        }
    }
}

// -------------------------------------------- Upcoming Schedule
.main-content-wrapper {
  .section-title {
    &.upcoming-broadcasts__title {
      margin-top: 40px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}



// -------------------------------------------- Learning Tracks Related

// Series in Progress Section
.series-progress_overlay-container {
    position: relative;
    text-align: center;

    .series-progress_sermon_count {
        position: absolute;
        color: @grey-darken-01;
        background-color: @white-darken-01;
        border-radius: 12px;
        padding: 4px 10px 2px 10px;
        font-size: 13px;
        vertical-align: middle;
        bottom: 13px;
        right: 13px;
        line-height: 18px;
    }
}

.series-sermon-item {
    &.series-progress_overlay-container {

        .list-player-completed {
          visibility: hidden;
          transition-delay: 0s;
          transition-property: visibility;
          position: absolute;
          bottom: 5px;
          right: 1px;
          height: 28px;
          width: 28px;
        }
    }

  .sermon-not-complete {
    display: block !important;
  }
  .sermon-complete {
    display: none !important;
  }

  &.mark-complete {
      .list-player-progress-current {
          width: 100% !important;
          transition: width 0.75s linear;
      }
      .list-player-completed {
          visibility: visible;
          transition-delay: 0.75s;
          //display: block;
      }

      .sermon-complete {
          display: block !important;
      }
      .sermon-not-complete {
          display: none !important;
      }
    }
}
