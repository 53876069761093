// ---------------------------------------------------- Day Browser

.day-browser-outer-wrapper {
  background: @muted-background;
  .text-center;
  color: @muted-text;
  width: 100%;
  z-index: 500;
  -webkit-backface-visibility: hidden;

  &.affix {
    top: 0;
    margin-top: 0;
    .box-shadow(0 2px 3px fade(#000, 10%));
  }

  .back, .forward {
    display: block;
    position: absolute;
    top: 0;
    // font-size: @font-size-large;
    padding-top: 13px;
    padding-right: @line-height-computed/1.3;
    padding-bottom: 13px;
    padding-left: @line-height-computed/1.3;
    line-height: @line-height-computed;
    color: @muted-text;
    z-index: 300;
    text-decoration: none !important;
    [class^="ti-"] {
      position: relative;
      top: 1px;
    }
    @media (max-width: @screen-xs-max) {
      [class^="ti-"] {
        top: 4px;
      }
    }
    &:hover {
      background: darken(@muted-background, 5%);
      .day-control-text {
        .opacity(1);
      }
    }
  }

  .back {
    left: 0;
  }

  .forward {
    right: 0;
  }
}
.day-control-text {
  font-size: 15px;
  display: inline-block;
  line-height: @line-height-computed;
  position: relative;
  top:-2px;
  font-weight: 500;
  .opacity(.3);
  .transition(opacity .3s);
}
.day-browser-support {
  .make-row();
}
.day-controls {
  position: relative;
  max-width: 320px;
  @media (min-width: @screen-md-min) {
    max-width: 400px;
  }
  .center-block;
}

#date-picker {
  font-size: @font-size-small;
  font-weight: bold;
  color: @taupe-00;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  background: transparent;
  width: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 110;

  @media (max-width: @screen-xs-max) {
    letter-spacing: 0.5px;
    font-weight: 400;
  }
}
#date-picker2 {
  border: none;
  background: transparent;
  position: relative;
  z-index: 110;
  text-transform: uppercase;
}
.current-date {
  font-size: @font-size-small;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-top: (@line-height-computed * .5);
  padding-bottom: (@line-height-computed * .5);
  line-height: @line-height-computed;
   @media (max-width: @screen-xs-max) {
    padding-top: 14px;
    padding-bottom: 11px;
  }
}
