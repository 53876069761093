.library-binding {
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;

    &.homepage {
        margin: 5px 0;
        justify-content: flex-end;

        .dropdown-menu {
            @media (max-width: @screen-xs-max) {
                left: 0;
                right: auto;
            }
        }
    }

    .library-binding_feature,
    .icon-post-aquire {
        flex: 0 0 auto;
        position: relative;
        text-align: center;
        height: 44px;
        width: 44px;
        display: block;
        margin: 0;
        letter-spacing: normal;

        .favorite-info-box {
            height: 100%;
            width: 100%;
        }

        a {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            &:hover {
                background-color: @white-darken-02;
                border-radius: 4px;
            }

            img {
                cursor: pointer;
                flex: 0 0 auto;
                width: auto;
                height: auto;
                align-self: center;
            }
        }

        .queue-toggle {
            .popover {
                z-index: 99999;
            }

            &:focus {
                .tooltip {
                    display: none;
                }
            }
        }

        &.favorite-menu--right {
            .dropdown-menu {
                left: auto;
                right: 0;
            }
        }
        &.favorite-menu--center {
            .dropdown-menu {
                left: auto;
                right: auto;
            }
        }
    }

    .dropdown-menu {
        padding: 1em;
        border: 2px solid @white-darken-02;
        border-radius: 1em;
        margin: 0px;

        li {
            font-size: 16px;
            margin-bottom: 0px;

            a {
                padding-left: 5px;
                color: @black;
                font-weight: bolder;
                cursor: pointer;

                &:hover {
                    color: @taupe-darken-01;
                }

                i {
                    margin: 5px;
                    line-height: 0;
                    font-size: 120%;
                }
            }
        }

        .sharable .ti-twitter {
            color: @black;
        }
        .sharable .ti-facebook {
            color: @black;
        }


        // More Info Share dropdown box
        &.dropdown-menu--more-info {
            padding: 12px 0;

            .more-info__block-item {
                white-space: nowrap;
                margin: 6px 15px;
                height: 32px;
                line-height: 32px;
                min-width: 250px;

                a {
                    color: @taupe-darken-01;
                    justify-content: flex-start;
                    line-height: 32px;
                    font-size: 12px;
                    padding: 0 15px 0 0;
                    background-color: @white-darken-02;

                    .svg-icon__wrapper {
                        margin: 5px;
                    }

                    &:hover {
                        border-radius: 0;
                        background-color: @white-00;
                    }
                }
            }

            .sharable {
                &.sharable--header {
                    color: @taupe-darken-01;
                    justify-content: flex-start;
                    padding: 10px 5px 5px 15px;
                    line-height: 24px;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 900;
                }
                a {
                    color: @taupe-darken-01;
                    justify-content: flex-start;
                    line-height: 24px;
                    font-size: 14px;
                    .ti-twitter,
                    .ti-facebook,
                    .ti-envelope {
                        color: @taupe-darken-01;
                    }

                    &:hover {
                        border-radius: 0;
                    }
                }
            }
        }

        @media (max-width: @screen-xs-max) {
            left: auto;
            right: 0;
        }
        &.dropdown-menu--right {
            left: auto;
            right: 0;
        }
    }

    @media (max-width: @screen-xs-max) {
        text-align: center;
    }
}

.post-aquire {
    border-radius: 7px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    margin: 2em auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 5px;
    align-items: center;
    align-content: center;
    min-width: 46px;
    @media (max-width: @screen-xs-max) {
        padding: 5px 20px;
        flex-direction: row;
        width: fit-content;
    }
}

// Favorites
.favorites-toggle {
    cursor: pointer;
    &.error {
        background-color: red;
    }
    .unfavorite-heart {
        display: none;
    }
    .favorite-heart {
        display: inline-block;
    }
    &.favorite {
        .unfavorite-heart {
            display: inline-block;
        }
        .favorite-heart {
            display: none;
        }
    }
}

.favorite-info-box {
    display: inline-block;
    .dropdown-menu-favorite {
        min-width: 250px;
        padding: 20px !important;
        @media (max-width: @screen-xs-max) {
            left: 0;
            right: auto;
        }
        p {
            text-align: left;
            font-size: 1em;
            line-height: 1.5em;
            margin-bottom: 1em;
            a {
                color: @green-00;
            }
            &.center {
                text-align: center !important;
            }
            &.big {
                font-weight: bold !important;
            }
        }
    }
}

// Specific Pages
.library-binding {
    &.bible-favorite,
    &.motm,
    &.daily {
        text-align: right;
        justify-content: flex-end;
        width: 100%;
        margin: 0;
        .dropdown {
            .dropdown-menu {
                left: auto;
                right: 0;
            }
        }
    }

    &.devotional {
        justify-content: flex-end;
        margin-top: 5px;
    }

    &.homepage {
        .dropdown-menu {
            @media (max-width: @screen-xs-max) {
                left: auto;
                right: 0;
            }
        }
    }

    &.series {
        .library-binding_feature {
            @media (max-width: @screen-xs-max) {
                margin: 0px;
            }
            img {
                @media (max-width: @screen-xs-max) {
                    width: 21px;
                    height: 21px;
                }
            }

          &.learning-tracks-toggle__wrapper {
              .learning-tracks-toggle__bubble {
                background-color: @white-darken-02;
                font-size: 12px;
                line-height: 32px;
                margin: 6px 0;
                position: absolute;
                right: 46px;
                padding: 0 12px;
                border-radius: 16px;
                white-space: nowrap;
                width: auto;
                opacity: 0;
                transition: opacity 2s linear 4s;

                .learning-tracks-toggle__copy {
                  color: @taupe-00;
                }
              }

              &:hover {
                .learning-tracks-toggle__bubble {
                  opacity: 1;
                  transition: opacity 0.35s linear 0s;
                }
              }
          }
        }
    }
}

.media-options-support {
    &.series-media-options-support {
        justify-content: flex-end;

        .library-binding.series {
            .learning-tracks__alert {
                background-color: @white-darken-01;
                color: @taupe-00;
                font-size: 12px;
                line-height: 32px;
                margin: 6px -44px 6px 0;
                padding: 0 50px 0 16px;
                border-radius: 16px;
            }
        }
    }
}

// SVG handling for action icons
.svg-icon__wrapper {
  height: 24px;
  width: 24px;

  svg {
    max-height: 24px;
    max-width: 24px;
  }
}

.svg-icon--default {
  fill: @taupe-darken-01;
}
.svg-icon--default_stroke {
  stroke: @taupe-darken-01;
}
























