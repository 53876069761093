    .tfl_devotional {
      &.devotional_details {
        margin-top: 40px;
      }

      .content-passage {
        @media(min-width:@screen-sm-min) {
          width: 87.5%; // 7/8th grid
        }

        .devotional_scripture_text,
        .devotional_scripture_text p {
          font-family: @font-family-sans-serif;
          line-height: 32px;
          font-size: 20px;
          letter-spacing: 0;
          color: @taupe-darken-01;
          font-weight: 100;
        }
        .devotional_scripture_reference {
          padding-top: 12px;
          a {
            color: @taupe-darken-01;
            font-weight: 900;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }


      .content-body {
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0;
          color: @taupe-darken-03;
        }
        .ab_footnote {
          vertical-align: super;
          font-size: 11px;
        }

        &::first-letter {
          float: left;
          font-size: 82px;
          font-family: @font-family-serif;
          line-height: 72px;
          letter-spacing: 0;
          color: @taupe-darken-01;
          padding-top: 0;
          padding-right: 8px;
          padding-left: 3px;
          font-style: normal;
          //initial-letter: 3; // - no browser support
        }
      }

      .devotional-subhead
      {
        font-family: @font-family-serif;
        font-size: 22px;
        text-align: center;
        color: @taupe-00;
        @media (max-width:@screen-sm-max)
        {
          text-align: left;
        }
      }

      .devotional-questions
      {
        display: flex;
        gap: 30px;
        margin: 20px 0 40px 0;

        .devotional-questions__question
        {
          display: flex;
          flex-direction: column;
          flex: 1 1;
          align-items: center;
          text-align: center;
        }
        .devotional-questions__icon
        {
          width: 30px;
          height: 30px;
          margin: 0 0 20px 0;
        }
        .devotional-questions__text
        {
          padding: 0;
          margin: 0;
        }

        @media (max-width:@screen-sm-max)
        {
          flex-direction: column;
          gap: 15px;

          .devotional-questions__icon
          {
            width: 20px;
            height: 20px;
            margin: 0;
          }
          .devotional-questions__question
          {
            flex-direction: row;
            gap: 20px;
            align-items: center;
            justify-content: start;
            text-align: left;
          }
        }
      }

      .devotional_topics {
        padding-top: 10px;

        .devotional_topics_list_title {
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          color: @taupe-darken-01;
          letter-spacing: 0;
          padding-right: 6px;
        }

        .devotional_topics_list_item {
          letter-spacing: 0;
          line-height: 24px;
          font-size: 16px;
          padding-left: 6px;
        }
      }

      .devotional_footnotes {
        margin-top: 50px;
        background-color: @white-darken-01;
        padding: 22px;

        .devotional_footnotes_title {
          font-weight: 800;
          line-height: 24px;
          letter-spacing: 1px;
          font-size: 14px;
          text-transform: uppercase;
          color: @taupe-darken-03;
        }
        .devotional_footnotes_list {
          .devotional_footnotes_list_item {
            padding: 10px 0;
            .ab_footnote {
              vertical-align: super;
              font-size: 11px;
            }

          }
        }
      }

      // outro credits
      .content-credits
      {
        display: flex;
        flex-wrap: wrap;
        padding: 72px 0;

        .content-credits_photo
        {
          margin: 0 45px 10px 0;

          img
          {
            display: block;
            width: 72px;
            height: 72px;
            margin: 0 auto;
            border-radius: 72px;
          }

          @media (max-width: @screen-sm-max)
          {
            flex-basis: 100%;
            margin: 0 0 20px 0;
          }

        }
        .content-credits_copy
        {
          flex: 1;
          font-size: 14px;
          color: @warm-gray-lighten-01;

          @media (max-width: @screen-sm-max)
          {
            text-align: center;
          }
        }
      }
    }

// Archive Pages
body.devotionals.more {
  .article-title {
    margin: 10px 0;
  }
  .volume-group {
    margin-top: 72px;
  }
}
