.queue-toggle {
  display: inline-block;
  z-index: 155;

  .tooltip {
    z-index: 154;
    top: -35px !important;
  }
  .popover {
    z-index: 155;
    &.top {
      background-color: @white-darken-01;
      & > .arrow:after {
        border-top-color: @white-darken-01;
      }
    }
    .popover-title {
      display: none;
    }

    .popover-content {
      padding: 0px;
      width: 140px;


      .queue-button-wrapper {
        background: @white-darken-01;

        button {
          background: @white-darken-01;
          width: 100%;
          border: none;
          padding: 6px;
          color: @grey-darken-01;
          font-size: 14px;
          &:hover {
            background: @white-darken-02;
          }
        }

        hr {
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }

  a {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    }
  }
}

body.modal-open {
  padding-right: 15px !important;
  background-color: white;
  //overflow-y: hidden;
  #scroll-player,
  #scroll-player .mejs-controls .mejs-time-rail {
    right: 30px;
  }
}


#queueModal {
  text-align: left;
  padding: 0px !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 10000; // Over top dropdown.

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    transform: translate3d(0, 100vh, 0);
    transition: transform 0.15s ease;

    .modal-content {
      background: none;
      color: @white-00;
      border: none;
      max-height: 95vh;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      align-content: flex-start;
      position: relative;
      box-shadow: none;
      margin: 0 auto;

      width: 80%;
      max-width: 1200px;
      @media (max-width: @screen-sm-max) {
        width: 95%;
      }
      @media (max-width: @screen-tiny-max) {
        width: 98%;
      }
    }
  }
  &.in {
    .modal-dialog {
      transform: translate3d(0, 0, 0);
      transition: transform .5s ease;
    }
  }

  .empty-queue-wrapper {
    text-align: center;
    height: 60vh;
    width: 100%;
    padding: 10%;
    color: @white-darken-01;

    span {
      font-size: 24px;
      @media (max-width: @screen-xs-max) {
        font-size: 18px;
      }
    }
    p {
      @media (max-width: @screen-xs-max) {
        font-size: 12px;
      }
    }

    .queue-add-example-wrapper {
      .queue-screenshot.horizontal {
        width: 100%;
        max-width: 300px;
      }
      .queue-screenshot.vertical {
        @media (max-width: @screen-tiny-max) {
          width: 25%;
        }
      }
    }
  }

  .queue-list-wrapper {
    .queue-row.current {
      background-color: @blue-00;
      .not-current {
        display: none;
      }
      .queue-photo-wrapper {
        img {
          box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.5);
        }
        &:hover {
          img {
            box-shadow: none;
          }
        }
      }
      &.playing {
        .queue-details-wrapper {
          .queue-photo-wrapper {
            &:hover .queue-photo-overlay {
              background: rgba(0,0,0,0.7) url(/static/js/responsive/lib/mediaelement/icons/icons8-queue-pause.svg) no-repeat center center;
            }
          }
        }
      }
    }
    .queue-row.active {
      background-color: @blue-lighten-02;
    }
  }


  .queue-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    border-bottom: 1px solid @blue-lighten-01;
    background-color: @blue-darken-01;

    button:focus {
      outline: none;
    }
    button:hover {
      filter: brightness(75%);
    }

    .queue-button-wrapper {
      white-space: nowrap;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      padding: 5px;
      color: @taupe-darken-03;
      cursor: pointer;
      a {
        color: @taupe-darken-03;
        cursor: pointer;
      }
      &:hover {
        opacity: .75;
      }
    }

    .app-queue-popover-menu {
      min-width: 150px;
      padding: 10px;
    }

    .popover {
      border-radius: 16px;
    }

    .queue-left-gutter {
      width: 100px;
      text-align: center;
      flex: 0 0 auto;
      align-self: center;

      .sort-arrow-wrapper {
        display: flex;
        flex-direction: column;

        button {
          background: none;
          border: none;
          cursor: pointer;
        }

        .sort-arrow,
        .drag-drop-icon {
          width: 26px;
          height: 26px;
          align-self: center;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -ms-user-select: none;

          @media (max-width: @screen-sm-max) {
            width: 18px;
            height: 18px;
          }
        }

        .drag-queue-btn {
          cursor: move;
        }
      }
    }

    .queue-details-wrapper {
      flex: 1 1 auto;
      align-self: flex-start;
    }

    .queue-right-gutter {
      flex: 0 0 auto;
      width: 50px;
      align-self: flex-start;
      position: relative;

      a {
        background: none;
        height: 42px;
        width: 42px;
        border: none;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: none;
          border: none;
          outline-offset: none;
        }
      }
    }

    // Specific Rows
    &.queue-header {
      background: @blue-darken-02;
      height: 72px;
      border-radius: 7px 7px 0 0;
      position: absolute;
      z-index: 2;

      .queue-left-gutter {
        align-self: center;
        .close-listening-queue {
          background: none;
          border: none;
          height: 42px;
          width: 42px;

          img {
            width: 24px;
          }
        }
      }

      .queue-details-wrapper {
        align-self: center;
        h5 {
          text-align: left;
          text-transform: uppercase;
          font-family: @font-family-sans-serif;
          font-size: 24px;
          font-weight: 800;
          text-align: left;
          letter-spacing: 0;
          line-height: 24px;

          @media (max-width: @screen-tiny-max) {
            font-size: 16px;
          }
        }
      }

      .queue-right-gutter {
        margin-right: 10px;
        align-self: center;
      }

    }

    &.queue-footer {
      height: 186px;
      padding: 0;
      background: @blue-darken-04;
      margin: 0;
      position: relative;
      z-index: 2;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 40px;
        background: linear-gradient(rgba(0, 0, 0, 0.001), @blue-darken-02);
        border-radius: 7px;
        top: -40px;
      }

      .queue-details-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        text-align: center;
        padding: 0;
        min-width: 100px;

        .queue-player-title {
          flex: 0 0 auto;
          align-self: center;
          padding-top: 10px;
          padding-bottom: 10px;
          height: 90px;
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: center;
          .queue-title {
            max-height: 70px;
            padding: 0;
            font-size: 22px;
            line-height: 24px;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
        }

        .queue-player-controls {
          flex: 1 1 auto;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-content: stretch;
          height: 30px;
          padding: 0;
          margin: 0;
          .disabled {
            opacity: .4;
            cursor: not-allowed;
          }
          .queue-player-controls_previous {
            flex: 1 1 auto;
            text-align: right;
            img {
            }
          }
          .queue-player-controls_next {
            flex: 1 1 auto;
            text-align: left;
            img {
            }
          }
          .queue-player-controls_playpause {
            flex: 0 0 auto;
            width: 225px;
            text-align: center;
          }
          img {
            cursor: pointer;
          }
        }

        .queue-player-progress-bar-wrapper {
          height: 44px;
          margin-top: 20px;

          .queue-player-progress-bar {
            background-color: @white-darken-02;
            width: 100%;
            border-radius: 6.5px;
            height: 8px;
            position: relative;
            &:hover {
              .queue-player-time-handle {
                display: block;
                position: absolute;
                height: 10px;
                width: 10px;
                top: -4px;
                left: -10px;
                border: 8px solid @green-00;
                border-radius: 8px;
                background-color: @green-00;
                z-index: 3;
              }
              .queue-player-time-seek {
                background-color: @green-darken-03;
                opacity: 0.5;
                border-radius: 6.5px;
                height: 8px;
                z-index: 1;
                position: absolute;
                top: 0;
              }
            }

            .queue-player-progress-bar-current {
              background-color: @green-00;
              border-radius: 6.5px;
              height: 8px;
              z-index: 2
            }
          }
          .queue-player-time-wrapper {
            height: 16px;
            margin-top: 2px;
            margin-bottom: 16px;
            .queue-player-current-time {
              float: left;
              color: @white-darken-01;
              font-size: 12px;
              line-height: 16px;

            }
            .queue-player-total-time {
              float: right;
              color: @white-darken-01;
              font-size: 12px;
              line-height: 16px;
            }
          }

        }
      }

      .queue-right-gutter {
        margin-right: 10px;
        padding-top: 10px;
        align-self: flex-start;
      }
    }
  }

  .queue-list-wrapper {
    padding-top: 72px;
    border-radius: 7px 7px 0 0;
    overflow-y: scroll;
    scrollbar-color: @blue-lighten-01 @blue-darken-02;
    scrollbar-width: thin;
    background-color: @blue-darken-02;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 7px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      color: transparent;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @blue-lighten-04;
      border-radius: 4px;
      width: 6px;
    }

    .queue-row {
      padding: 30px 0;

      &.shrink {
        transition: height 0.3 linear;
        height: 0px;
      }

      .small {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0;
        font-family: @font-family-sans-serif;
        font-weight: 600;
        color: @white-darken-04;

        &.bright {
          color: @white-darken-01;
        }
      }



      .queue-details-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 0;

        .queue-photo-wrapper {
          padding: 0;
          flex: 0 0 auto;
          width: 250px;
          position: relative;

          .queue-photo-overlay {
            position: absolute;
            background: rgba(0,0,0,0.7);
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            border-radius: 14px;
            cursor: pointer;
            -webkit-transition: opacity 0.4s ease-in-out 0s;
            -moz-transition: opacity 0.4s ease-in-out 0s;
            transition: opacity 0.4s ease-in-out 0s;
          }

          &:hover .queue-photo-overlay {
              opacity: 1;
              background: rgba(0,0,0,0.7) url(/static/js/responsive/lib/mediaelement/icons/icons8-queue-play.svg) no-repeat center center;
          }


          .list-player-progress {
            display: block;
            position: relative;
            top: -9px;
            margin: 0 auto;
            background-color: @white-darken-02;
            height: 4px;
            width: 90%;
            border-radius: 2px;
            overflow: hidden;
            @media (max-width: @screen-xs-max) {
              max-width: 90% !important;
            }
            .list-player-progress-current {
              background-color: @green-00;
              display: block;
              height: 4px;
              border-radius: 2px;
              overflow: hidden;
            }
          }

          img {
            max-width: 250px;
            width: 100%;
            object-fit: contain;
            border-radius: 14px;
          }
        }

        .queue-description-wrapper {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          padding-left: 30px;

          .queue-scripture-reference,
          .queue-date,
          .queue-series,
          .queue-sku {
            font-size: 14px;
            line-height: 24px;
            color: @white-darken-01;
            margin: 0;
          }
          .queue-date {
            color: @white-darken-04;
          }
          .queue-title {
            font-size: 22px;
            line-height: 24px;
            letter-spacing: 0;
            color: @white-darken-01;
            padding: 0;
            margin: 0;

          }
          .queue-sku {
            .expiration {
              display: block;
            }
          }
        }

        .queue-duration-wrapper {
          flex: 0 0 auto;
          padding-right: 30px;
          width: 50px;
          text-align: right;
        }

      }

      .queue-right-gutter {
        a {
          margin-top: -10px;
          cursor: pointer;
        }
      }

      .move-queue-item-up.disabled, .move-queue-item-down.disabled {
        opacity: 50%;
        pointer-events: none;
      }

      &.expired {
        pointer-events: none;
        & > * {
          opacity: 0.3;
        }
        .expired_notification {
          display: block !important;
          opacity: 0.9;
          position: absolute;
          width: 100%;
          text-align: center;
          div, button {
            width: 60%;
            display: block;
            padding: 10px;
            border-radius: 5px;
            text-align: center;
            margin: 5px auto;
            border: none;
            pointer-events: auto;
          }
          div {
            background: @blue-darken-04;
            color: @white-00;
          }
        }
      }
    }
  }


  // Small Screen Handling
  @media (max-width: @screen-xs-max) {
    .queue-list-wrapper {
      .queue-row {
        .queue-left-gutter {
          align-self: flex-start;
          padding: 0 15px;
          width: auto;
        }
        .queue-details-wrapper {
          flex-wrap: wrap;
          justify-content: space-between;

          .queue-photo-wrapper {
            width: 70%;
            max-width: 250px;
            order: 1;
            .list-player-progress {

            }
          }
          .queue-duration-wrapper {
            width: 30%;
            order: 2;
            padding: 0;
          }
          .queue-description-wrapper {
            width: 100%;
            order: 3;
            padding: 0;
            justify-content: space-between;
            .queue-scripture-reference,
            .queue-date,
            .queue-series,
            .queue-sku {
              font-size: 12px;
              line-height: 20px;
            }
            .queue-title {
              font-size: 18px;
            }
          }
        }
      }
    }

    .queue-row.queue-header {
      .queue-left-gutter {
        width: 60px;
      }
    }
    .queue-row.queue-footer {
      .queue-left-gutter {
        align-self: flex-start;
        padding: 0 15px;
        width: 60px;
      }
      .queue-details-wrapper {
        .queue-player-title {
          .queue-title {
            text-align: center;
            font-size: 18px;
            max-height: 80px;
            line-height: 20px
          }
        }
        .queue-player-controls {
           .queue-player-controls_playpause {
              width: 40px;
            }
            .queue-player-controls_next {
              width: 40px;
              text-align: right;
              max-width: 125px;
            }
            .queue-player-controls_previous {
              width: 40px;
              text-align: left;
              max-width: 125px;
            }
        }
      }
    }
  }
}
