.truthpartner-content-box {
    width: 100%;
    text-align: center;
    background-color: @white-darken-01;
    border-radius: 16px;
    margin-bottom: 45px;
    padding: 48px;
    background-image: url("/static/images/responsive/truthpartner_thistle-light.svg");
    background-position: center center;
    background-repeat: no-repeat;

    .truthpartner-content-box__title {
        font-size: 24px;
        line-height: 24px;
        color: @grey-darken-02;
        font-family: @font-family-serif;
        letter-spacing: 0;

    }
    .truthpartner-content-box__subtitle {
        font-size: 12px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 900;
        color: @taupe-00;
        font-family: @font-family-sans-serif;
        letter-spacing: 0;
    }
    .truthpartner-content-box__header {
        font-size: 21px;
        line-height: 24px;
        color: @grey-darken-02;
        font-family: @font-family-serif;
        letter-spacing: 0;
    }
    .truthpartner-content-box__body {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: 7px;
        font-family: @font-family-sans-serif;
    }
}

.my-library-list {
  .truthpartner-content-box {
    margin-top: 20px;
    background-position: center 12px;
    .truthpartner-content-box__body {
      margin-top: 24px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  .motm-downloads {
    .motm-download {
      margin-top: 25px;
      .motm-download__btn {
        font-size: 12px;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: 0;
        text-transform: uppercase;
        padding: 0 14px;
        display: inline-block;
        border-radius: 4px;
      }
    }

  }
}

.unauthorized {
  .truthpartner-content-box {
    margin-top: 20px;
    background-position: center 12px;
    margin-bottom: 24px;
    .truthpartner-content-box__body {
      margin-top: 24px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
      padding: 0 10px;
      color: @taupe-darken-03;

      @media (min-width: @screen-sm-min) {
        padding: 0 10%
      }

      .tp-content__list {
        margin: 0;
        padding: 0;
        .tp-content__list-item {
          list-style: none;

          &::before {
            content: "→";
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }
  .smalltext {
    font-size: 14px;
    line-height: 20px;
    color: @taupe-darken-03;
    text-align: center;
  }
  .sign-in {
    text-align: center;
    margin-top: 75px;

    .sign-in__title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
      font-weight: 900;
      color: @taupe-darken-01;

    }
    .sign-in__body {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      letter-spacing: 0;
      color: @taupe-darken-01;

    }

    .btn {
      color: @taupe-darken-01;
    }
  }
}




// Message of the Month

.message-of-the-month {

    .motm__body {
        margin-top: 26px;

        .motm__header {
            .motm__date {
                font-size: 16px;
                line-height: 24px;
                color: @taupe-darken-01;
                font-weight: 600;
                font-family: @font-family-sans-serif;
                letter-spacing: 0;
            }
            .motm__title {
                font-size: 36px;
                line-height: 48px;
                letter-spacing: 0;
                font-family: @font-family-serif;
                color: @green-00;
            }
            .motm__subtitle {
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 0;
                font-family: @font-family-sans-serif;
                color: @taupe-darken-01;

                .sku-dot {
                    color: @taupe-lighten-03;
                }
            }
        }

        .motm__copy {
            margin: 24px 0;
            letter-spacing: 0;
            font-family: @font-family-sans-serif;
            color: @taupe-darken-03;
            font-size: 16px;
            line-height: 24px;
        }

        .motm__topics {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            color: @taupe-darken-01;

            .motm__topics--list_title {
                font-weight: bold;
            }
        }
    }

    .truthpartner-content-box {
        margin-top: 72px;
    }
}
