// ------------------------------------------------------------------------- Navigation - Filterbar
.article-title-wrapper .filtered-by-outer-wrapper {
  .hidden-xs;
  .text-right;
  margin-top: 26px;
  .pull-right;
}
.filtered-by {
  min-height: @line-height-computed;
  margin-bottom: @line-height-computed;
  @media (max-width: @screen-xs-max) {
    margin-bottom:10px;
  }
}

.filter-criteria-label {
  margin-left: 5px;
  background: @muted-text;
  color: @white-00;
  cursor: pointer;

  a {
    color: @white-00;
    text-decoration: none;
  }
}
.filtered-by-title {
  font-size: @font-size-small;
  font-weight: bold;
  color: @muted-text;
  .kern-wide;
}

.nav-filterbar {
  padding: 0 10px;
  background: @muted-background;

  .nav-filterbar-label {

  }
  .nav-title {
    .hidden-md;
    .hidden-lg;
    line-height: 46px;
    display: block;
    cursor: pointer;
    text-decoration: none;

    .caret {
      float: right;
      margin-top: 20px;
      // upward pointing caret
      border-top: 0;
      border-bottom: 4px solid;

      @media (max-width: @screen-xs-max) {
        border-width: 8px;
        border-top: 0;
      }
    }
    &.collapsed .caret {
      // downward pointing caret
      border-bottom: 0;
      border-top: 4px solid;

      @media (max-width: @screen-xs-max) {
        border-width: 8px;
        border-bottom: 0;
      }
    }

  }
  select {
    .visible-xs;
  }
  li.nav-title {
    .hidden-xs;
    .hidden-sm;
  }
  .nav-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    .kern-wide;
    color: @muted-text;
  }
  .nav > li.active span {
    background: @white-darken-03;
    color: darken(@muted-text, 20%);
  }
  .nav > li > span {
    display: block;
    text-align: center;
    padding: 15px 0;
    border-radius: 0;
    color: @muted-text;
    cursor: pointer;
    .transition(~"all 0.1s ease-in-out");

    &:hover {
      background: @white-darken-02;
    }
  }
}
#filter-container {
  background: @white-darken-03;
  position: relative;
  // If collapsed, still show on md and lg.
  margin-top: @grid-gutter-width;
  .filtered-by {
    position: absolute;
    top: -@grid-gutter-width;
  }
  &.collapse {
    @media (min-width: @screen-md-min) {
      display: block !important;
    }
  }
  .nav-filterbar-list {
    text-transform: uppercase;
    .kern-wide;
    margin: 0 10px;
    .hidden-xs;
    > li {
      font-size: @font-size-small;
      font-weight: 500;
    }
  }
}
.filterbar-options-list {
  > li {
    font-size: 13px;
  }
}
.filterbar-options.first {
  margin: 0 -10px;
  select {
    margin-bottom: 0;
    margin-top: 5px;
  }
}
.filterbar-options {
  font-size: @font-size-small;
  padding: 5px 10px;
  background: @white-darken-03;

  @media (min-width: @screen-sm-min) {
    padding: 0 10px;
  }

  select {
    margin-bottom: 10px;
  }

  .nav {
    .hidden-xs;
    .hidden-sm;
  }

  select {
    .hidden-md;
    .hidden-lg;
  }

  .nav {
    padding: 5px;
    margin: (@line-height-computed * .5) 0;
    background: @muted-background;
    border-radius: 3px;

    > li {
      white-space: nowrap;
      span {
        cursor: pointer;
      }
    }
  }
  .nav > li.active span {
    background: @white-darken-02;
    color: darken(@muted-text, 20%);
  }
  .nav > li > span {
    padding: 5px 0;
    display: block;
    text-align: center;
    border-radius: 3px;
    color: darken(@muted-text, 20%);
    .transition(~"all 0.1s ease-in-out");

    &:hover {
      background: @white-darken-02;
    }
  }
}
.criteria-support {
  select {
    .visible-xs;
  }
  ul.criteria {
    margin-top:0;
  }
}
.criteria-wrapper-columns {
  .column-count(4);
  .column-gap(30);
  .hidden-xs;
  a {
    color: darken(@muted-text, 20%);
    display: block;
    padding: 3px 7px;
    border-radius: 3px;
    font-size: @font-size-small*1.1;
    .transition(~"all 0.1s ease-in-out");
    &:hover {
      background: @white-darken-02;
      text-decoration: none;
    }
  }
  li.active a {
    background: @white-darken-05;
  }
}

.filterbar-collaps {
  font-size: @font-size-small;
  background: @muted-contrast-background;
  color: @muted-contrast-text;
  .text-right;
  padding: 2px 5px;
  cursor: pointer;

  &:hover {
    background: lighten(@muted-contrast-background, 3%);
  }
}