// ------------------------------------------------------------------------- Form UI
/* The switch - the box around the slider */

.ui-toggle-switch-wrapper {
  height: 44px;
  width: 70px;
  border-radius: 16px;
  background-color: @white-darken-01;
  padding: 10px;


  .ui-toggle-switch {
      position: relative;
      display: inline-block;
      width: 48px;
      height: 24px;
      box-sizing: border-box;
      border: 1px solid @white-darken-01;
      border-radius: 22px;
      background-color: @white-darken-01;

      /* Hide default HTML checkbox */
      input {
          opacity: 0;
          width: 0;
          height: 0;
      }

      /* The slider */
      .ui-slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: @white-darken-03;
          -webkit-transition: .2s;
          transition: .2s ease-out;

          &:before {
              position: absolute;
              box-sizing: border-box;
              height: 22px;
              width: 22px;
              border: 1px solid @white-darken-04;
              background-color: @white-darken-01;
              box-shadow: inset 0 1px 0 1px #FFFFFF, 0 2px 4px 0 rgba(0,0,0,0.1);
              -webkit-transition: .2s;
              transition: .2s;
              content: "";
          }
          &:after {
            content: url("/static/images/form-ui/ui-toggle-off.svg");
            right: 5px;
            top: 2px;
            position: absolute;
            opacity: 0.9;
          }
      }

      input:checked + .ui-slider {
          background-color: @blue-00;
      }

      input:checked + .ui-slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
      }

      /* Rounded sliders */
      .ui-slider.round {
          border-radius: 22px;
      }

      .ui-slider.round:before {
          border-radius: 50%;
      }

      &.active {
        .ui-slider {
          &:after {
              content: url("/static/images/form-ui/ui-toggle-on.svg");
              opacity: 0.9;
              left: 5px;
              top: 2px;
              position: absolute;
          }
        }
      }
  }
}

.ui-change-box {
    display: flex;
    border: 1px solid @taupe-lighten-04;
    flex-direction: row;
    margin-top: 8px;
    border-radius: 4px;
    padding: 20px;

    & > div {
      flex: 1 1 auto;
      font-size: 16px;
      line-height: 24px
    }
    .ui-change-link {
      flex: 0 0 auto;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
      align-self: flex-start;
      cursor: pointer;
    }
}



.ui-form-box {
    display: flex;
    border: 1px solid @taupe-lighten-04;
    flex-direction: column;
    margin-top: 20px;
    &--rounded {
      border-radius: 4px;
    }

    .ui-form-box__header {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid @taupe-lighten-04;
        width: 100%;
        padding: 10px;
        @media (max-width: @screen-tiny-max) {
            padding: 5px;
        }
        @media (min-width: @screen-md-min) {
          padding: 24px 5px 24px 20px;
        }

        .ui-form-box__title {
            color: @taupe-darken-03;
            font-family: @font-family-sans-serif;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
        }

        .ui-form-box__subtitle {
            color: @taupe-darken-02;
            font-family: @font-family-sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
        }
    }

    .ui-form-box__body {
      padding: 10px;
      @media (max-width: @screen-tiny-max) {
        padding: 5px;

        p {
          padding-left: 18px;
          padding-top: 10px;
        }
      }
      @media (min-width: @screen-md-min) {
        padding: 24px
      }
      .ui-form-box__bodyheader {
        padding: 0;
        color: @blue-00;
        font-size: 14px;
        font-weight: 900;
        line-height: 24px;
        text-transform: uppercase;
      }
    }

    .ui-form-bumpers {
      width: 100%;
      display: flex;
      flex-direction: row;
      @media (max-width: @screen-xs-max) {
        flex-direction: column;
      }
      justify-content: space-between;
      align-items: center;

      .ui-form-bumpers__start {
        flex: 1 1 auto;
        align-self: center;
      }
      .ui-form-bumpers__end {
        flex: 1 1 auto;
        text-align: right;
        align-self: flex-start;
        @media (min-width: @screen-md-min) {
          padding-right: 15px;
        }
        .ui-form-box__btn {
          box-sizing: border-box;
          padding: 4px 21px;
          border: 1px solid @taupe-lighten-04;
          border-radius: 4px;
          background: @white-darken-01;
          color: @taupe-darken-03;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          min-width: 200px;

          &:hover {
            opacity: 0.8;
          }
        }
        @media (max-width: @screen-xs-max) {
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }
      @media (max-width: @screen-xs-max) {
        .ui-form-bumpers__start {
          align-self: center;
          width: 100%;
        }

      }
    }

    .ui-form-box__collapse {
        border-bottom: 1px solid @taupe-lighten-04;
    }

    .ui-form-box__form-group {
        display: flex;
        font-family: @font-family-sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        padding: 16px 16px 12px 20px;
        justify-content: space-between;
        border-bottom: 1px solid @taupe-lighten-04;

        &.last {
          border-color: transparent;
        }
        .ui-form-box__label_wrapper {
          display: flex;
          flex-direction: column;

          span {
            flex: 1 1 auto;
          }
          .ui-form-box__subtitle {
            color: @taupe-darken-02;
            font-family: @font-family-sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
          }
        }
      }

    .ui-form-box__label {
        margin-top: 10px;
    }
}

.ui-form {
  .ui-label {
    font-size: 16px;
    line-height: 26px;
    font-family: @font-family-sans-serif;
    color: @taupe-darken-03;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  input {
    border-color: @white-darken-05;
  }
  .btn {
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 800;
    padding: 12px 40px;
    font-family: @font-family-sans-serif;
    border: 1px solid transparent;
    @media (max-width: @screen-tiny-max) {
      width: 100%;
      padding: 12px;
    }
  }
  iframe, embed {
    max-width: 100%;
  }
}
