// ------------------------------------------------------------------------- Footer

@footer-background:                 @blue-00;
@footer-link-color:                 @blue-lighten-08;
@footer-text-color:                 @blue-lighten-08;
@footer-title-color:                @white-00;
@footer-input-background:           @blue-darken-01;
@footer-input-placeholder:          @blue-lighten-08;
@footer-input-color:                @white-00;
@footer-border-color:               @blue-lighten-01;
@footer-label-color:								@white-00;

/*** hubspot form ***/
footer form.hs-form input[type='text'], footer form.hs-form input[type='email']
{
    width:100% !important;
    padding:6px 12px 6px 12px;
    border-color: @footer-input-background;
    border:none;
    border-radius:4px;

    background: @footer-input-background;
    box-shadow: none;

    color: @white-00;
}
footer form.hs-form input::placeholder
{
    color: @blue-lighten-08;
}
footer form.hs-form input[type='submit']
{
    display:block;
    width:100%;
    max-width:500px;
    margin-bottom:10px;
    padding:10px;
    border-radius:4px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background-color: @green-00;


    color: @white-00;
    text-shadow: rgba(17, 19, 8, 0.2) 0px -1px 0px;
    text-transform: uppercase;
    text-align:center;
    letter-spacing: 1px;
}
footer form.hs-form input[type='submit']:hover
{
   background-color: @green-lighten-01;
}
footer form.hs-form input[type='checkbox']
{
    position:relative;
    left:-8px;
    top:2px;
}

footer form.hs-form label span
{
    color:@blue-lighten-08;
    font-family: @font-family-sans-serif;
    font-size:0.95em;
    font-weight:100;
}
footer form.hs-form label strong
{
    color:@white-00;
}
footer form.hs-form ul.inputs-list
{
    list-style-type:none;
}


footer form.hs-form span.hs-form-required
{
    display:none;
}
footer form.hs-form ul.hs-error-msgs
{
    padding:5px;
    color:@red-00;
    font-weight:bolder;
    font-size:16px;
}
footer form.hs-form fieldset
{
    margin-bottom:5px;
}
footer form.hs-form .field.hs_firstname .input
{
    margin-right:12px;
}

footer .submitted-message
{
    color:@white-00;
    font-family: @font-family-sans-serif;
}
/********************/


.base-utility-outer-wrapper {
  border-top: 1px solid @border-color;
  background-color: @white-00;
  font-size: @font-size-small;
  a {
    color: @muted-text;

    &:focus,
    &:active {
      color: @white-00;
    }
  }
}
.base-utility-support {
  .make-row();
}
.back-to-top-wrapper {
  .make-xs-column(2);
  @media (max-width: @screen-xs-max) {
    min-height: 37px;
  }
  border-right: 1px solid @border-color;
  padding-top: (@line-height-computed * .5);
  padding-bottom: (@line-height-computed * .5);

  i {
    font-size: @font-size-large;
    text-align: center;
  }
}
.link-trail-wrapper {
  .make-xs-column(10);
  padding-top: (@line-height-computed * .5);
  .link-trail {
    margin-bottom: 0;
  }
}

.footer-nav-subscribe-outer-wrapper {
  border-bottom: 1px solid @footer-border-color;
  .make-row;
}
.footer-nav-subscribe-support {
  .make-row();
}
.footer-nav-subscribe-control-support {
  // display: table;

}
.footer-nav-wrapper {
  .make-sm-column(8);
  float: none !important;
  display: table-cell;
  vertical-align: top;

  @media (max-width: @screen-xs-max) {
    float: left !important;
    display: block;
    width: 100%;
  }
}
.connect-with-us-wrapper {
  .make-xs-column(12);
  .make-sm-column(6);
  .make-md-column(3);
}
.welcome-wrapper {
  .make-xs-column(12);
  .make-sm-column(6);
  .make-md-column(3);

  @media (max-width: @screen-xs-max) {
    border-bottom: 1px solid @footer-border-color;
    border-top: 1px solid @footer-border-color;
  }
}
.our-resources-wrapper {
  .make-xs-column(12);
  .make-sm-column(6);
  .make-md-column(3);
  padding: 0 12px;

  @media (max-width: @screen-xs-max) {
    border-bottom: 1px solid @footer-border-color;
  }
}
.donate-wrapper {
  .make-xs-column(12);
  .make-sm-column(6);
  .make-md-column(3);

  @media (max-width: @screen-xs-max) {
    border-bottom: 1px solid @footer-border-color;
  }
}
.subscription-form-wrapper {
  .make-sm-column(4);
  border-left: 1px solid @footer-border-color;
  float: none !important;
  display: table-cell;
  vertical-align: top;

  @media (max-width: @screen-xs-max) {
    border-left: none;
    float: left !important;
    display: block;
  }
  h4.subscription-title {
    color: @white-00;
    padding: 100px 50px 0 50px;
    text-align: center;
  }
  .subscription-message {
    padding: 0 50px 50px 50px;
    color: @white-00;
    text-align: center;
  }


}
.legal-social-outer-wrapper {
  border-top: 1px solid @footer-border-color;
  margin-top: 3px;
  padding: @line-height-computed 0;
}
.legal-social-support {
  .make-row();
}
.legal-wrapper {
  .make-sm-column(8);
}
.social-wrapper {
  .make-sm-column(4);
}
.copyright-support {
  .make-row();
}
.copyright-wrapper {
 .make-sm-column(8);
}
.footer-logo-wrapper {
  .make-sm-column(4);

  .footer-logo {
    display: block;
    background-image: url(/static/images/responsive/truth-for-life-learning-for-living-right-wt.svg);
    background-repeat: no-repeat;
    background-position: right 0;
    text-indent: -10000px;
    height: 32px;
    margin-top: 7px;
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      margin-top: 11px;
    }
  }
}
footer {
  background: @footer-background;
  color: @footer-text-color;
  font-size: .95em;
  @media (max-width: @screen-sm-max) {
    padding-bottom: 50px;
  }
  address, p, li {
    line-height: 1.7;
  }
  .pipe {
    margin: 0 5px;
  }
  .footer-title {
    color: @footer-title-color;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    .caret {
      // upward pointing caret
      .pull-right;
      margin-top: 3px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid @footer-border-color;
      border-top: none;
    }
    &.collapsed .caret {
      // downward pointing caret
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid @footer-border-color;
      border-bottom: none;
    }

    @media (min-width: @screen-sm-min) {
      margin-top: (@line-height-computed * 2);
      cursor: auto;

      .caret {
        display: none;
      }
    }

  }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .footer-title {
      min-height: 30px;
    }
  }
  .footer-logo-wrapper {
    margin-top: -20px;
    margin-bottom: 20px;

    @media (max-width: @screen-xs-max) {
      margin-top: @line-height-computed;
    }
  }
  .footer-logo {

  }
  @media (min-width: @screen-sm-min) {
    ul.collapse {
      display: block;
      height: auto !important;
    }
  }

  a {
    color: @footer-link-color;

    &:hover, &:active, &:focus {
      color: @footer-title-color;
    }
  }

  .h-card {
    @media (max-width: @screen-lg-min) {
      .p-tel {
        .clearfix;
      }
    }
  }

  .first-name-wrapper {
    .make-sm-column(6);
    @media (min-width: @screen-sm-min) {
      padding-right: 7px;
    }
  }
  .last-name-wrapper {
    .make-sm-column(6);
    @media (min-width: @screen-sm-min) {
      padding-left: 7px;
      padding-right: 14px;
    }
  }
  input[type="text"] {
    color: @footer-input-color;
  }
  .newsletter-type {
    color: @footer-title-color;
    font-weight: 600;
    // text-transform: uppercase;
    // .kern-wide;
  }

  .subscription-form {
    padding-bottom: 31.5px;
    .form-control {
      background: @footer-input-background;
      border-color: @footer-input-background;
      .placeholder(@footer-input-placeholder);
      box-shadow: none;
    }

    .form-control {
      @media (max-width: @screen-xs-max) {
        margin-top: (@line-height-computed * 0.5);
      }
    }
    label {
      color: @footer-label-color;
    }
  }
  #emailAddress {
    margin-top: (@line-height-computed * 0.5);
  }
}

.btn-subscribe, .btn-primary-call, .btn-login {
  background-color: @green-00;
  text-shadow: 0 -1px 0 fade(darken(@support-color, 40%), 20%);
  text-transform: uppercase;
  // box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  .kern-wide;
  color: @white-00;
  border: none;
  .transition(none);
  background-position: 0;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    background-color: @green-lighten-01;
    color: @white-00;
    opacity: 1;
  }
}

.social-media-icons {
  text-align:right;
  padding-bottom: @line-height-computed;

  @media (max-width: @screen-xs) {
    margin-left: 0;
    margin-top: 2em;
  }

  &.list-inline {
    margin-left: 0;
  }
  li {
    padding: 0;
    // margin-right: 4px;
    margin-bottom: 4px;
  }
  a {
    color: @white-00;
    background-color: @white-00;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    .transition(opacity .4s);
    &:hover {
      background-color: @white-darken-02;
    }
    [class^="ti-"], [class*=" ti-"] {
      .svg-position-fix;
    }
    .footer_icon {
      width: 20px;
      height: 20px;
      margin: 10px;

    }
  }
}
