.sermon_transcript--icon {
  display: inline-block;
  color: @blue-00;
  background-color: @white-00;
  border: 2px solid @blue-00;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  text-align: center;
  vertical-align: middle;
  padding-top: 3px;
}

a.Super.Upper
{
  position: relative;
  top: -0.5em;
  padding-top: 100px;
  font-size: 0.85em;
}
a.Super.Lower
{
  position: relative;
  padding-top: 100px;
}
a.Super:focus
{
  outline:none;
  border:none;
}

.post-wrapper {
  overflow: hidden;
  font-family: @font-family-sans-serif;
  font-size: 18px;
  line-height: 1.58em;
  position: relative;
  padding-top: 1em;
  @media (min-width: @screen-sm-min) {
    font-size: 22px;
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
    .col-lg-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-lg-8 {
      width: 66.66666667%;
    }
  }
  #post-header-follow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    position: fixed;
    width: 100%;
    background: @white-00;
    transition: all 0.3s ease;
  }
  .sticky-header {
    &.show-me {
      top: 0;
    }
    &.hide-me {
      top: -100px;
    }
  }
  .post-header-title {
    flex-grow: 1;

    .title {
      font-size: 0.75em;
      font-weight: 700;
      line-height: 1;
    }
  }
  .post-header-share-options {
    display: flex;
    flex-direction: row;

    a {
      display:inline-block;
      margin: -3px 1em 0 0;
    }

    .share-icon {
      width: 20px;
      height: 20px;


      &:last-child {
        margin-right: 0;
      }
    }
  }
  .dropdown-menu {
    padding: 10px 0;
    border: none;
    border-radius: 1em;
  }
  .dropdown-menu-favorite {
      left: auto !important;
      right: 0 !important;
      @media (max-width: @screen-xs-max) {
          left: -20px !important;
          right: auto !important;
      }
  }
  .muted {
    opacity: 0.55;
  }
  .video {
    background-color: @blue-lighten-06;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative; /* If you want text inside of it */
  }
  .post-header {
    text-align: center;
  }
  .post-date {
    font-size: 0.7em;
    text-transform: uppercase;
    margin: 1em 0 0.5em 0 !important;
    color: @muted-text;
  }
  .post-series {
    font-weight: 700;
    color: @muted-text;
    font-size: 1.25em;
    line-height: 1.58em;
    margin: 0 !important;
  }
  .post-author {
    font-size: 0.7em;
    margin-bottom: 1em;
  }

  .h1 {
    font-family: @font-family-slab;
    font-weight: 300;
    font-size: 2.5em;
    line-height: 1;
    color: @primary-color;
    margin: 0;
  }
  h2,
  .h2 {
    font-family: @font-family-slab;
    font-weight: 300;
    font-size: 2em;
    color: @primary-color;
  }
  h3 {
    font-family: @font-family-slab;
    font-weight: 200;
    font-size: 1.5em;
    color: @primary-color;
  }
  h4 {
    font-family: @font-family-slab;
    font-weight: 200;
    font-size: 1.1em;
    color: @primary-color;
  }
  .scripture-reference-lead {
    font-size: 0.75em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    .icon-reference {
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 1;
    }
    .references {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }

  p {
    margin-bottom: 2em;
  }
  .pre-formated {
    white-space: pre-line;
    line-height: 1.58em;
  }
  .callout {
    display: inline-block;
    font-family: @font-family-slab;
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.58em;
    text-align: center;
    color: #822b00;
    border-radius: 3px;
    padding: 0.75em 1em;

    @media (min-width: @screen-sm-min) {
      font-size: 1em;
    }
    @media (min-width: @screen-md-min) {
      font-size: 1.25em;
    }

    &.has-quote {
      position: relative;
      z-index: 10;
      &::before {
        content: "\201C";
        font-family: @font-family-sans-serif;
        display: inline-block;
        position: absolute;
        top: 56px;
        left: -6px;
        font-size: 7em;
        color: rgba(186, 189, 186, 0.3);
        z-index: -1;
        @media (min-width: @screen-sm-min) {
          left: -26px;
        }
      }
      &::after {
        content: "\201D";
        font-family: @font-family-sans-serif;
        display: inline-block;
        position: absolute;
        top: 56px;
        right: -6px;
        font-size: 7em;
        color: rgba(186, 189, 186, 0.3);
        z-index: -1;
        @media (min-width: @screen-sm-min) {
          right: -26px;
        }
      }
    }
    &.tweet-this {
      font-family: @font-family-sans-serif;
      font-weight: 700;
      position: relative;
      padding-bottom: 2em;
      .btn-tweet-this {
        display: block;
        color: @primary-color;
        width: 120px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
        font-size: 0.5em;
        padding: 0 1em 1em 1em;
        border-radius: 7px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: 2em auto;
        line-height: 1;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
          background: rgba(222, 245, 252, 0.2);
        }
      }
      .icon-tweet-this {
        display: block;
        width: 26px;
        margin: -12px auto 0 auto;
        line-height: 0;
        position: relative;
        z-index: 10;
      }
    }
  }

  .modal {
    background: rgba(255,255,255,0.8);
  }
  .modal-content {
    border-radius: 0;
    box-shadow: 0 5px 20px rgba(0,0,0,.3);
    border: none;

    .modal-title {
      font-family: @font-family-slab;      font-size: 24px;
      text-align:center;
      color: @primary-color;
    }

    .modal-header
    {
      border-bottom:none;
    }
    .tab-content .row
    {
      padding:2rem;
    }

    .close {
      width: 16px;
      height: 16px;
    }
    .btn-volumes {
      margin-left: 10px;
    }
    .icon-post-aquire {
      display: inline-block;
      margin: 0 0 0 20px;
      height: 24px;
    }
    .topics {
      padding: 2em 0;
    }
    .topics::after {
      display: block;
      content: " ";
      clear: both;
    }
  }

  .post-topics {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline;
    li {
      display: inline;

      &::after {
        content: ",";
      }
      &:last-child::after {
        content: "";
      }
    }
  }
  .post-id {
  }
  .share-options {
    margin: 2em 0;
    text-align: center;

    .share-icon {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      margin: 0 1em;

      & path {
        fill: @muted-text;
      }
    }
  }

  .scripture {
    padding: 0 2em;

    .crossreference {
      display: none;
    }
  }
  .scripture-reference {
    font-weight: 800;
    margin: 0;
  }
  .verse-number {
    font-size: 0.65em;
  }
  .scripture-notice {
    background: @muted-background;
    font-size: 0.75em;
    line-height: 1.25em;
    margin: 2em 0;
    p
    {
      padding:0 1em 1em 1em;
      margin:0;
    }
    p:first-child
    {
      padding-top:1em;
    }
  }
  .post-footer {
    padding-bottom: 3em;
  }

  .author-profile {
    display: flex;
    flex-direction: row;
    background: @blue-lighten-08;
    font-size: 0.75em;
    line-height: 1.25em;
    margin: 2em 1em;
    padding: 0.95em 2em 1em 1.4em;
    align-items: center;
    border-radius: 100px;

    @media (min-width: @screen-sm-min) {
      margin: 2em 2.5em;
    }

    .author-profile-text {
      margin: 0;
      line-height: 1.25em;
      flex-grow: 1;
    }
    .author-photo-wrapper {
      width: 54px;
      height: 54px;
      margin: 0 1em 0 0;
    }
    .author-photo {
      width: 54px;
      height: 54px;
      border-radius: 30px;
      overflow: hidden;
      min-width: 54px;
      min-height: 54px;
    }
  }

  #jump-top {
    position: fixed;
    bottom: 1.5em;
    left: 1em;
    display: box;
    width: 40px;
    height: 40px;
    background: @white-00;
    border-radius: 30px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;

    &.show-me {
      bottom: 1.5em;
    }

    &.hide-me {
      bottom: -100px;
    }

    @media (min-width: @screen-sm-min) {
      width: 60px;
      height: 60px;
    }

    &:hover {
      box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
      bottom: 2em;
    }
  }
  .icon-jump-top {
    width: 20px;
    height: 20px;
    margin: 10px;
    @media (min-width: @screen-sm-min) {
      margin: 15px 0 25px 20px;
    }
  }

  .footer-promos {
    margin-bottom: 3em;

    .banner-wrapper img {
      margin: 0 auto;
    }
  }

  // FIXME: Temporary workaround for TinyMCE's H6 as Pull Quote  Functionality
  h6 {
    border-radius: 3px;
    color: #822b00;
    display: inline-block;
    font-family: @font-family-slab;    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.58em;
    margin-bottom: 2em;
    padding: 0.75em 1em;
    position: relative;
    text-align: center;
    z-index: 10;

    @media (min-width: @screen-sm-min) {
      font-size: 1em;
    }
    @media (min-width: @screen-md-min) {
      font-size: 1.25em;
    }

    &::before {
      content: "\201C";
      font-family: @font-family-sans-serif;
      display: inline-block;
      position: absolute;
      top: 56px;
      left: -6px;
      font-size: 7em;
      color: rgba(186, 189, 186, 0.3);
      z-index: -1;
      @media (min-width: @screen-sm-min) {
        left: -26px;
      }
    }
    &::after {
      content: "\201D";
      font-family: @font-family-sans-serif;
      display: inline-block;
      position: absolute;
      top: 56px;
      right: -6px;
      font-size: 7em;
      color: rgba(186, 189, 186, 0.3);
      z-index: -1;
      @media (min-width: @screen-sm-min) {
        right: -26px;
      }
    }
  }
  // End FIXME
}

.mceContentBody {
  padding: 20px;
  background-color: @white-00;
}
