@import "includes/global-imports.less";
@import "includes/components.less";
@import "includes/media-player";
@import "includes/account.less";
@import "includes/site-login.less";
@import "includes/site-checkout.less";
@import "includes/structure.less";
@import "includes/day-browser.less";
@import "includes/single-page.less";
@import "includes/notifications.less";
@import "includes/home.less";
@import "includes/daily.less";
@import "includes/devotional.less";
@import "includes/mobile-nav.less";
@import "includes/site-notice.less";
@import "includes/giving-form.less";
@import "includes/social-nav.less";
@import "includes/archives.less";
@import "includes/store.less";
@import "includes/sidebar-nav.less";
@import "includes/filterbar-nav.less";
@import "includes/footer.less";
@import "includes/bible.less";
@import "includes/custom-pages.less";
@import "includes/stationfinder.less";
@import "includes/janrain.less";
@import "includes/qas-overrides.less";
@import "includes/addresses.less";
@import "includes/nprogress.less";
@import "includes/error-messaging.less";
@import "includes/sermon-transcript.less";
@import "includes/subscribe-daily.less";
@import "includes/colorize.less";
@import "includes/mobile-custom-tab.less";
@import "includes/listening-queue.less";
@import "includes/slider.less";
@import "includes/forms-ui.less";
@import "includes/library-binding.less";
@import "includes/truthpartner.less";
@import "includes/search.less";

@media (max-width: @screen-xs-max) {
  body.mobile-page {
    background: @white-00 !important;
    .main-content-support:before {
      border-top: none;
    }
    .mobile-donation-premiums {
      text-align: center;
      padding-top: 20px;
      p.lead {
        font-size: 14px;
        .font-base;
        font-weight: 400;
        margin-bottom: 5px;
        color: @gray-00;
      }
      img {
        max-height: 270px;
      }
    }
  }
}
