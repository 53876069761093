@prev-next-btn-height: 48px;
@slide-tile-padding: 16px;
.slide-lateral {
  position: relative;
}
.slide-lateral_slider {
  position: relative;
  white-space: nowrap;
  text-align: left;
  margin: 0 24px 0 10px;
  padding: 0;
  border: 0;

  overflow-x: scroll;
  overflow-y: visible;
  scrollbar-width: none;
  -ms-overflow-style: none;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  @media (max-width: @screen-xs-max) {
    margin: 0;
  }
}
.slide-lateral_slider::-webkit-scrollbar {
  display: none;
}
.slide-lateral_tile {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: @slide-tile-padding;
  border: 0;

  scroll-snap-align: start;

  white-space: normal;
  vertical-align: top;

  a {
    display: block;

    &.expired {
      .slide-lateral_thumb{
        opacity: 0.2;
      }
    }
  }
}

.slide-lateral_copy_container {
  display: flex;
  min-width: 150px;
  text-align:left;
  .slide-lateral_copy {
    flex-grow: 1;
    width: 0;
  }
}

.slide-lateral_title {
  display: block;
  color: @black;
  text-decoration: none;
}
.slide-lateral_title--major {
  margin-bottom: 0.3em;
  font-family: @font-family-serif;
  font-size: 1em;
  line-height: 1.3em;
}
.slide-lateral_title--minor {
  font-family: @font-family-sans-serif;
  font-size: 0.9em;
  line-height: 1.3em;
}
.slide-lateral_title--secondary {
  font-family: @font-family-sans-serif;
  color: @taupe-darken-01 !important;
  font-size: 0.9em;
  line-height: 1.3em;
}
.slide-lateral_title--alert {
  font-family: @font-family-sans-serif;
  background-color: @taupe-darken-01;
  font-size: 12px;
  line-height: 18px;
  padding: 0 12px;
  border-radius: 12px;
  color: @white-00;
  width: 150px;
  text-align: center;
  display: block;
  margin-top: 5px;
}
.slide-lateral_step {
  display: inline-block;
  width: 48px;
  height: 100%;

  position: absolute;
  top: 0;

  background-repeat: no-repeat;
  cursor: pointer;

  @media (max-width: @screen-xs-max) {
    display: none;
  }
}
.slide-lateral_step--prev {
  left: 0px;
}
.slide-lateral_step--next {
  right: 0px;
}
.slide-lateral_step:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background: @white-00;
}
.slide-lateral_step--next:before {
  right: 0;
}
.slide-lateral_step:after {
  display: inline-block;
  width: 48px;
  height: @prev-next-btn-height;

  position: relative;

  background: @white-00;
  border-radius: 50%;

  color: @black;
  font-family: "tfl-global";
  font-size: 28px;
  line-height: 46px;
  text-align: center;

  transition: all 0.2s;
}
.slide-lateral_step:hover:after {
  transform: scale(1.1);
}
.slide-lateral_step--prev:after {
  content: "\f104";
}
.slide-lateral_step--next:after {
  content: "\f105";
}
.limit--min .slide-lateral_step--prev,
.limit--max .slide-lateral_step--next {
  opacity: 0.3;
  cursor: initial;

  &:before {
    display: none;
  }
}
.limit--min.limit--max .slide-lateral_step {
  display: none;
}
.slide-lateral_thumb {
  display: block;
  width: auto;
  margin-bottom: 1em;
  border-radius: 1em;

  transform: scale(1);
  transition: transform 0.3s, box-shadow 0.4s;
}
*:hover > .slide-lateral_thumb {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px @warm-gray-lighten-04;
}
.slide-positioning(@thumb-height; @tile-padding: @slide-tile-padding; @btn-height: @prev-next-btn-height) {
  .slide-lateral_step:after {
    top: @thumb-height / 2 + @tile-padding - @btn-height / 2;
  }
  .slide-lateral_thumb {
    height: @thumb-height;
  }
}
.slider-group_lower {
  text-align: center;
  .slide-positioning(170px);
  @media (max-width: @screen-md-max) {
    .slide-positioning(120px);
  }
  &--lg-height {
    .slide-positioning(300px);
    @media (max-width: @screen-md-max) {
      .slide-positioning(200px);
    }
  }
}


/* Topic Specific Additions */
.topicgroup-topics {
  padding-bottom: 0;
  .slide-lateral_tile {
    padding: 16px 16px 0 16px;
    font-family: @font-family-sans-serif;
    font-weight: 700;

    a {
      position: relative;
      text-decoration: none;

      &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          background-color:pink;
          background: linear-gradient(to bottom, transparent 60%, black 100%);
          margin-bottom: 3em;
          border-radius: 1em;
          z-index: 2;
          overflow: hidden;
          transform: scale(1);
          transition: transform 0.3s, box-shadow 0.4s;
          @media (max-width: @screen-md-max) { 
            bottom: 8px;
            margin-bottom: 2em;
          }
      }
      &:hover {
        &::after {
          transform: scale(1.05);
          transition: transform 0.3s, box-shadow 0.4s;
        }
      }
    }

    .slide-lateral_thumb {
      height: 300px;
    }
    .slide-lateral_title {
      font-size: 24px;
      line-height: 32px;
      font-family: @font-family-sans-serif;
      position: relative;
      top: -70px;
      left: 30px;
      color: @white-00;
      text-decoration: none;
      z-index: 3;
      &:hover {
        color: @white-00;
      }
    }
    @media (max-width: @screen-md-max) {
      .slide-lateral_thumb {
        height: 200px;
      }
      .slide-lateral_title {
        font-size: 18px;
        line-height: 24px;
        top: -50px;
        left: 15px;
      }
    }
    @media (max-width: @screen-tiny-max) {
      .slide-lateral_thumb {
        height: 220px;
      }
    }
  }
}

.my-library_slider-group {
  border-bottom: 2px solid @taupe-lighten-04;
  margin: 0 -15px 0 -15px;
  padding: 10px 0 50px 0;

  @media(max-width:@screen-xs-max) {
    padding: 25px 0 30px 0;
  }

  .slider-group_upper {
    display: block;
    padding: 0 10px 0 10px;
  }
  .slider-group_lower {
    position: relative;
  }

  .my-library_slider-group_title {
    display: table-cell;
    padding: 5px 15px 10px 15px;
    width: 100%;

    h3 {
      margin: 0;
      color: @grey-darken-01;
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;
      font-family: @font-family-sans-serif;

    }
    p {
      margin: 0;
      color: @grey-lighten-01;
      font-family: @font-family-sans-serif;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
    }

  }

  .slider-group_details {
    display: table-cell;
    padding: 15px 15px 0 15px;
    white-space: nowrap;

    p {
      white-space: normal;
      width: 205px;
      margin-bottom: 16px;
      color: @taupe-darken-02;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }

    p.actionable {
      color: @taupe-darken-01;
      text-align: center;
      font-weight: 900;
    }

    .my-library_slider-group_cta {
      display: inline-block;
      padding: 3px 10px 3px 10px;
      border-radius: 5px;
      background: @white-darken-01;
      color: @grey-darken-01;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 12px;
    }
  }
}

