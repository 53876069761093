/* login body styles ******************************/
.login--body {
    margin:0;
    background:@white-darken-01;
    text-align:center;
    font-family:@font-family-sans-serif;

    #site-canvas{ background:@white-darken-01; }
}

/* login block, login elements, login element modifiers *****************************************/
.login
{
    position:relative;
    display:block;
    box-sizing:border-box;
    max-width:555px;
    margin:0 auto 0 auto;

    font-family:@font-family-sans-serif;
    text-align:center;

    p.alert {
      @media(max-width: @screen-xs-min) {
        padding-bottom: 1.5em;
      }
    }

    a
    {
        color: @brand-primary;
        text-decoration: underline;
    }

    .login__item
    {
        display:block;
        position:relative;
        max-width:360px;

        margin:0 auto 14px auto;
        padding:0;
        border:none;
        box-sizing: border-box;


        .social_login__grid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .login__cta--social {
                flex: 0 0 auto;

                &:hover {
                    background-color: @white-darken-02;
                }
            }
        }
    }
    .login__item--wide{ max-width:100%; }
    .login__item--flat{ margin-bottom:0; }

    /* CTAs**************************************/
    .login__cta
    {
        position:relative;
        display:block;
        width:100%;

        box-sizing:border-box;
        padding:10px;
        border:none;
        border-radius:4px;

        overflow: hidden;
        background:@taupe-lighten-03;
        cursor:pointer;
        transition:box-shadow 0.4s;

        color:@black;
        font-family:@font-family-sans-serif;
        font-weight:bolder;
        font-size:14px;
        text-align:center;
        text-decoration:none;
        line-height:24px;

        &:hover
        {
            //box-shadow:0px 0px 10px @taupe-lighten-01;
        }
        &.login__cta--green
        {
            .tfl-btn-submit;
        }
        &.login__cta--blue
        {
            .tfl-btn-primary;
        }
        &.login__cta--gray
        {
            border:1px solid @taupe-lighten-04;
            background: @white-darken-01;
            color:@taupe-darken-03;
        }
        &.login__cta--social
        {
            display: inline-block;
            background-color: @white-00;
            color: @black-00;
            font-weight: 400;
            width: 176px;
            border: 1px solid @taupe-lighten-04;
            margin-bottom: 10px;
            border-radius: 30px;
            padding: 8px;

            display: flex;
            gap: 10px;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            img {
                flex: 0 0 auto;
                height: 18px;
                width: 18px;
                margin: 0;
                padding: 0;
            }
            span {
                display: block;
                flex: 0 0 auto;
            }

            @media(max-width: @screen-xs-min)
            {
                width:100%;
                padding-left:0;
                padding-right:0;
            }
        }
    }

    /* text styles ******************************/
    .login__heading
    {
        color:@taupe-darken-03;
        font-family:@font-family-slab;
        font-size:24px;
        font-weight:normal;
        line-height:32px;
    }
    .login__rule
    {
        display:flex;
        align-items: center;
        justify-content:space-between;

        color:@taupe-darken-01;
        font-size:12px;
        font-weight:bolder;
        line-height:24px;

        &:before, &:after
        {
            content:" ";
            flex:1 1;
            display:block;
            width:100%;
            height:0;
            margin: 1px 5px;
            border-bottom:2px solid @white-darken-01;
        }
        &:before{ margin-left:0px; }
        &:after{ margin-right:0px; }
    }
    .login__copy
    {
        color:@taupe-darken-03;
        text-align:left;

        &.login__copy--default{ font-size:14px; }
        &.login__copy--larger{ font-size:16px; }
        &.login__copy--smaller{ font-size:12px; }
    }
    .login__tiptext
    {
        margin-top:5px;
        font-weight:bolder;

        &.login__tiptext--help
        {
            color:@taupe-darken-01;
            font-size:12px;
            line-height:16px;
        }
        &.login__tiptext--warn
        {
            color:@red-00;
            font-size:14px;
            line-height:24px;
        }
    }
    .login__alert
    {
        border:1px solid;
        padding:16px 20px;

        color:@taupe-darken-03;
        font-size:14px;
        font-weight: 600;
        line-height:16px;
        text-align:left;

        strong, p
        {
            display:block;
            font-size:16px;
            line-height:24px;
        }
        p
        {
            font-size:14px;
            line-height:16px;
        }
        *:first-child
        {
            margin-top:0;
        }
        *:last-child
        {
            margin-bottom:0;
        }
        &.login__alert--info{ border-color:@white-darken-03; }
        &.login__alert--good{ border-color:@green-lighten-03; }
        &.login__alert--good strong{ color:@green-00; }
        &.login__alert--warn{ border-color:@red-lighten-01; }
        &.login__alert--warn strong{ color:@red-00; }
    }

    /* form input styles ******************************/
    .login__field
    {
        text-align:left;

        label
        {
            display:inline-block;
            color:@taupe-darken-03;
            font-weight:bolder;
            font-size:16px;
            line-height:24px;
        }
        label[for]
        {
            cursor:pointer;
        }
        &.login__field--text
        {
            input, select
            {
                position: relative;
                display:block;
                width:100%;

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                box-sizing: border-box;
                border:1px solid @white-darken-05;
                border-radius:4px;
                padding:10px;
                margin:0;

                box-shadow:inset 0px 0px 5px rgba(0, 0, 0, 0.1);

                color:@black;
                font-family:@font-family-sans-serif;
                font-size:16px;
                line-height:24px;
            }
            select
            {
                background:url(/static/images/responsive/icon-login-dropdown.svg) no-repeat right center;
            }
        }
        &.login__field--bool
        {
            position:relative;
            padding-left:33px;

            input
            {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                position:absolute;
                top:0;
                left:0;
                display:block;
                width:24px;
                height:24px;

                box-sizing: border-box;
                border:2px solid @taupe-lighten-04;
                margin:0;
                padding:0;

                cursor:pointer;
                background:@white-darken-01;

                &:checked
                {
                    background: @ui-blue-00 url(/static/images/responsive/icon-login-check.svg) no-repeat center center;
                    border-color: @ui-blue-darken-01;
                }
            }
        }
        &.login__field--warn
        {
            input, select
            {
                border:2px solid @red-00;
            }
        }
    }
    .login__aux
    {
        clear:both;

        margin:5px auto;
        padding:0px 4px;
        border-radius:2px;
        cursor:pointer;

        color:@taupe-darken-01;
        font-weight:bolder;
        font-size:12px;
        line-height:20px;
        text-decoration: none;
        text-align:center;

        &:hover
        {
            background:@white-darken-01;
        }

        &.login__aux--inline{ display:inline-block; }
        &.login__aux--right{ float:right; }
        &.login__aux--center{ display:block; }
    }

}
/* login block modifiers *****************************************/
.login.login--header
{
    padding:50px 0;

    a
    {
        display:block;
        width:80%;
        max-width:250px;
        height:50px;
        margin:0 auto 0 auto;
    }
    svg
    {
        width:100%;
        height:auto;
    }
    path
    {
        fill: @blue-00;
    }
}
.login.login--form
{
    background:@white-00;

    padding:40px 0 24px 0;
    border:1px solid @white-darken-02;
    border-radius:4px;

    text-align:center;

    @media(max-width: @screen-xs-min)
    {
        max-width:100%;
        width:100%;
        border-left:none;
        border-right:none;
        border-radius:0;
        padding-left:10px;
        padding-right:10px;
        margin-top:-50px;
    }
}
.login.login--footer
{
    padding:20px;
    background:none;
    color:@taupe-darken-03;
    font-size:14px;
    line-height:24px;
}


.login--body {
    .cancel__wrapper {
        padding-top: 15px;

        .cancel-link,
        .cancel-link:visited {
            color: @taupe-darken-03;
            text-decoration: underline;

            &:hover {
                color: @taupe-darken-01;
            }
        }
    }
    .small-link {
        font-size: 12px;
        text-decoration: none;
        margin-left: 10px;

        &:hover {
            text-decoration: underline;
        }
    }
}


// Site Login Widget
.account_widget {
    .account-sign-in {
        h2 {
            font-family: @font-family-base;
            font-size: 18px;
            letter-spacing: 0;
            // color: @muted-text;
            font-weight: 300;
            display: none;
        }

        .social-login {

            @media (max-width: @screen-xs-max) {
                &:before {
                    content: "";
                    display: block;
                    height: 1px;
                    width: 80%;
                    margin-left: 10%;
                    border-top: 1px solid @border-color;
                    position: absolute;
                    left: 0;
                    bottom: -40px;
                }

                &:after {
                    content: "OR";
                    display: block;
                    height: 40px;
                    width: 60px;
                    background: @white-00;
                    left: 50%;
                    bottom: -60px;
                    margin-left: -30px;
                    position: absolute;
                    text-align: center;
                    line-height: 40px;
                }
            }


            @media (min-width: @screen-sm-min) {
                &:before {
                    content: "";
                    display: block;
                    height: 102%;
                    width: 1px;
                    border-left: 1px solid @border-color;
                    position: absolute;
                    right: -40px;
                    top: 0;
                }

                &:after {
                    content: "OR";
                    display: block;
                    height: 40px;
                    width: 60px;
                    background: @white-00;
                    right: -70px;
                    top: 35%;
                    position: absolute;
                    text-align: center;
                    line-height: 40px;
                }
            }

            .login__cta {
                &.login__cta--social {
                    width: 100%;
                }
            }
        }

        .email-login {
            padding-top: 0;
            @media (max-width: @screen-xs-max) {
                padding-top: @grid-gutter-width*2;
            }

            .login_submit__wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-content: flex-start;


                .form-group {
                    width: 100%;
                    .btn-primary-call {
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }
                }

                @media (min-width: @screen-sm-min) {
                    flex-direction: row;
                    gap: 10px;
                    .form-group {
                        width: 50%;
                    }
                    .help-text a {
                        line-height: 38px;
                    }
                }
            }

            .login_form__wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-content: flex-start;
                width: 100%;

                .form-group {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }

                @media (min-width: @screen-md-min) {
                    flex-direction: row;
                    gap: 10px;
                    .form-group {
                        width: 50%;
                    }
                }
            }
        }

        .no-label-wrapper {
            @media (max-width: @screen-xs-max) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .register-account {
        .social-media-buttons {
            position: relative;
            margin-top: @grid-gutter-width;
            padding-top: @grid-gutter-width/1;

            &:before {
                content: "";
                display: block;
                height: 1px;
                width: 100%;
                border-top: 1px solid @border-color;
                position: absolute;
                left: 0;
                top: 10px;
            }

            &:after {
                content: "OR";
                display: block;
                height: 40px;
                width: 60px;
                background: @white-00;
                left: 50%;
                top: -10px;
                margin-left: -30px;
                position: absolute;
                text-align: center;
                line-height: 40px;
            }
        }

        .no-label-wrapper {
            @media (max-width: @screen-xs-max) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .login {
        max-width: 100%;
        .login__item {
            max-width: 100%;

            .login__cta {
                &.login__cta--social {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    .account-form__innerwrapper {
        padding: 0;
    }

    .account_header {
        border-bottom: 1px solid @border-color;
        margin-bottom: 10px;
    }

    .account-well {
        font-size: 18px;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        margin: 20px 0;

        @media (max-width: @screen-xs-max) {
            font-size: 14px;
        }

        a {
            margin-left: 20px;

            @media (max-width: @screen-xs-max) {
                margin: 0 auto;
                display: block;
            }
        }
    }

    .help-text {
        display: flex;
        justify-content: space-between;
        a {
            display: block;
            flex: 1 1 auto;

        }
    }

    .remember_me__wrapper {
        margin: 0;
        padding: 10px 0;
        border-radius: 5px;

        .remember_me__form {
            display: flex;
            height: 24px;
            margin-bottom: 5px;

            .remember_me__label {
                flex: 1 1 auto;
                line-height: 24px;
                vertical-align: middle;
                margin: 0 0 0 8px;
            }

            .remember_me__checkbox {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: block;
                flex: 0 0 auto;
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                border: 2px solid @taupe-lighten-04;
                margin: 0;
                padding: 0;
                cursor: pointer;
                background: @white-00;

                &:checked {
                    background: @ui-blue-00 url("/static/images/responsive/icon-login-check.svg") no-repeat center center;
                    border-color: @ui-blue-darken-01;
                }
            }
        }

        .remember_me__text {
            font-size: 12px;
            line-height: 18px;
            color: @taupe-darken-01;
        }
    }


    @media (max-width: @screen-xs-max) {
        .btn-primary-call {
            width: 100%;
            margin-top: 10px;
        }
    }
}

// Store Login Widget
.store-login-widget {
    @media (max-width: @screen-xs-max) {
        margin: 15px 0;
    }
    .form-horizontal {
        .form-group-sm {
            .control-label,
            .control-input,
            .input-col {
                width: 100%;
                text-align: left;
            }
        }

        .submit__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .submit_btn__wrapper {
                height: 32px;
                flex: 0 0 auto;
            }
            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                flex-direction: column;
                .submit_btn__wrapper {
                    width: 100%;
                    margin-top: 10px;
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
        .remember_me__wrapper {
            display: flex;
            flex-direction: row;
            height: 32px;
            font-size: 14px;
            line-height: 32px;
            flex: 1 1 auto;
            white-space: nowrap;

            .remember_me__label {
                flex: 1 1 auto;
                height: 32px;
                font-size: 14px;
                line-height: 32px;
                display: block;
                margin-left: 5px;
            }
            .remember_me__checkbox {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: block;
                flex: 0 0 auto;
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                border: 2px solid @taupe-lighten-04;
                margin: 4px 0;
                padding: 0;
                cursor: pointer;
                background: @white-00;

                &:checked {
                    background: @ui-blue-00 url("/static/images/responsive/icon-login-check.svg") no-repeat center center;
                    border-color: @ui-blue-darken-01;
                }
            }
        }

        .remember_me__text {
            font-size: 14px;
            line-height: 20px;
        }
    }
    p {
        font-size: 14px;
        line-height: 18px;
        color: @taupe-darken-01;
    }

    .social-media-buttons {
        li {
            margin-bottom: 5px;
        }
    }

    .login {
        margin: 15px auto;

        .login__rule {
            &:before,
            &:after {
                border-color: @taupe-lighten-04;;
                border-width: 1px;
            }
        }
        max-width: 100%;
        .login__item {
            max-width: 100%;

            .login__cta {
                &.login__cta--social {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    .social-login {
        .remember_me__wrapper {
            margin: 0;
            padding: 10px 0;
            border-radius: 5px;

            .remember_me__form {
                display: flex;
                height: 24px;
                margin-bottom: 5px;

                .remember_me__label {
                    flex: 1 1 auto;
                    font-size: 14px;
                    line-height: 24px;
                    vertical-align: middle;
                    margin: 0 0 0 8px;
                    font-weight: normal;
                }

                .remember_me__checkbox {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    display: block;
                    flex: 0 0 auto;
                    width: 24px;
                    height: 24px;
                    box-sizing: border-box;
                    border: 2px solid @taupe-lighten-04;
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                    background: @white-00;

                    &:checked {
                        background: @ui-blue-00 url("/static/images/responsive/icon-login-check.svg") no-repeat center center;
                        border-color: @ui-blue-darken-01;
                    }
                }
            }

            .remember_me__text {
                font-size: 14px;
                line-height: 20px;
                color: @taupe-darken-01;
            }
        }

    }
}

// Remember Me UI
.login,
.remember_me__wrapper {
    .remember_me_info__link {
        text-decoration: none;
        margin-left: 5px;
        color: @green-00;
    }
    .remember_me_info__text {
        font-size: 14px;
        line-height: 20px;
    }
    .login__item {
        &.login__item--remember_me {
            width: 170px;
            margin: 10px auto 14px 0;
        }
    }
}
