// ------------------------------------------------------------------------- 20th Year
.site-notice {
  position: fixed;
  bottom: -100px;
  left: 3%;
  background: @site-notice-bt;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 10px 15px;
  z-index: 15;
  box-shadow: 0 3px 3px fade(#000, 40%);
  .transition(~"all 0.2s ease-in-out");

  @media (max-width: @screen-xs-max) {
    left: 0;
    width: 100%;
    border-radius: 0;
    box-shadow: 0 -3px 3px fade(#000, 40%);
  }

  &.slide-in {
    bottom: 0;
  }
  .milestone-logo {
    background-image: url(/static/images/responsive/20-logo.svg);
    width: 328px;
    height: 40px;
    display: inline-block;
    text-indent: -9999px;
  }

  .btn-support {
    text-transform: uppercase;
    .kern-wide;
    .btn-sm;
    margin: 10px 0 0 10px;

    @media (max-width: @screen-xs-max) {
      .btn-block;
      margin-left: 0;
    }
  }

  .close {
    position: absolute;
    top: -1px;
    right: 3px;
    font-size: 16px;
    opacity: 0;
  }
  &:hover {
    .close {
      opacity: 0.3;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}