// alternate nav-tabs
.nav-tabs.nav-tabs--alternate {
  border: none;
  border-bottom: 1px solid @taupe-lighten-04;
  margin-top: 0;
  margin-bottom: 48px;

  /* reset borders */
  > li,
  > li.active {
    > a,
    > a:hover {
      border: none;
    }
  }
  > li > a {
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
    color: @taupe-darken-01;
  }
  > li.active {
    border-bottom: 4px solid @blue-00;
    > a {
      color: @blue-darken-01;
    }
  }
}

// alternate list-group

.list-group.list-group--alternate {
  .list-group-item.list-group-item--header {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    color: @taupe-darken-03;
    text-transform: uppercase;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .list-group-item {
    padding: 12px 24px;
    background-color: @white-darken-01;
    border: 1px solid @taupe-lighten-04;
    font-weight: bold;
    color: @taupe-darken-03;

    &:first-child,
    &:last-child {
      border-radius: 0;
    }
    &.active {
      color: @blue-darken-01;
      background-color: @white-00;
    }
  }
  a.list-group-item {
    &:hover {
      background-color: @white-darken-02;
    }
    &.active:hover {
      background-color: @white-00;
    }
  }
}
