.use-primary-address-as-shipping {
  text-transform: none;
  margin: 0 0 1em;

  label {
    font-weight: normal;
  }
}


.param-group
{
  margin:0 0 24px 0;
  padding:0;
  border:1px solid @taupe-lighten-04;
  box-sizing: border-box;

  .param-group__header
  {
    display:flex;
    justify-content: space-between;
    margin:0;
    padding:24px 21px;
    box-sizing: border-box;

    color: @taupe-darken-03;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: @screen-xs-max) {
      display: block;

      .param-group__headertitle{
        display: block;
        width: 100%;
        margin-bottom: 5px;
      }
      .param-group__action {
        width: 100%;
      }
    }
  }

  .param-group__subtitle {
    color: @taupe-darken-01;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
  }

  .param-group__member
  {
    margin:0;
    padding:22px 21px;
    border-top:1px solid @taupe-lighten-04;
    box-sizing: border-box;
  }

  .param-group__action
  {
    display:inline-block;
    box-sizing:border-box;
    padding:4px 21px;
    border:1px solid @taupe-lighten-04;

    border-radius:4px;
    background:@white-darken-01;

    color:@taupe-darken-03;
    font-size:14px;
    font-weight: 700;
    line-height:24px;
  }
  .param-group__action--success
  {
    border:1px solid @ui-yellow-lighten-02;
    background:@ui-yellow-lighten-02;
    color:@taupe-darken-01;
  }
  .param-group__action--failure
  {
    border:1px solid @ui-red-00;
    background:@ui-red-00;
    color:@white-00;
  }
}

.param-group--borderless
{
  border:none;
  margin:0;

  .param-group__header
  {
    padding:0;

    color: @blue-00;
    font-size: 14px;
    font-weight:900;
    line-height: 24px;
    text-transform:uppercase;
  }
  .param-group__member
  {
    border:none;
    padding:0;
    margin-top:24px;
  }
}

.param-group--trivial
{
  border:none;
  margin:0;

  .param-group__header
  {
    padding:0;

    color: @taupe-darken-03;
    font-size: 16px;
    font-weight:700;
    line-height: 24px;
    text-transform:unset;
  }
  .param-group__member
  {
    border:none;
    padding:0;
    margin-top:0;
    color: @taupe-darken-01;
  }
}

[data-transaction-mode] [data-transaction-match]{ display:none; }
[data-transaction-mode='initial'] [data-transaction-match~='initial']{ display:block; }
[data-transaction-mode='suspend'] [data-transaction-match~='suspend']{ display:block; }
[data-transaction-mode='success'] [data-transaction-match~='success']{ display:block; }
[data-transaction-mode='failure'] [data-transaction-match~='failure']{ display:block; }
[data-transaction-mode='bounced'] [data-transaction-match~='bounced']{ display:block; }
[data-transaction-mode]{ transition: opacity 0.3s; }
[data-transaction-mode='suspend']{ opacity:0.5; }


/* Account  */
body.account {
  .account-error {
    p, span {
      display: inline-block;
      margin: 10px;
    }
    span.more-info-btn {
      float: right;
      a {
        color: @state-danger-text;
        font-weight: bold;
      }
    }
  }

  .podcast-container {
    h4 {
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: @taupe-00;
    }
    h5 {
      font-size: 24px;
      line-height: 36px;
    }

    p,
    .text-list {
      font-size: 18px;
      line-height: 24px;
    }
    .media-heading,
    .media-body {
      font-size: 16px;
      line-height: 20px;
    }
    a.btn-sm {
      font-size: 14px;
      line-height: 1.5;
    }

    .ui-form {
      .iframe_wrapper {
        .error {
          h5 {
            font-size: 16px;
            line-height: 20px;
            margin: 10px 0 5px 0;
            font-weight: bold;
            font-family: @font-family-sans-serif;
          }
        }
      }
    }


  }

  .header-support {
    h4.section-title {
      font-family: @font-family-sans-serif;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      text-transform: uppercase;
      color: @taupe-darken-01;
      letter-spacing: 0;
    }

    h1.article-title {
      line-height: 48px;
      font-size: 32px;
      color: @black;
      font-family: @font-family-serif;
      letter-spacing: 0;
    }
  }

  .account-screen-subheader {
    color: @taupe-darken-02;
    line-height: 24px;
    font-size: 16px;
    font-family: @font-family-sans-serif;
    font-weight: bold;
    padding: 12px 0;
    margin: 0;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
  }

  .form-section__address {
    margin-bottom: 48px;

    .address-submit-wrapper {
      text-align: right;
      margin-top: 24px;
    }

    @media (min-width: @screen-md-min) {

      .address-form__row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        .form-group {
          flex: 1 1 auto;
        }
      }
    }
  }


  // Account Deletion Section
  .account-delete {
    border-top: 1px solid @taupe-lighten-04;
    margin-top: 25px;
    padding-top: 15px;

    .account-delete__wrapper {
      margin: 0;
      @media (min-width: @screen-sm-min) {
        margin: 0 0 0 15px;
      }

      .ui-form-box {
        font-size: 16px;
        line-height: 1.5;
        padding: 15px;
        margin-top: 5px;

        .send__button {
          margin-top: 15px;
        }
      }
    }
  }




  // Small Screen Nav buttons {
  .small-nav {
    margin: 0 0 15px 0;
    padding: 0;

    a {
      text-align: left;
      text-indent: 10px;
      width: 100%;
      background-color: transparent;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0;
      color: @link-color;
      display: block;
      background-image: url("/static/images/icons8-xbox-menu.png");
      background-repeat: no-repeat;
      background-position: 98% center;
    }
  }
  .account-small-nav-modal {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    .close {
      opacity: 1;
    }
    .modal-dialog {
      margin: 20px auto;
      max-width: 400px;
    }
    ul {
      width: 100%;
      list-style-type: none;
      padding-bottom: 5px;
      padding-left: 20px;
      li {
        width: 100%;
        display: block;
        border: none;
        vertical-align: middle;
        line-height: 24px;
        font-size: 18px;
        padding: 15px 0;
        a {
          color: @taupe-00;
          text-decoration: none;
        }
        &.active {
          background-image: none;
          > a {
            color: @grey-darken-02;
            font-weight: bold;
          }
        }
        ul.account-small-nav-submenu {
          display: none;
          li {
            font-weight: normal;

            ul.account-small-nav-submenu {
              li.active {
                > a {
                  font-weight: normal;
                  color: @link-color;
                  &:before {
                    content: "";
                    margin-left: 0;
                  }
                }
              }
            }
          }
          li.active {
            > a {
              font-weight: bold;
              &:before {
                content: "– ";
                margin-left: -1em;
              }
            }
            ul.account-small-nav-submenu {
              li.active {
                > a {
                  font-weight: bold;
                  color: @grey-darken-02;

                  &:before {
                    content: "– ";
                    margin-left: -1em;
                  }
                }
              }
            }
          }
          li,
          li.active {
            padding: 8px;
            a {
              font-size: 16px;
              line-height: 24px;
            }
            ul {
              li,
              li.active {
                padding: 0;
              }
            }
          }
        }

        &.active {
          ul.account-small-nav-submenu {
            display: block;
          }
        }
      }
    }
    ul.list-unstyled {
      > li.has_submenu {
        > a:after {
          content: url("/static/images/responsive/icons8-chevron-down.svg");
          transform: rotate(180deg);
          float: right;
          padding-right: 5px;
          padding-left: 5px;
        }
        &.active {
          a:after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  section.not-tp {
    text-align: center;
    border-radius: 10px;
    padding: 25px;
    margin-top: 15px;
  }
  section.new-tp {
    text-align: left;
    border-radius: 5px;
    padding: 25px 25%;
    margin-top: 25px;
    background-color: @white-darken-02;
    font-size: 1em;
    line-height: 1.1em;
    h2 {
      color: @blue-00;
      font-size: 1.5em;
    }
  }
  .tp-status {
    margin-top: 15px;
  }
  .bottom-spacer {
    padding-bottom: 15px;
  }
  .spinner_box {
    text-align: center;
    padding-top: 100px;
  }
  .profile_sidebar {
    font-size: 16px;
    font-family: @font-family-sans-serif;
    .profile_sidebar_box {
      .profile_sidebar_nav {
        padding: 0;
        background-color: transparent;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        li {
          background-color: @white-darken-01;
          list-style: none;
          vertical-align: middle;
          padding: 0;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          border: 1px solid @white-darken-04;
          font-size: 16px;
          line-height: 20px;
          color: @muted-text;
          @media (max-width: @screen-sm-max) {
            font-size: 14px;
            &.header,
            a {
              padding: 10px;
            }
          }
          &.header,
          a {
            color: @taupe-00;
            padding: 10px 5px 10px 20px;
          }
          &.sidebar-option {
            min-height: 59px;
            border-top: none;
            cursor: pointer;
            &:hover {
              background-color: @white-00;
              border-color: @white-00;
            }
          }
          &.header {
            min-height: 67px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            letter-spacing: 1.05px;
            background: @white-darken-03;

            span {
              display: block;
              vertical-align: middle;
            }
          }
          .is-submenu {
            font-size: 14px;
            text-indent: 20px;
            display: none;
            width: 100%;
            background: transparent;
            border: none;
            margin: 0;
            padding: 0;
            li {
              background: transparent;
              border: none;
              margin: 0;
              padding: 0;
            }
            a {
              display: block;
              color: @gray-00;
              line-height: 27px;
              font-size: 14px;
              text-indent: 40px;
              padding: 0;
              margin: 0;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          a {
            display: block;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            color: @taupe-00;
            font-size: 16px;
          }
          &.active {
            background-color: @white-00;
            a {
              cursor: default;
              color: @support-color;
              text-decoration: none;
            }
            .is-submenu {
              display: block;
              a {
                color: @gray-00;
              }
              li.active {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .email-subscriptions {
    .thumbnail-wrapper {
      padding: 20px;
      .thumbnail {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
      }

      @media (min-width: @screen-md-min) {
        padding: 20px 10px 20px 0;
        .thumbnail {
          max-width: 255px;
          margin: 0;
          float: right;
        }
      }
      @media (min-width: @screen-lg-min) {
        .thumbnail {
          max-width: 200px;
        }
      }
    }
  }

  .validate-error {
    color: @red-00;
  }

  .tp-overview-section {
    padding-top: 15px;
    padding-bottom: 30px;
  }

  .tp-flex-resource {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    flex-flow: row wrap;
    justify-content: flex-start;

    .overview-resource-image {
      width: 100%;
      text-align: center;
      flex: 0 0 auto;
      padding:25px;

      img {
        max-height: 350px;
        margin: 0 auto;
      }
    }

    .overview-resource-description {
      flex: 1 1 auto;
      align-self: center;
    }

    @media (min-width: @screen-md-min) {
      flex-flow: row nowrap;

      .overview-resource-image {
        width: 30%;

        &.right-side {
          order: 2;
        }
      }
    }
  }

  .monthly_resource {
    .resource-selection {
      margin-bottom: 20px;
      a {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: flex-start;
        margin: 5px;
        input {
          flex: 0 0 auto;
          align-self: flex-start;
          margin-top: 3px;
          line-height: 20px;
        }
        span {
          flex: 1 1 auto;
          padding-left: 10px;
        }
      }
    }
    .resource-image {
      width: 100%;
      text-align: center;
      img {
        max-height: 350px;
      }
    }
    .address_selection {
      display: block;
      clear: both;
      padding: 20px 0;
      .shipping_address_box__details {
        float: left;
      }
    }
    &.tp-resource {
      .tp-resource-option {
        clear: both;
        padding-bottom: 20px;
        border-bottom: 1px solid @white-darken-05;
        &.tp-request {
          border-bottom: none;
        }
        .resource-request {
          max-width: 600px;
          padding: 20px;
          border: 1px solid @white-darken-05;
          border-radius: 2px;
          background-color: @white-darken-01;
          display: block;
          overflow: auto;
        }
        .resource-selection {
          margin-bottom: 20px;
          display: block;
          overflow: auto;
          a {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: flex-start;
            margin: 5px;
            input {
              flex: 0 0 auto;
              align-self: flex-start;
              margin-top: 3px;
              line-height: 20px;
            }
            span {
              flex: 1 1 auto;
              padding-left: 10px;
            }
          }
        }
      }
      .resource-description {
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        font-size: 18px;
        line-height: 24px;

        h2.title {
          margin-bottom: 0;
        }
        h4.marketvalue {
          margin-top: 0;
        }
      }
    }
  }

  .tp-resource {
    .overview-resource-image {
      width: 100%;
      text-align: center;
      img {
        max-height: 350px;
        margin: 0 auto;
      }
      @media (min-width: @screen-md-min) {
        width: 30%;
        float: right;
      }
      &.left-side {
        @media (min-width: @screen-md-min) {
          width: 30%;
          float: left;
        }
      }
    }
    .overview-resource-details {
      font-size: 14px;
      line-height: 17px;
      ul {
        margin-left: 20px;
      }
      a {
        cursor: pointer;
      }
    }
    .resource-image {
      @media (min-width: @screen-md-min) {
        width: 30%;
        float: right;
        margin: 0 auto;
        img {
          max-height: 350px;
        }
      }
    }
    .resource-description {
      padding: 25px;
      background-color: @white-darken-02;
      border-radius: 25px;
      overflow: auto;
      min-width: 350px;
    }
    .resource-request {
      margin-top: 10px;
      text-align: left;
      margin-right: 35px;
      .available-formats {
        .format {
          background-color: @white-00;
        }
      }
    }
    .resource-selection {
      h5 {
        margin-bottom: 5px;
      }
      a {
        margin-left: 10px;
        line-height: 20px;
      }
    }
  }
  .tp-update-details {
    padding: 0 15px;
    .amount_wrapper {
      max-width: 400px;
    }
    .amount_wrapper_with_cc {
      max-width: 600px;
      margin-bottom: 20px;
      padding-right: 20px;
    }
    .has_error {
      color: @red-00;
    }
  }
  .no-margin {
    margin-bottom: 0;
  }
  .truthpartner-overview-details {
    padding: 10px;
  }
  .tp-actions {
    list-style: none;
    padding: 5px 0 15px 0;
    li {
      border-bottom: 1px solid @white-darken-04;
      > a {
        padding: 20px 0;
        display: block;
        .font-secondary;
        font-weight: 600;
        strong {
          font-size: 1.1em;
        }
        .help-block {
          padding-left: 17px;
          font-size: 0.9em;
          .font-base;
          margin-top: 0;
          color: @muted-text;
        }
        &:before {
          .ti;
          content: "\•";
          margin-right: 6px;
          position: relative;
          top: 1px;
          color: @muted-text;
        }
        .ti-external-link {
          top: 2px;
          position: relative;
        }
      }
      .ti-big {
        height: 20px;
        width: 20px;
        padding: 0px 0 3px 0;
        margin: 0;
        vertical-align: middle;
      }
    }
  }
  .send-gift-monthly {
    padding-top: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  ul.tp-cancel {
    list-style: none;
    li {
      padding: 3px;
      border: none;
    }
  }

  .tp-mom-section {
    padding-top: 20px;
    clear: both;
    display: block;
    .title {
      color: @title-color;
      font-size: 33px;
      line-height: 42px;
    }
  }
  .tp-mom-digital {
    padding-bottom: 20px;
    border-bottom: 1px solid @white-darken-05;
    max-width: 600px;

    h5 {
      width: 100%;
      display: block;
      .btn {
        float: right;
        font-family: @font-family-sans-serif;
      }
    }
    .media-player-wrapper {
      display: block;
      width: 100%;
      flex: none;
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    .motm_tools {
      width: 100%;
      text-align: right;
    }
  }

  .tp-mom-request {
    max-width: 600px;
    padding: 20px;
    border: 1px solid @white-darken-05;
    border-radius: 2px;
    background-color: @white-darken-01;

    p {
      font-weight: normal;
      margin-bottom: 10px;
    }
    .tp-mom-request-form-item {
      padding: 10px 0;
      label {
        font-weight: normal;
      }
    }
  }
  .mom-product,
  .mom-options {
    float: left;
    width: 50%;
  }
  .explainbox textarea {
    width: 90%;
    height: 150px;
    margin-left: 15px;
  }

  .tp-statusbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    background-color: @white-darken-05;
    border-bottom: 1px solid @white-darken-05;
    border-left: 1px solid @white-darken-05;
    border-right: 1px solid @white-darken-05;
    background-color: @white-darken-01;

    .tp-statusbar-item {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      border-top: 1px solid @white-darken-05;
      color: @taupe-00;
      font-size: 14px;
      line-height: 17px;
      @media (min-width: @screen-lg-min) {
        width: 50%;
      }

      .tp-status-label {
        flex: 0 0 auto;
        min-width: 155px;
        font-weight: bold;
        text-align: right;
        padding: 10px;
        background-color: @white-darken-03;
      }
      .tp-status-value {
        padding: 10px;
        flex: 1 1 auto;

        a {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .edit-pledge {
    position: relative;
    top: 3px;
    margin-left: 5px;
  }

  .transaction-yearend {
    font-size: 16px;
    line-height: 24px;
    color: @taupe-darken-03;

    h5.transactions__yearend_list-title {
      font-size: 16px;
      line-height: 24px;
      color: @taupe-darken-03;
      margin-bottom: 5px;
    }
    .transactions__yearend_list {
      padding: 0 0 20px 0;
      li {
        list-style: none;
        margin: 6px 0;
        a {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    p.minortext {
      font-size: 14px;
      line-height: 24px;
      color: @taupe-darken-03;
      a {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .transaction-prefs {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;

    h5 {
      font-size: 16px;
      line-height: 24px;
      color: @taupe-darken-03;
      padding: 0;
      margin: 0;
    }
    .minortext {
      margin-top: 10px;
    }
    .prefbox {
      height: 100%;
      margin: 18px 10px;
      flex: 0 0 auto;

      .prefcheckbox {
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
      }
    }
    .preftitle {
      flex: 1 1 auto;
      margin-top: 18px;
      margin-right: 10px;
    }
  }
}

.donate {
  .success_status {
    background-color: @white-darken-03;
    font-weight: bold;
    font-size: 18px;
    color: @support-color;
    overflow: hidden;
    padding: 10px 15px;
    text-align: center;
    margin: 0 0 0 15px;
    @media (max-width: @screen-xs-max) {
      max-width: 100%;
      display: block;
      margin-right: 15px;
    }
  }
}

.donation-overview-details {
  .panel {
    border-radius: 3px;
    padding: 0 20px;
    border-color: @white-darken-03;
    address {
      font-size: 1em;
    }
    h5 {
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 2.25em;
      margin-bottom: 5px;
      color: @muted-text;
      > a {
        text-transform: none;
        position: relative;
        top: -1px;
      }
    }
    hr {
      display: none;
    }
    .table {
      background-color: @white-00;
      td {
        text-align: right;
      }
    }
  }
}

.italic {
  font-style: italic;
}

.account-profile-form {
  h4 {
    margin-bottom: 1em;
  }
  div.radio {
    margin-top: 0;
    padding-top: 0;
  }
  .stationpicker-wrapper {
  }

  .alert-danger {
    overflow: auto;
    .alert-message {
      padding: 0;
      margin: 0;
      p {
        display: inline-block;
        margin: 0;
        line-height: 38px;
      }
      .more-info-btn {
        display: inline-block;
        float: right;
      }
      @media (max-width: @screen-sm-max) {
        padding: 5px;
        p {
          display: block;
          line-height: 1.5;
        }
        .more-info-btn {
          display: block;
          float: none;
          width: 100%;
          a {
            width: 100%;
          }
        }
      }
    }
  }
}

strong.section-label {
  display: block;
  margin: 0 0 1em;
}

.form-group-goto {
  padding-top: 20px;
}
.transaction-overview {
  background-color: @muted-background;

  &__section {
    border-top: 1px solid @white-darken-03;
    padding: 0 @line-height-computed;
    padding-top: @line-height-computed;
    &--no-border {
      border-top: none;
    }
  }

  &__date {
    text-transform: uppercase;
    font-weight: bold;
    color: @gray;
  }

  &__tracking dd {
    white-space: nowrap;
  }

  &__payment-number {
    float: right;
  }

  &__transaction-id {
    text-align: center;
    padding-bottom: @line-height-computed;

    dd {
      font-family: @font-family-monospace;
    }
  }

  dt {
    text-transform: uppercase;
    font-size: 12px;
    color: @gray;
    line-height: 2;
  }
  dd {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    text-decoration: underline;
  }
}

.skeleton {
  background-color: @white-darken-04;
  border-radius: 0.25em;
}
.skeleton-container() {
  position: relative;

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    animation: loading 1.5s infinite;
    top: 0;
  }
}
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.transaction-line-items--skeleton {
  .skeleton-container();
}

.transaction-overview--skeleton {
  .skeleton-container();

  .transaction-overview__list--skeleton {
    dd:empty {
      width: 100%;
      height: 1em;
      margin: 0.25em 0;
      &:extend(.skeleton);

      &:nth-of-type(3n) {
        width: 91%;
      }
      &:nth-of-type(even) {
        width: 77%;
      }
    }
  }
}
.view-transactions-header,
.view-truthpartner-header {
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;

  a {
    color: @taupe-00;
  }

  img {
    margin-top: -6px;
  }
}
.summary__amount {
  float: right;
  &--total {
    font-weight: bold;
  }
}
.transaction-line-items {
  list-style: none;
  padding-left: 0;
}
.transaction-line-item {
  display: flex;
  border-bottom: 1px solid @white-darken-03;
  &.refund {
    background-color: @white-darken-01;
  }

  &__image {
    align-self: center;
    flex-shrink: 0;
    &--skeleton {
      height: 150px;
      width: 150px;
      &:extend(.skeleton);
    }
  }
  &__description {
    padding: @line-height-computed;
    flex: 1;
  }

  &__title {
    font-family: @font-family-slab;
    font-weight: bold;
    &--skeleton {
      height: 1.25em;
      margin: 0.25em 0 1em;
      width: 90%;
      &:extend(.skeleton);

      &-2 {
        width: 66%;
      }
      &-3 {
        width: 80%;
      }
    }
  }
  &__price {
    font-weight: bold;
    display: block;
    &--skeleton {
      max-width: 5em;
      height: 1.25em;
      margin: 0.25em 0 1em;
      &:extend(.skeleton);
    }
  }
  &__quantity {
    display: block;
    &--skeleton {
      max-width: 8em;
      height: 1.25em;
      margin: 0.25em 0 1em;
      &:extend(.skeleton);
    }
  }
}

.my-library {
  margin-top: 40px;
}

.account-screen-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  font-family: @font-family-sans-serif;
  line-height: 24px;
  border-top: 1px solid transparent;
  color: @taupe-darken-01;
}
body.account .podcast-container .account-screen-leadin {
  margin:12px 0 24px 0;
  color:@taupe-darken-02;
  font-size:14px;
  font-weight:unset;
  line-height:24px;
}

// Enhanced instructions
.instruction-box {
    margin-top: 50px;

    .instruction-box_header {
    color: @taupe-darken-03;
    font-family: @font-family-sans-serif;
    font-size: 20px;
    font-weight: 800;
    }
    .instruction-box_list_body {
      .instruction-box_list_body_title {
        color: @taupe-darken-01;
        font-family: @font-family-sans-serif;
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 2px;
      }
      .instruction-box_list_body_list {
        padding-left: 17px;

        li {
          color: @taupe-darken-03;
          font-family: @font-family-sans-serif;
          font-size: 16px;
        }
      }
    }
    .instruction-box_individual_body {
      .instruction-box_individual_body_title {
        color: @taupe-darken-01;
        font-family: @font-family-sans-serif;
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 2px;
      }
      .instruction-box_individual_body_list {
        padding-left: 17px;

        li {
          color: @taupe-darken-03;
          font-family: @font-family-sans-serif;
          font-size: 16px;
        }
      }
    }
    .instruction-box_list_section_demo_image {
      max-width: 100%;
    }
}

.saved_payment_options__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0 10px;
  align-items: flex-start;
}

.saved_payment_selection_box {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;

  .payment_method__wrapper {
    margin: 0;
  }

  .payment_method {
    background-color: @white-00;
  }
  .change_link {
    padding-left: 10px;
  }

  @media (max-width: @screen-tiny-max) {
      padding: 5px;
      flex-direction: column;

      .payment_method__wrapper {
        margin: 10px 0;
      }
  }
}
.billing_address_box {
  margin-bottom: 20px;

  .billing_address_box__details {
    float: left;
  }
}

.giving-form {
  .alert-truthpartner,
  .alert-primary {
    display: none;
  }
}


.payment_method__wrapper {
    width: 100%;
    max-width: 350px;
    margin: 10px 0;
    color: @taupe-darken-03;


  .payment_method {
      border: 1px solid @white-darken-05;
      border-radius: 5px;
      width: 100%;
      padding: 10px;

      &.inactive {
        opacity: 0.4;
        background-color: @grey-darken-02;
        cursor: not-allowed;
      }

      .payment_method__details {
        display: flex;
        flex-direction: row;

        .payment_method__logo {
          display: flex;
          flex-direction: column;
          flex: 0 0 auto;
          width: 75px;

          .payment_method__img {
            height: 100%;
            width: 100%;

            .payment_method__thumbnail {
              width: 100%;
            }
          }
        }

        .payment_method__info {
          flex: 1 1 auto;
          padding: 0 10px;

          .payment_method__name {
            width: 100%;
            font-weight: bold;
          }
          .payment_method__meta {
            width: 100%;
            font-weight: bold;
          }

        }
      }

      .payment_method__title {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid black;
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 14px;
      }
  }
  .payment-alert {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    margin-top: 4px;
    padding: 0 6px;
    margin-bottom: 0;
    margin-top: 4px;
  }
}
a.payment_method__wrapper:hover {
  color: @taupe-darken-03;
  opacity: 0.7;
}
.alert-truthpartner {
  background-color: @blue-darken-01;
  border-color: @tp-yellow;
  color: @white-00;
}
.alert-primary {
  background-color: @green-00;
  border-color: @green-darken-02;
  color: @white-00;
}

.saved_payment_options__wrapper--slim{
  flex-direction: column;

  .payment_method__wrapper {
    margin: 5px 0;
    cursor: pointer;
    color: @taupe-darken-01;
    .payment_method {
      margin: 0;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid @white-darken-01;

      .payment_method__details {
        margin-left: 32px;
        .payment_method__logo {
          width: 40px;
        }
      }
      &.active{
        background-repeat: repeat-x;
        color: @taupe-darken-01;
        border-color: @blue-00;
      }
      &:hover {
        border-color: @blue-00;
      }
    }
  }
  .iframe_wrapper {
    .class_input_box {
      width: 100% !important;
    }
  }
}


// Account Deleteion Confirmation Section
.account_delete__info {
  padding: 20px;
  @media (min-width: @screen-sm-min) {
    padding: 20px 20%;
  }
  text-align: left;
  line-height: 24px;
  font-size: 14px;

  ul {
    margin-bottom: 20px;
    li {
      margin-bottom: 10px;
    }
  }

  .account_delete__confirm {
    margin-top: 40px;
    padding: 5px 15px;
    font-size: 12px;
    line-height: 18px;
  }

  .account_delete__submit {
    padding: 15px 0;
  }

  .fineprint {
    font-size: 10px;
    line-height: 14px;
    padding: 10px;
    margin-top: 30px;
    border: 1px solid @taupe-lighten-03;
  }
}
