//FIXME: Webview style. 
.btn-full {
    width: 100%;
}


body.verifying_address {
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    #verify_address.address_overlay {
        display: block;
    }
}

#verify_address {
    &.address_overlay {
        display: none;

        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2500;
        background-color: rgba(3, 3, 3, 0.8);
        backdrop-filter: blur(5px);

        .address_processing {
            height: 250px;
            width: 250px;
            padding: 50px 30px;
            background-color: @blue-00;
            color: @white-00;
            font-size: 32px;
            line-height: 38px;
            text-align: center;
            margin: 25vh auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span {
                flex: 0 0 auto;
                display: block;
                &.processing_copy {
                    padding: 20px;
                }

                svg {
                    height: 44px;
                    width: 44px;
                }
            }
        }

        .address_prompt {
            display: none;
         
            margin: 20vh auto;
            width: 95%;
            max-width: 600px;
            background-color: @blue-00;
            color: @white-00;

            .prompt_box {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
    
                .prompt_box__header {
                    flex: 1 1 auto;
                    height: 50px;
                    width: 100%;
                    color: @white-00;
                    text-align: center;
                    font-size: 24px;
                    line-height: 50px;
                }

                .prompt_box__body {
                    flex: 1 1 auto;
                    background-color: @white-00;
                    display: flex;
                    flex-direction: row;
                    color: @black-00;

                    .prompt_box__suggestion {
                        padding: 0 16px;
                        min-width: 50%;
                        flex: 1 1 auto;
                        border-left: 1px solid @blue-00;


                        .address_option {
                            padding: 5px;
                            background-color: @white-darken-02;
                            margin-bottom: 10px;
                        }
                    }
                    .prompt_box__entered {
                        padding: 0 16px;
                        min-width: 45%;
                        border-right: 1px solid @blue-00;
                        button {
                            margin-bottom: 10px;
                        }
                        address {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    address {
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 5px;
                    }
                }
            }
            @media (max-width: @screen-xs-max) {
                margin: 5vh auto;
                .prompt_box {
                    .prompt_box__header {
                        font-size: 18px;
                        line-height: 22px;
                        padding: 10px;
                        height: 64px;
                    }
    
                    .prompt_box__body {
                        flex-direction: column;

                        .prompt_box__suggestion {
                            border: none;
                            width: 100%;
                        }
                        .prompt_box__entered {
                            border: none;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}