// ------------------------------------------------------------------------- Store

.OrderSummary
{
  padding-top:1em;
  padding-bottom:2em;
  border-top:1px solid @border-color;

  .DownloadURL
  {
    display:none;
  }

  @media print
  {
    .DownloadURL
    {
      display:block;
    }
    .DownloadButton
    {
      display:none;
    }

    & > :first-child
    {
      width:100%;
    }
  }

  table
  {
    color: @text-color;
    font: 15px Arial, Helvetica, sans-serif;
    line-height: 18px;

    thead th
    {
      color: @support-color;
    }
    td
    {
      font-size: 13px;
    }
    td, th
    {
      padding-left:0 !important;
    }
    tbody tr:hover, tfoot tr:hover
    {
      background: @muted-background;
    }
  }

}

//body.store #subnav-toggle span.caret:before
//{
//  display: block;
//  position: absolute;
//  top: 17px;
//  right: 40px;
//
//  color: @muted-text;
//  font-size: 14px;
//  content:"Categories";
//}
.store-photo-wrapper > a > img
{
  @media(max-width: @screen-sm-min)
  {
    max-height:150px;
    height:auto;
    display:block;
    margin:0 auto;
  }
}

.store-landing {
  #site-canvas {
    background-color: @white-darken-01;
  }
}
.store {
  &.product-detail {
    #site-canvas {
      #gradient > .vertical(@start-color: @white-00; @end-color: @white-darken-02; 30%; 100%);
    }
  }
}
.store-display-options {
  .store-search {
    .well {
      margin-bottom: 10px;
    }
    .form-group {
      margin: 0;
      width: 100%;
    }
    @media (min-width: @screen-sm-min) {
      input {
        width: 100%;
      }
      button, input {
        margin: 0;
      }
    }

    @media (max-width: @screen-xs-max) {
      button, input {
        margin: 0;
        display: inline-block;
      }
    }
    #searchbox {
      margin-bottom: 15px;
    }
  }

  .store-filter {
    .make-md-column(8);
    .make-xs-column(12);

    .text-right;
    @media (max-width: @screen-sm-max) {
      .text-left;
    }
  }

  input {
    .placeholder(@muted-text);
  }

  .form-group {
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      padding-bottom: 10px;
    }
    @media (min-width: @screen-md-min) {
      margin-left: 20px;
    }

    @media (max-width: @screen-xs-max) {
      display: inline-block;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      margin-left: 0;
      .btn-sm {
        padding-left: 7px !important;
        padding-right: 7px !important;
      }
    }
  }
  label {
    font-weight: normal;
    .small;
    color: @muted-text;
  }
}
.price {
  margin:20px 0 20px 0;
  line-height: 1.7em;
  font-size: 16px;

  .price_display
  {
    margin:0;
    font-size: 20px;
    font-family:@font-family-sans-serif;
    strong
    {
      display:inline-block;
      font-family:@font-family-sans-serif;
      font-weight:bolder;
    }
  }
  .price_status
  {
    margin:0;
    color: @orange-lighten-02;
    font-family:@font-family-sans-serif;
    letter-spacing: -0.3px;
    strong
    {
      display:inline-block;
      font-family:@font-family-sans-serif;
      font-weight:bolder;
    }
  }
  .available-formats
  {
    height:auto;
    overflow:hidden;
    .format{ float:none; }
  }
}
.modal-body .price
{
  .format{ float:left; }
}



.list-price {
  .small;
  color: @muted-text;

  .price-amount {
    text-decoration: line-through;
  }
}
.sku {
  color: @muted-text;
}
.tiq-list {
  h4 {
    margin-top: 2em;
  }
}

.featured-product {
  .make-xs-column(12);
  .make-sm-column(6);
  .make-md-column(6);
  .make-lg-column(4);
  .card {
    margin-bottom: 0 !important;
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
    &:nth-child(1), &:nth-child(1n + 3) {
      padding-right: 0;
    }
    &:nth-child(2n + 2) {
      .card-inner {
        padding-left: 0;
        clear: both;
      }
    }
  }
  @media (min-width: @screen-md-min) {
    &:nth-child(4n) {
      clear: both;

    }
  }
  .store-support,
  .archive-support {
    padding-top: 0;
  }
  .label-featured {
    font-size: 90%;
    top: 10px;
    left:0;
    position: absolute;
    z-index: 1;
    background-color: @orange-00;
  }
}

.default-product {
  .make-xs-column(12);
  .make-sm-column(4);
  .make-md-column(4);
  .make-lg-column(3);
  .card {
    img {
      max-height: 150px;
    }
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
    &:nth-child(3n+1) {
      clear: both;
    }
  }
  @media (min-width: @screen-lg-min) {
    &:nth-child(4n+1) {
      clear: both;
    }
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
    &:first-child, &:nth-child(3n + 1) {
      clear: both;
      padding-right:0;
    }
   &:nth-child(3), &:nth-child(3n + 3) {
      padding-left: 0;
    }
  }
}

.card {
  position: relative;
  .card-inner {
    background: @white-00;
    overflow: hidden;
    .box-shadow(0 1px 2px rgba(0,0,0,.15));
    margin-left:0;
    margin-right:0;

     padding: 15px;
    text-align: center;
    min-height: 310px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    display: inline-block;
    * {
      vertical-align: middle;
      text-align: center;
    }
    img {
      margin-bottom: 15px;
      margin-left: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      max-height: 145px;
    }
    h4.store-title {
      font-size: 17px;
      line-height: 1.1;
      padding-left: 0;
      padding-right: 0;
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        font-size: 17px;
      }
    }
    .product-type {
      font-size: 14px;
    }
  }
  &.card-notice {
    padding: 30px;
    background-color: @state-warning-bg;
    color: @state-warning-text;
    border: 1px solid @state-warning-border;
    p:last-child {
      margin-bottom: 0;
    }
    .close-wrapper {
      top:10px;
      right: 0px;
      margin-top:0;
      z-index:auto;
      .close {
        color: @state-warning-text;
        font-size: 20px;
      }

    }
  }
  &.card-notice.sm {
    padding: 10px;
  }
  /* event specific notifications */
  margin-bottom: @grid-gutter-width;
  .archive-description {
    height: 150px;
    overflow: scroll;
  }
  .store-photo-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .store-description-wrapper {
    .make-xs-column(12);
    padding-left:0;
    padding-right:0;
    text-align: center;
    .product-type {
      margin-bottom: 0;
      margin-top: 7px;
    }
  }
  .btn-view-details {
    .btn-default;
    .btn-sm;
    position:absolute;
    right:5px;
    bottom:5px;
    z-index: 2;
  }
  button {
    display: inline;
  }
}
.cards {

  .featured-product {
    margin-bottom: 15px;
    .card-inner {
      min-height: 348px;

      img {
        max-height: 200px;
      }
      h4.store-title {
        font-size: 20px;
      }
    }
  }
}

.modal-product-details {
  .product-title {
    font-size: 38px;
    line-height: 1.1;
    margin-bottom: 10px;
    margin-right: 40px;
    @media (max-width: @screen-xs-max) {
      font-size: 22px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      font-size: 32px;
    }
  }
  .product-type {
    font-size: 16px;
    color: @muted-text;
    margin-bottom: 1em;
    @media (max-width: @screen-xs-max) {
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .modal-dialog {

    @media (min-width: @screen-sm-min) {
      width: 90%;
      height: 50%;
      .modal-body {
        padding: @grid-gutter-width;
        text-align: center;
      }
    }
    @media (min-width: @screen-md-min) {
      max-width: 1150px;
      .modal-body {
        padding: @grid-gutter-width*2.5;
        text-align: center;
      }
    }
    .book-cover {
      vertical-align: middle;
      display: inline-block;
      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
      @media (min-width: @screen-sm-min) {
        width: 39%;
      }
      img {
        .img-responsive;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: @screen-sm-max) {
          max-width: 100%;
        }
        @media (max-width: @screen-xs-max) {
          max-width: 200px;
        }
      }
    }
    .product-info {
      display: inline-block;
      vertical-align: middle;
      @media (max-width: @screen-xs-max) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
      @media (min-width: @screen-sm-min) {
        width: 57%;
        padding-left: 5%;
      }


      .product-description {
        min-height: 70%;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 1.7;

        @media (max-width: @screen-xs-max) {
          padding-top: 14px;
          padding-bottom: 5px;
          display: block;
        }
      }
      .product-footer {
        padding-top: @grid-gutter-width;
        @media (max-width: @screen-xs-max) {
          display: none;
        }
        .moreinfo {
          .btn-default;
          .btn;
          float: right;
          position: absolute;
          bottom:0;
          right:0;
        }
      }
    }
  }
  .purchase-options {
    background: transparent;
    padding:0;
    margin-bottom: 0;
    .available-formats {
      .format {
        width: 49%;
        margin-right: 2%;
        &:nth-child(even) {
          margin-right: 0;
        }
      }
      .format-helper {
        @media (max-width: @screen-xs-max) {
          display: none;
        }
      }
    }
  }
}

// Inventory Control
.inventory-control {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: @screen-xxs-max) {
    flex-direction: column;
  }

  .product_format__wrapper {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .format {
      flex: 0 0 auto;
      margin: 0;
      width: 100% !important;
      float: none !important;
    }

    .stock_status__wrapper {
      flex: 0 0 auto;
      p {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 5px;
      }
    }
  }
}







@media (min-width: @screen-sm-min) {
  .modal {
    .book-cover img {
      max-height: auto !important;
      max-width: 100% !important;
    }
  }
}

.btn-primary-call {
  clear: both;
  @media (max-width: @screen-xs-max) {
    font-size: 16px;
  }
}
.buttons {
  clear: both;
  .make-xs-column(12);
}

// ----------------------------------------------- Product Enhancement Pages

.enhanced-product {
  .promotions {
    display: none;
  }
  #site-canvas {
    background-color: @muted-background;
    background-size: cover !important;
  }
  .store-support {
    @media (min-width: @screen-md-min) {
      .store-photo-wrapper {
        .make-sm-column(5);
        padding-right: @grid-gutter-width;
      }
      .store-description-wrapper {
        .make-sm-column(7);
        padding-left: @grid-gutter-width;
      }
    }
  }
  .podcast-container {
    background-color: @white-00;
    padding: @grid-gutter-width*2;
    .box-shadow(0 0 30px rgba(0,0,0,.3));
    margin-top: @grid-gutter-width*2;
    margin-bottom: @grid-gutter-width*2;
    @media (max-width: @screen-xs-max)
    {
      padding:15px;
    }

  }
  .header-support {

    @media (max-width: @screen-xs-max)
    {
      margin:0;
      padding:0;
    }

    // text-align: center;
    &:after {
      display: none;
      border: none !important;
    }
    .article-title-wrapper {
      float:none
    }
    .article-title {
      font-size: 4em;
      @media (max-width: @screen-xs-max)
      {
        font-size:2em;
      }
    }

  }
  .main-content-support {
    &:before {
      display: none;
    }
    padding-bottom: 0;
  }
  .main-content-wrapper {
    .make-sm-column(12);
    p.lead {
      margin-top: 1em;
    }
  }
  .store-support, .archive-support, .main-content-wrapper {
    padding-top: 0;
    margin-top: 0;
  }
  .sidebar-wrapper {
    .make-sm-column(12);

    .sidebar-nav {
      margin-bottom: 0;
      background: transparent;
      #gradient>.vertical(@muted-background, @white-00);
      border-top: 1px solid @border-color;
      border-left: none;
      border-right: none;
      border-bottom: none;
      margin-left: -@grid-gutter-width*2;
      margin-right: -@grid-gutter-width*2;
    }
  }
  .purchase-options {
    background-color: transparent;
    .box-shadow(none);
    padding:0;
    .btn-primary-call {
      line-height: 52px;
      height: 52px;
      padding-top: 1px;
      padding-bottom: 0;
      font-size: 18px;
    }
  }
  .product-image-wrap {
    position: relative;
    strong {
      display: block;
      text-align: center;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    img
    {
      @media (max-width: @screen-xs-max)
      {
        max-height:235px;
      }
    }
  }

}

.enhanced-product
{
  .store-description-wrapper
  {
    padding-bottom:50px;
  }

  #subnav-items {
    background: @white-darken-01;
  }
  @media(min-width:@screen-sm-min)
  {
    .nav-stacked.nav-pills > li {
      display:inline-block;
      a:before {
        display:none;
      }
    }
  }

  &.lessons-for-life-the-collection {
    #site-canvas {
      background: url('/static/store/enhanced-lessons-for-life.jpg') fixed no-repeat top left !important;
    }
  }

  &.reading-plan #site-canvas
  {
    background:#252626 !important;
  }
  &.sharable-bible #site-canvas
  {
    background: #bbbbbb url('/static/store/enhanced-sharable-bible.jpg') fixed no-repeat top left !important;
    background-size: contain !important;
    .podcast-container
    {
      background:rgba(255, 255, 255, 0.75);
    }
  }
  &.american-gospel #site-canvas
  {
    background: #dddddd url('/static/store/enhanced-american-gospel.jpg') fixed no-repeat top left !important;
    background-size: contain !important;
    .podcast-container
    {
      background:rgba(255, 255, 255, 0.75);
    }
  }
  &.my-times #site-canvas
  {
    background: #eaedf2 url('/static/store/enhanced-my-times.jpg') fixed no-repeat top left !important;
    background-size: contain !important;
  }
  &.pray-big #site-canvas
  {
    background: #d56c3f url('/static/store/enhanced-pray-big.jpg') fixed no-repeat top left !important;
    background-size: contain !important;
  }
  &.miracle-of-christmas #site-canvas
  {
    background: #013343 url('/static/store/enhanced-miracle-of-christmas.jpg') fixed no-repeat top left !important;
    background-size: contain !important;
  }
}


.featured-products {
  padding-top: @grid-gutter-width/2;
}

.store-search {

  button.btn-transparent {
    position: absolute;
    top:38px;
    right:15px;
    outline: none;
    border-color: transparent;

    &:active, &:link {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      -webkit-tap-highlight-color: transparent;
      outline: none;
      box-shadow: none;
      > i {
        color: @text-color;
      }
    }
    > i {
      color: @muted-text;
    }
  }
  #searchbox {
    position: relative;
    padding-right: 30px;
  }
  .main-content-wrapper {
    .make-sm-column(9);
    .make-lg-column(10);
  }
  .sidebar-wrapper {
    .make-sm-column(3);
    .make-lg-column(2);
  }

  .search-filters {
    .make-sm-column(3);
    .make-lg-column(2);
    @media (min-width: @screen-sm-min) {
      padding-right: 30px;
    }

    label strong {
      color: @text-color;
    }
  }

  .search-results {
    @media (max-width: @screen-xs-max) {
      padding-left: 0;
      padding-right:0;
    }
    .make-sm-column(9);
    .make-lg-column(10);
  }

  #types_clear_div
  {
    width:auto;
    margin-bottom:10px;
    @media (max-width: @screen-xs-max)
    {
      float:right;
    }
  }

  .checkboxes {
    @media (max-width: @screen-xs-max) {
      margin-bottom:0;
    }
    label {
      margin-bottom: 5px;
      color: @muted-text;
      padding-left: 26px;
      padding-top: 1px;
      position: relative;
      cursor: pointer;
      input[type=checkbox] {
        position: absolute;
        top: -9999px;
        left: -9999px;
        .opacity(0);

      }
      &.checked {
        &:after {
          z-index: 2;
          .ti;
          content: "\$";
          display: block;
          height:20px;
          width:20px;
          position: absolute;
          left:0;
          top:0px;
          text-align: center;
          font-size: 20px;
          color: @lime-00;
        }
      }
      &:before {
        .ti;
        content: "";
        display: block;
        height:20px;
        width:20px;
        position: absolute;
        left:0;
        top:0;
        text-align: center;
        font-size: 20px;
        display: block;
        background: @white-00;
        border:1px solid @border-color;
        border-radius:2px;
        .box-shadow(inset 0 1px 2px rgba(0,0,0,.15));
        z-index: 1;

      }
      strong {
        color: @black;
      }
      @media (max-width: @screen-xs-max) {
        font-size: 13px !important;
        margin-top:12px;
        margin-bottom:20px;
      }
    }
    margin-bottom: 10px;
    .clearfix;
  }
}

.filters {
  @media (max-width: @screen-xs-max) {
    label strong {
      > a {
        .btn;
        .btn-default;
        .btn-sm;
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    label strong {
      > a {
        color: inherit;
        text-decoration: none;
        cursor: default;
        > i {
          top: 2px;
          position: relative;
        }
      }
    }
  }
}

#filters_list {
  @media (max-width: @screen-xs-max) {
    line-height: 1;
    &.in, &.collapsing {
      display: block;
    }
  }
}

#perpage {
  height: 30px;
  width: 100px;
  padding: 0;
}
.label-perpage {
  display: block;
  margin-bottom: 15px;
}

/*** Product Reviews **************/

form.Review
{
  .Fields
  {
    display:block;
  }
  .Confirm
  {
    display:none;
  }
  textarea
  {
    width:100%;
    height:100px;
    margin-bottom:12px;
  }
  &.Confirm .Fields
  {
    display:none;
  }
  &.Confirm .Confirm
  {
    display:block;
  }
}

.Reviews
{
  clear:both;
  padding:1em;

  .Menu
  {
    padding:1em;
    box-sizing:border-box;
    background:#f2f1ed;
  }
  .Review
  {
    border-bottom:1px solid #e5e5e5;
    margin-bottom:1em;

    .User, .Comment
    {
      padding:1em;
      margin:0;
      box-sizing:border-box;
    }
    .User
    {
      text-transform:capitalize;
      padding-bottom: 0;
    }
    .None
    {
      padding:1em;
      font-size:1.2em;
      font-style:italic;
      text-align:center;
    }


  }
}
.no-reviews .Reviews
{
  display:none;
}


.Reviews h3, .Products h3
{
  font-size: 18px;
  color: #988b82;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
}
.Reviews .btn, .Products .btn
{
  white-space: normal;
  display:block;
  margin:0 auto;
}

.Products .related-products, .Products .related-resources
{
  padding:1em;
  margin:0;
  box-sizing:border-box;
}
.Products .related-products .cards li
{
  float:none;
  width:100%;
}

@media(min-width:992px)
{
  .Reviews
  {
    overflow: hidden;

    .Menu
    {
      display:block;
      float:left;
      width:30%;
      padding:2em;
      border-radius:3px;
      box-sizing:border-box;
      background: @white-darken-02;
    }
    h4
    {
      margin-top:0;
    }

    .Listing
    {
      display:block;
      float:left;
      width:70%;
    }
    .Review
    {
      overflow: hidden;
      border:none;
      box-sizing:border-box;

      p
      {
        font-size:0.9em;
      }
      strong
      {
        display:block;
      }
      .User
      {
        float:left;
        width:30%;
        text-align:right;
      }
      .Comment
      {
        float:left;
        width:70%;
      }
    }
  }

  .Products
  {
    &.series-products {
      clear: both;
    }

    .related-products
    {
      float:left;
      width:30%;
      padding:1em;
    }
    .related-resources
    {
      float:left;
      width:70%;
    }
    &.no-related .related-resources
    {
      width:100%;
    }
  }
}

/* Wishlist */
.store-wishlist {
  .wishlist__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .wishlist__product {
      flex: 1 1 auto;
      width: 100%;
      display: flex;
      border-top: 1px solid @white-darken-03;
      display: flex;
      flex-direction: column;
      align-content: stretch;
      padding: 10px 0;

      .wishlist__product_data {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10px;

        .wishlist__product_image {
          width: 100px;
          flex: 0 0 auto;
          a {
            height: 100%;
            display: block;
            img {
              width: 100%;
              max-height: 150px;
            }
          }

        }

        .wishlist__product_title {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          a {
            flex: 1 1 auto;
            font-weight: bold;
          }
          .wishlist__product_date {
            flex: 1 1 audo;
            font-size: 14px;
            color: @text-color;
          }

          .product-unavailable {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 0;
          }

        }

        .wishlist__product_actions {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;;
          align-items: flex-end;

          .wishlist__product_metadata {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-end;

            .wishlist__product_price {
              font-size: 18px;
            }

          }
          .wishlist__product_buttons {
            flex: 1 1 auto;
            display: flex;
            gap: 5px;
            button {
              width: 110px;
            }
          }
        }
      }

      .stock_status__wrapper {
        padding-top: 5px;
        p {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      .wishlist__product {
        .wishlist__product_data {
          flex-wrap: wrap;

          .wishlist__product_actions {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: @screen-xxs-max) {
      .wishlist__product {
        .wishlist__product_data {
          .wishlist__product_image {
            width: 100%;
            align-items: center;
            a {
              text-align: center;
              img {
                width: 100px;
              }
            }
          }

          .wishlist__product_title {
            text-align: center;
          }

          .wishlist__product_actions {
            align-items: center;
          }
        }
        .stock_status__wrapper {
          text-align: center;
        }

      }
    }

  }


  @media print {
    #quick-add-form {
      display: none;
    }
    #nav-links-bottom {
      display: none;
    }
    .checkbox-cell {
      display: none;
    }
    .miniNav {
      display: none;
    }
    .main-content-wrapper {
      width: 100%;
    }
    .wishlist__product_url {
      width: 100%;
      display: block !important;
      visibility: visible !important;
    }

    .stock_status__wrapper {
      display: none;
    }
  }
}
