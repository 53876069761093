body.mobile-custom-tab-ios {
  #smartbanner,
  #adslice,
  .or-sign-in,
  .social-share,
  .mobile-sign-in,
  .nav-utility-outer-wrapper,
  .base-utility-outer-wrapper,
  footer,
  .nav-primary-wrapper,
  .toggle-nav-wrapper {
    display: none !important;
  }

  .brand-link {
    cursor: default;
    pointer-events: none;
  }

  // Social login should use in-app buttons not buttons on web.
  .social-media-buttons {
    display: none !important;
  }
}
