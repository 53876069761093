section.Subscribe.Daily
{
    padding:3rem 2rem 0 2rem;
    background: @muted-background;
}
section.Subscribe.Daily .container
{
    max-width:800px;
}
section.Subscribe form input {
    // display:block;
    // width:100%;

    // border:none;
    // padding:10px;

    // color: @black;
    // text-align:left;
}
section.Subscribe form input[type='email'] {
    // -moz-box-shadow:    inset 0 1px 10px #aaa;
    // -webkit-box-shadow: inset 0 1px 10px #aaa;
    // box-shadow:         inset 0 1px 10px #aaa;
    // border-radius: @border-radius-base;
    // color: @muted-text;
    .form-control;
}
section.Subscribe form input[type='submit'] {
    .btn;
    .btn-primary;
    margin-top:1rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}
section.Subscribe form input[type='submit']:hover {
    background-image: linear-gradient(to bottom, @green-lighten-01 0, @support-color 100%);
}
section.Subscribe ul.hs-error-msgs {
    margin:0;
    padding:10px;
    border:none;
    background: @blue-lighten-01;
    list-style-type:none;
    text-align:left;
    line-height:1.1em;
}
section.Subscribe ul.hs-error-msgs *
{
    font-weight:lighter;
    color: @white-00;
}
section.Subscribe .hs_error_rollup ul.hs-error-msgs, section.Subscribe .hs-form-required
{
    display:none !important;
}
/*****************/
section.Subscribe .Phone
{
    position:relative;
    width:300px;
    height:400px;
    margin:0 auto;
    overflow:hidden;
}
section.Subscribe .Screen
{
    position: absolute;
    top: 25%;
    left: 9%;
    width: 82%;
    height: 108%;
    background: @white-00;
    overflow-y: scroll;
}
@media(max-width:375px)
{
    section.Subscribe .Phone
    {
        width:250px;
        height:312px;
    }
}