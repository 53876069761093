// ------------------------------------------------------------------------- Home - Refresh

.resource-reference
{
  display:table;
  width:100%;
  margin:45px 0 45px 0;
}
.resource-reference_minor
{
  position:relative;
  padding:0 0 0.5em 0;
  margin:0;
  border-bottom:2px solid @border-color;
  color:@taupe-darken-03;
  font-size:20px;
  font-weight:100;
  text-transform:uppercase;
  line-height:1.1em;

  strong
  {
    font-weight:900;
    display:inline-block;
  }
}
.resource-reference_major
{
  padding:0;
  margin:8px 0 0 0;
  font-size:32px;
}
.resource-reference_label
{
  display:table-cell;
  width:100%;
  vertical-align: middle;
}
.resource-reference_icon
{
  display:table-cell;
  width:auto;
  vertical-align: middle;
  white-space:nowrap;

  color:@taupe-darken-03;
  font-size:16px;
  text-transform: none;
  font-weight:900;
}
.resource-reference_icon img
{
  width:24px;
  height:24px;
  padding:4px;
}
.resource-reference_thumbnail
{
  width:100%;
  padding-bottom: 55%;

  background-size: cover;
  background-color: @border-color;
  background-position: center center;
  background-repeat: no-repeat;

  border-radius:1em;
}

.resource-reference--large
{
  .resource-reference_minor
  {
    font-size:24px;
  }
  .resource-reference_major
  {
    font-size:48px;
  }
  .resource-reference_icon
  {
    font-size:24px;

    img
    {
      width:26px;
      height:26px;
    }
  }
}

.resource-reference--small
{
  .resource-reference_minor
  {
    border:none;
    margin-bottom:0;
    font-size:16px;
  }
  .resource-reference_major
  {
    font-size:24px;
  }
}

.resource-reference--image
{
  margin-top:0;

  .resource-reference_minor
  {
    margin-top:-0.8em;
  }
  .resource-reference_major
  {
    position:relative;
    margin:0;
  }
  .resource-reference_icon
  {
    display:inline-block;
    margin-left:0.5em;
    margin-bottom:5px;

    background:white;
    border-radius:33px;

    font-size:14px;

    img
    {
      width:30px;
      height:30px;
    }

  }
}

@media(max-width: @screen-md-max)
{
  .resource-reference--image
  {
    .resource-reference_major
    {
      font-size:15px;
    }
    .resource-reference_minor
    {
      font-size:13px;
    }
  }
}

@media(max-width: @screen-xs-max)
{
  .resource-reference
  {
    margin:2em 0 1em 0;
  }

  .resource-reference_minor
  {
    font-size:1.1em;
  }
  .resource-reference_major
  {
    font-size:1.3em;
  }

  .resource-reference--large
  {
    .resource-reference_minor
    {
      font-size:1em;
    }
    .resource-reference_major
    {
      padding:0;
      font-size:1.5em;
    }
    .resource-reference_icon
    {
      img
      {
        width:22px;
        height:22px;
      }
    }
  }

  .resource-reference--image
  {
    margin:0 0 40px 0;

    .resource-reference_thumbnail
    {
      width:35%;
      float:left;
      padding-bottom: 22%;
      border-radius:8px;
    }
    .resource-reference_major
    {
      width:65%;
      float:right;
      padding-left:17px;
    }
    .resource-reference_minor
    {
      width:65%;
      float:right;
      margin-top:0;
      padding-left:17px;

      strong
      {
        display:block;
      }
    }
    .resource-reference_icon
    {
      display:inline-block;
      position:absolute;
      right:auto;
      left:-13px;
      top:0.5em;
      vertical-align: top;
      margin:0;
    }
  }
}

.container--program
{
  padding:0;
  color:@taupe-darken-03;
}
.container--daily
{
  padding:0;
  margin-top:35px;
  color:@taupe-darken-03;
}
.programDetails_description
{
  font-size:1.4em;
}
.programDetails_bible
{
  display:inline-block;
  color:@taupe-darken-03;
  font-weight:bolder;
}
@media(max-width: @screen-xs-max)
{
  .container--daily
  {
    padding-top:2em;
    border-top:1px solid @white-darken-05;
  }
  .programDetails_description
  {
    font-size:1em;
  }
}


// ------------------------------------------------------------------------- Home - signup
#dailySignUp
{
  max-width:380px;
  margin:0 auto;
  color: @taupe-darken-03;

  @media(max-width:@screen-xs-max)
  {
    margin-bottom: 25px;
    max-width: none;
  }

  h3 {
    margin-top: 0px;
  }

  form.hs-form > *
  {
    width:100%;
    display:table-cell;
  }
  form.hs-form .hs_email.hs-email > label, form.hs-form .hs_error_rollup
  {
    display:none;
  }
  .hs_email.hs-email input[type='email'], .hs_submit.hs-submit input[type='submit']
  {
    display:block;
    width:100%;
    border:none;
    border-radius:4px;
    padding:10px 20px 10px 20px;
    font-size:14px;
  }
  .hs_email.hs-email input[type='email']
  {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2) inset;
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2) inset;
    -webkit-appearance: none;
  }
  .hs_submit.hs-submit input[type='submit']
  {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background:@green-00;
    color:white;
    text-transform:uppercase;
    font-weight: bolder;

    &:hover
    {
      background: @green-lighten-01;
    }

    form.hs-form ul.hs-error-msgs {
      list-style: none;
      color:@red-00;
      font-weight:bolder;
      font-size:14px;
    }
  }
}
// ------------------------------------------------------------------------- Home - HubBox

.tphub {
  margin-bottom: 25px;
  border-radius: 16px;
  overflow: hidden;
  background-color: @white-darken-02;

  &__icons svg {
    height: 24px;
    width: 24px;
  }

  &__icons--enlarge svg {
    height: 24px;
    width: 24px;
    
    path {
      transform: scale(1.4);
      transform-origin: center center;
    }
  }

  .tphub__body {
    padding: 20px 10px 16px 16px;

    .tphub__header {
      width: 100%;
      padding-bottom: 12px;

      .tphub__welcome {
        color: @gray-00;
        font-family: @font-family-sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
        letter-spacing: 0;
      }

      .tphub__title {
        color: @taupe-darken-03;
        font-family: @font-family-sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 0;

        strong {
          font-weight: 900;
        }
      }
    }

    .tphub__thumbnail {
      text-align: center;
      width: 100%;
      display: block;
      margin-bottom: 25px;
      position: relative;

      .tphub__thumbnail_img {
          border-radius: 16px;
          display: inline-block;
          width: 100%;
          position: relative;
      }
      .tphub__thumbnail_overlay {
            background-image: url('/static/images/responsive/motm-overlay.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
      }
    }

    .tphub__links {
      width: 100%;
      padding-bottom: 10px;
      a {
        display: flex;
        white-space: nowrap;
        font-size: 16px;
        line-height: 28px;
        white-space: nowrap;
        color: @taupe-darken-01;
        font-family: @font-family-sans-serif;
        padding-bottom: 7px;
        text-indent: 5px;

        .tphub__icons--enlarge {
          height: 24px;
          width: 24px;
          margin-right: 5px;
          svg {
            margin: 2px 5px 2px 0;
          }
          .tphub__icon_background {
            fill: transparent;
          }
        }
      }
    }
  }

  .tphub__footer {
    background-color: @white-darken-01;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 16px 24px 16px 30px;
    justify-content: flex-start;
    flex-wrap: wrap;
    a {
        flex: 0 1 auto;
        display: flex;
        padding-right: 24px;
        align-content: center;
        white-space: nowrap;
        font-size: 16px;
        line-height: 28px;
        color: @taupe-darken-02;
        font-family: @font-family-sans-serif;

        .tphub__icons {
          margin: 0 5px 0 0;
        }
    }
  }

  @media (min-width: @screen-sm-min) {
    .tphub__body {
      padding: 20px 24px 16px 30px;

      .tphub__header {
        width: 60%;
        float: left;
      }
      .tphub__thumbnail {
        width: 40%;
        float: right;
        text-align: right;
        margin: 0;

        .tphub__thumbnail_img {
          max-width: 215px;
        }
      }
      .tphub__links {
        width: auto;
        float: left;
      }
    }
  }
}

// ------------------------------------------------------------------------- Home - Podcast
.podcast-details-support {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: @screen-md) {
    flex-direction: row;
  }
}
.index .media-player-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  @media (min-width: @screen-md) {
    flex: 50%;
    max-width: 50%;
    margin-left: 15px;
    margin-right: 0;
    padding-right: 15px;
  }
}
.index .podcast-description-wrapper {

  margin-left: 15px;
  @media (min-width: @screen-md) {
    flex: 50%;
    padding-left: 34px;
  }

  .lead {
    margin-right: 15px;
  }

  @media (max-width: @screen-md-max) {
    .lead {
      font-size: 1.5em;
    }
  }
  @media (max-width: @screen-xs-max) {
    .lead {
      font-size: 1.3em;
    }
  }
  @media (min-width: @screen-lg-min) {
    .lead {
      font-size: 1.7em;
    }
  }
  .scripture-reference {
    border-left:0;
  }
  @media (max-width: @screen-xs-max) {
    margin-top: @line-height-computed;
    .scripture-reference {
      margin-bottom: 20px !important;
    }
  }
  @media (max-width: @screen-sm-max) {
    .lead {
      margin-top:0;
    }
  }
}

.index .podcast-container {
  padding-bottom: 0;
}



// ------------------------------------------------------------------------- Home - Current Series
.index .current-series-toggle-wrapper {
  border-bottom: 1px solid @border-color;
}
.index .hidden-home {
  display: none !important;
}
.podcast-container-home {
  .by-line-wrapper {
    .make-xs-column(12);
    .make-sm-column(5);
  }
  .distribution-options-wrapper {
    .make-xs-column(12);
    .make-sm-column(7);
    @media (max-width: @screen-xs-max) {
      text-align: left;
      .btn {
        margin-top: 8px;
      }
    }
    .btn-subscribe-options {
      .btn-sm {
        @media (min-width: @screen-sm-min) {
          // padding-top: 3px;
          // padding-bottom:3px;
          padding-left: 5px;
        }
        .caret {
          @media (min-width: @screen-sm-min) {
            top: -1px;
            position: relative;
          }
        }
      }
    }
    .ti-rss {
      @media (min-width: @screen-sm-min) {
        height: 20px;
        width: 20px;
      }

    }
  }
}
.current-series-toggle-support {

  .current-series-toggle-wrapper {
    text-align: center;
    a {
      color: @sermon-link;
      font-size: 1.15em;
      font-weight: 400;
      .current-series-btn {
        background-color: @muted-background;
        display: inline-block;
        padding: 12px 18px;
        border-radius: 10px 10px 0 0;
        span {
          color: @muted-text;
        }
      }
      [class^="ti-"], [class*=" ti-"] {
        .svg-position-fix;
      }
      // font-family: @font-family-secondary;
      #gradient.vertical(@white-00; @white-darken-01; 0%; 100%);
      padding: 10px 20px 0;
      display: block;
      @media (min-width: @screen-lg-min) {
        padding: 10px 20px 0;
      }
      text-align: center;
      // text-transform: uppercase;
      // letter-spacing: 1px;
      text-decoration: none;
      margin-bottom: 0;
      border-radius: 3px;
      [class^="ti-"] {
        fill: darken(@muted-background, 20%);
      }
      .ti-headphones {
        margin-right: 4px;
      }
      .ti-chevron-down {
        font-size: 14px;
        margin-left: 3px;
      }
      strong {
        font-weight: inherit;
      }

      &:hover {
        text-decoration: none;
        background-color: darken(@muted-background, 4%);
      }
    }

    .ti-caret-up {
      display: none;
    }
  }
  &.inner-toggle {
    clear: both;
    .current-series-toggle-wrapper {
      a {
        background: @muted-background;
        display: inline-block;

        padding: 10px 20px 13px;
        margin-left:auto;
        margin-right:auto;
      }
    }
  }
}
.openSeries {
  .current-series-toggle-wrapper {
    border: none;
  }
}
#index .current-series-outer-wrapper {
  padding: (@line-height-computed * 2) 0;
}
.current-series-outer-wrapper {
  background: @muted-background;
  padding: @line-height-computed 15px;
  position: relative;
  display: none;

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 100%;
    border-radius: 0;
  }

  .view-all {
    font-family: @font-family-sans-serif;
    font-size: @font-size-small;
    .kern-wide;
    color: @muted-text;
    display: none;
  }

  .title {
    color: @primary-color;
  }
}
.current-series-support {
  .make-row();
}
.current-series-details-support {
  .make-row();
}

/**************** broadcast related series links***********************************************/

/* area directly under video player */
.Info
{
  padding: 8px 0;
  .Credit
  {
    float:right;
  }
}
/* two-column related sermons section under broadcast */
.Related
{
  #gradient.vertical(@white-00; @white-darken-01; 0%; 100%);
  border-bottom:1px solid @border-color;
  .Column
  {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: @screen-md-min) {
      &.leftside {
        padding-right: 10px;
        padding-left: 0;
      }
      &.rightside {
        padding-left: 10px;
        text-indent: 25px;
        padding-right: 0;
      }
    }
  }
  .section-title
  {
    padding-top:30px;
    border-top: 1px solid @border-color;
  }
  .Title
  {
    display:inline-block;
    margin-top:0;
    margin-bottom:8px;
    color: @support-color;
  }
}




// ------------------------------------------------------------------------- Home - Devotional & Blog

.devotional-blog-outer-wrapper {
  border-bottom: 1px solid @border-color;
  p, address, li, cite {
    font-size: 18px;
  }
}
.devo-banner {
  margin-bottom: 10px;
  .devotional-favorite {
    text-align: right;
    margin: 5px 0;
    width: 100%;
  }
}
.devotional-blog-support {
  .make-row();
  border-top: 1px solid @border-color;
}

.devotional-wrapper {
  @media (max-width: @screen-sm-max) {
    border-bottom: 1px solid @border-color;
  }
  @media (min-width: @screen-md-min) {
    border-right: 1px solid @border-color;
  }
}

.devotional-wrapper,
.blog-wrapper {
  .make-md-column(6);
  padding-top: @line-height-computed;
  padding-bottom: @line-height-computed;
  .clearfix;
  min-height: 388px;
  .title {
    padding-bottom: (@line-height-computed * .35);
    a {
      color: @primary-color;
    }
  }

  @media (max-width: @screen-xs-max) {
    clear: both;
  }
}

.devotional-wrapper {
  padding-right: 20px;
  .title {
    padding-bottom: (@line-height-computed * .35);
  }
  .img-featured {
    .pull-left;
    max-width: 31.3%;
    margin-right: 25px;
    margin-bottom: 10px;

    img {
      .img-responsive;
      .pull-left;
    }
  }
}

.blog-wrapper {
  .extra-padding-left;
  h5 {
    margin-bottom: 0;
  }
  .home-blog-featured-image {
    max-width: 50%;
    margin-left: 25px;
    height: auto;
    max-height: 187px;
  }
  .lead {
    font-family: @font-family-base;
    letter-spacing: 0;
    line-height: 1.725;
  }
}
@media (max-width: @screen-xs-max) {
  .devotional-wrapper,
  .blog-wrapper {
    padding-right: 15px;
  }
}

// ------------------------------------------------------------------------- Home - Promotional

.promotional-outer-wrapper {
  margin-top: 3px;
  border-top: 1px double @border-color;
  padding-top: (@line-height-computed * 2);
  padding-bottom: (@line-height-computed * 2);
  .text-center;

  img {
    .center-block;
  }

  a {
    position: relatieve;
    cursor: pointer;
    .transition(~"all 0.2s ease-in-out");
  }
  a:hover:before {
    margin: 0 auto;
    .box-shadow(inset 0 0 15px fade(#000, 50%));
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 15px;
    margin-right: 15px;
    @media (max-width: @screen-xs-max) {
      max-width: 399px;
      margin: 0 auto;
    }
  }
}

.promotional-support {
  .make-row();
}
.promotion-wrapper {
  .make-sm-column(4);

  @media (max-width: @screen-xs-max) {
    margin-bottom: @line-height-computed;
  }
}
.podcast-title-support {
  position: relative;
  .section-title {
    margin-bottom:0;
  }
  .article-title {
    font-size: 2.1em;
    margin-top: .25em;
    margin-bottom: .55em;
    @media (max-width: @screen-xs-max) {
      font-size: 1.8em;
      margin-bottom: .25em;
    }
  }
}
.index .social-share-links {
  margin-right: 45px;
}
.split-content-support {
  .make-row();

  .split-content-wrapper-first {
    .make-md-column(8);
  }
  .split-content-wrapper-second {
    .make-md-column(4);
  }
}

.devotional-disclaimer {
  .well;
  .small;
  color: @muted-text;
}


// ------------------------------------------------------------------------- Home - explore sliders
.slider-group
{
  border-top:2px solid @taupe-lighten-04;
  margin:0 -15px 0 -15px;
  padding: 30px 0 30px 0;

  @media(max-width:@screen-xs-max)
  {
    padding:25px 0 30px 0;
  }

  .slider-group_upper
  {
    display:block;
    padding:0 10px 0 10px;
  }
  .slider-group_lower
  {
    position:relative;
  }
  .slider-group_title
  {
    display:table-cell;
    padding:15px 15px 0 15px;
    width:100%;

    h3
    {
      margin:0;
      color: @primary-title;
      font-size:32px;
      @media(max-width:@screen-md-max)
      {
        font-size:20px;
      }
    }
    p
    {
      margin:0;
      color:@grey-lighten-01;
      font-family:@font-family-sans-serif;
      font-weight:600;
      font-size:14px;
      text-transform:uppercase;
    }

  }
  .slider-group_details
  {
    display:table-cell;
    padding:15px 15px 0 15px;
    white-space: nowrap;

    p
    {
      white-space: normal;
      width:205px;
      margin-bottom:16px;

      color:@taupe-darken-02;
      font-weight:700;
      font-size:14px;
      line-height:24px;
    }

    p.actionable
    {
      color:@taupe-darken-01;
      text-align:center;
      font-weight:900;
    }

  }
  .slider-group_cta
  {
    display:inline-block;
    padding:3px 10px 3px 10px;
    border-radius:5px;
    .tfl-btn-primary;
    text-transform:uppercase;
    font-weight:900;
    font-size:12px;
  }
}


[data-profile-field]
{
  cursor:pointer;
  text-align:center;
  color: @taupe-darken-03;
  font-weight:bolder;
  font-size:14px;
  line-height:24px;

  /*checkbox icon*/
  &:before
  {
    content:"\4e";
    display: inline-block;
    vertical-align: middle;
    width:20px;
    height:20px;
    border:2px solid @warm-gray-lighten-03;
    border-radius:2px;
    margin-right:10px;
    background:@white-darken-01;
    color:@white-darken-01;
    font-family:@font-family-icon;
    line-height:15px;
  }
  /*label*/
  span
  {
    display:inline-block;

  }
}

[data-profile-updating]
{
  [data-profile-field]:before
  {
    border-color:darkblue;
    background:blue;
    color:white;
  }
}


// Create account call to action after subscribing to email
.create-account-message {
  display: none;
  p:first-child {
    font-weight: bold;
    margin-bottom: 12px;
  }

  a {
    button {
      border: none;
    }

    .create-account-btn {
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      width: 164px;
      height: 36px;

      &:hover {
        transition: all 0.3s;
      }
    }

    .sign-in-btn {
      background: none;
      text-decoration: underline;
      font-size: 16px;

      &:hover {
        transition: all 0.3s;
      }
    }
  }
}

.daily.create-account-message {
  p:first-child {
    color: @green-00;
  }

  a {
    .create-account-btn {
      background-color: @taupe-darken-01;
      color: @white-00;

      &:hover {
        background: @taupe-00;
      }
    }

    .sign-in-btn {
      color: @taupe-darken-01;

      &:hover {
        color: @taupe-00
      }
    }
  }
}

.footer.create-account-message {
  a {
    .create-account-btn {
      background-color: @blue-lighten-06;
      color: @blue-darken-02;

      &:hover {
        background: @blue-lighten-07;
      }
    }
    .sign-in-btn {
      color: @blue-lighten-06;

      &:hover {
        color: @white-00;
      }
    }
  }
}


// Create account call to action after subscribing to email and donating
.thankyou-note {
  display: flex;
  flex-wrap: wrap;

  .create-account-message-donation {
    background: @white-darken-01;
    margin: 16px 0px;
    border: 1px solid @white-darken-02;
    border-radius: 4px;
    padding: 20px;
    p,
    a {
      font-size: 16px;
    }

    p:first-child {
      font-weight: bold;
      margin-bottom: 12px;
    }

    a .create-account-btn {
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      width: 164px;
      height: 36px;
      background-color: @taupe-darken-01;
      color: @white-00;
      border: none;

      &:hover {
        background: @taupe-00;
        transition: all 0.3s;
      }
    }
  }
}
