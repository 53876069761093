section.signin {
  .form {
    margin-top: 1em;
    margin-bottom: 2em;
  }
  .janrainContent {
    padding-left: 0 !important;
  }
  #janrainEngageEmbed {
    margin-top: .2em;
    padding-bottom: .4em;
    .janrainHeader {
      height: auto !important;
      div {
        font-weight: bold !important;
        font-family: @font-family-slab !important;
        font-size: .9em !important;

      }
    }
  }
  #janrainProviderPages {
    padding-left: 0 !important;
    left: auto !important;
    ul {
      > li {
        border: 1px solid #ddd !important;
        background: #fff !important;
        background-image: none !important;
        border-radius: none !important;
        height: auto !important;
        &:hover {
          border-color: #214E60 !important;
          border-radius: none !important;
        }
        > a {
          padding-left: 3px !important;
          padding-bottom: 3px !important;

        }
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  section.signin {
    .janrainContent {
      overflow: visible !important;
      width: 100% !important;
    }
    #janrainProviderPages {
      padding-left: 0 !important;
      left: auto !important;
      width: 100% !important;
      ul {
        width: 100%;
        > li {
          height: 44px !important;
          width: 100% !important;
          > a {
            padding: 6px !important;
            width: 100% !important;
          }
        }
      }
    }
  }
  // .main-content-wrapper {
  //   max-width: 450px;
  // }
}

@media (min-width: @screen-sm-min) {
  section.signin {
    #janrainEngageEmbed {
      border-left: 1px solid #eee;
      padding-left: 30px;
    }
  }
}
