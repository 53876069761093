/* ---------------------------------------------------- QAS Overrides */

// Override landing page specific rules that corrupt QAS display
.yearend2016 .ui-dialog {
  font-size: 14px;
}

.ui-widget-overlay {
  position: fixed !important;
  background: rgba(0,0,0,1) !important;

  div {
    max-width: 100%;
  }
}
.well.alert-danger {
    background: @alert-danger-bg !important;
    border: @alert-danger-border !important;
}
.ui-dialog {
  .box-shadow(0 0 100px rgba(0,0,0,.3));
  &.ui-widget {
    font-family: @font-family-base;
    max-width: 100%;
  }
  .ui-dialog-content {
    padding: 1em !important;
    overflow: auto;
  }
  .ui-dialog-titlebar {
    font-family: @font-family-secondary;
    font-size: 1.5em;

  }
  .ui-widget-content {
    border: none;
  }
  .ui-widget-header {
    background:  @brand-success;
    color: @white-00;
    border: none;
    padding: 8px 1em !important;
    border-radius: 4px 4px 0 0;
  }
  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    .alert;
    .alert-danger;
    background: @alert-danger-bg !important;
    font-size: @font-size-base;
    margin-bottom: 8px;
  }
  .QAS_RightDetails {
    border-left: none;
    .QAS_RightSidePrompt {
      color: @text-color;
    }
    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
      .btn;
      .btn-default;
      font-family: @font-family-base;
      padding: 5px 10px !important;

    }
  }
  .QAS_Prompt {
    border-left: none;
    .well;
    color: @taupe-darken-01;
    .QAS_RightSidePrompt {
      color: @text-color;
    }
    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
      .btn;
      .btn-primary;
      font-family: @font-family-base;
      padding: 5px 8px !important;
    }
    .QAS_PromptData {
      table {
        background-color: transparent;
      }
    }
  }

}

.qas-wait {
  &.ui-dialog {
    padding: 0;
    box-shadow: 0px 0px 41.31px 9.69px rgba(0, 0, 0, 0.17);

    .ui-widget-header {
      border-radius: 0;
    }
    .ui-dialog-titlebar {
      font-family: inherit;
      font-weight: 100;
      font-size: 30px;
    }
    .ui-dialog-title {
      margin: 40px auto;
      text-align: center;
      float: left;
    }
  }
  &.ui-corner-all {
    border-radius: 0;
  }
  &.ui-widget-content {
    border: none;
    background: none;
  }
}

@media (max-width: @screen-xs-max) {
  .ui-dialog {
    .ui-dialog-title {
      font-size: 20px;
      margin: 5px auto !important;
      float: none !important;
      text-align: center;
    }

    .QAS_Prompt,
    .QAS_RightDetails,
    .QAS_Picklist {
      float: none;
      width: 100%;

    }
    &.qas-wait {
      .ui-dialog-title {
        margin: 40px auto !important;
        float: left !important;
      }
    }
  }
}