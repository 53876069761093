@keyframes PulseYellow
{
  0%
  {
    color:#FC0;
    text-shadow: 0px 0px 0px #FC0, 0px 0px 1px #FFF;
  }
  100%
  {
    color:#fff;
    text-shadow: 0px 0px 8px #FC0, 0px 0px 3px #FC0;
  }
}
@keyframes PulseRed
{
  0%
  {
    color:#F30;
    text-shadow: 0px 0px 0px #F30, 0px 0px 1px #FFF;
  }
  100%
  {
    color:#fff;
    text-shadow: 0px 0px 8px #F30, 0px 0px 3px #F30;
  }
}
body[data-live-phase='Intro'] .LiveStream
{
  animation: PulseYellow 2s 2s infinite alternate both;
}
body[data-live-phase='Event'] .LiveStream
{
  animation: PulseRed 2s 2s infinite alternate both;
}

// colorized global notifications:
#adslice
{
  .make
  {
    display:block;
    position:relative;
    margin-top:-2px;
    margin-bottom:-2px;
    padding:3px;
    color:#fff;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);

    .btn
    {
      background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
      color: inherit !important;
    }
    &.red
    {
      background:#b00;
    }
    &.orange
    {
      background:#d17035;
    }
    &.yellow
    {
      background:#ffdf5d;
      color:#8b7d35;
    }
    &.blue
    {
      background:#0d2d3a;
    }
    &.tan
    {
      background:#bdac8d;
    }
    &.black
    {
      background:#222;
    }
    &.white
    {
      background:#fff;
      color:#1a3f4f
    }

  }
}
