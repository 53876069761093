/* Mixins */

.overlay-hover {
  .overlay {
    .opacity(0);
    position: absolute;
    top: 46%;
    left: 0;
    z-index: 2;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    width: 100%;
    line-height: 1.1em;
    .transition(all .3s);
    strong {
      display: block;
    }
    .size {
      font-size: 13px;
    }
  }
  .ti-cloud-download {
    position: absolute;
    .opacity(.8);
    top: 3px;
    left: 14px;
    z-index: 2;
    font-size: 46px;
    color: #eee;
    text-shadow: 0 0 10px rgba(0,0,0,.2);
  }
  &:hover {
    &:before {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top:0;
      left:0;
      background-color: rgba(0,0,0,.2);
    }
    .overlay {
      .opacity(1);

    }
    [class^="ti-"] {
      .opacity(1);
      color: #fff;
    }
  }
}

//
// -------------------------------------------- Checks

ul.checks {
  list-style: none;
  padding-left: 0;
  > li {
    padding-left: 20px;
    position: relative;
    &:before {
      .ti;
      content: "";
      background-image: url(/static/images/_svgs/check.svg);
      background-size: 20px 20px;
      fill: @muted-text;

      height: 20px;
      width: 20px;
      position: absolute;



      z-index: 2;

      margin-right: 4px;
      top: 2px;
      position: absolute;
      left:0px;
      color: @muted-text;
    }
    &.link {
      &:before {
        content: "\t";
        .ti;
        left: 0px;
        top: 1px;
        position: absolute;
      }
    }
  }
}


/* Ways to Give */
.ways-to-give {
  .section {
    margin-bottom: 2em;
    h3 [class^="ti-"] {
      color: lighten(@gray-light, 18%);
      font-size: 20px;
    }
    .ways-list {
      padding-left: 17px;
    }
    &#phone-mail {
      margin-bottom: 1em;
    }
    &#cfc {
      img {
        float: right;
      }
    }
    @media (max-width: @screen-xs-max) {
      &#cfc {
        img {
          float: none;

        }
      }
    }
  }

  address {
    padding-left: 30px;
    font-style: italic;
  }
  @media (max-width: @screen-xs-max) {
    address {
      padding-left: 0;

    }
  }
}





/* Email Lists */
#lists_form {
  .checkbox {
    margin-bottom: 4px;
    margin-top: 0;
  }
  .form-group {
    input {
      max-width: 100%;
    }
  }
  @media (min-width: @screen-sm-min) {
    .form-group {
      width: 100%;
      margin-bottom: 15px;
      input {
        width: 100%;
      }
    }
  }
  @media (min-width: @screen-md-min) {
    .form-group {
      max-width: 27.3%;
      margin-bottom: 0;
      input {
        min-width: 50%;
      }
    }
  }
}

.email-list-options {
  h5 {
    color: @link-color;
    font-size: .9em !important;
    line-height: 1.45;
    text-transform: uppercase;
  }
}

.form-group-btn {
  img {
    margin: 0 auto 1em;
  }
  .help-text {
    font-size: .85em;
    margin-bottom: 0;
    color: @gray;
  }
}

// Subscribe
// -------------------------------------------------------
.connect .main-content-wrapper {



  .section {
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #eee;
    margin-bottom: 1em;
    &:last-child {
      border-bottom: none;
    }
  }
  .panel {
    .box-shadow(none);
    padding:0;
  }
  [class^="ti-"] {
    margin-right: 4px;
    top: 2px;
    position: relative;
  }
  svg.ti-play, svg.ti-pause {
    fill: white;
  }
  li {
    margin-bottom:0!important;
  }
  .help-text {
    font-size: 1em;
    line-height: 1.4;
    margin-bottom: 5px;
    color: @muted-text;
  }

  .ti-mobile {
    margin-left: -3px;
    width: 18px;
    top: 3px;
    position: relative;
    display: inline-block !important;

  }
  .ti-tunein {
    color: #37B4A7;
  }
  .ti-audionow {
    color: #2296C9;
  }
  .ti-oneplace {
    color: #E66600;
  }
  .ti-googleplay {
    color: #333;
  }
  .ti-itunes {
    color: #1F87DA;
  }
  .ti-podcast {
    color: #6B3F95;
  }
  .ti-tablet {
    width: 18px;
    display: inline-block;
    position: relative;
    margin-left: -15px;
    top: 4px;
  }
  .ti-envelope {
    top:6px;
  }
}
.choices {
  .ti-roku {
    background-color: @roku;
    color: #fff;
    // height: 20px;
    display: inline-block;
    border-radius: 2px;
    top:2px;
    height: 24px;
    text-align: center;

    &:before {
      position: relative;
      top:-3px;
      left:1px;
    }
  }
}
.streaming-tv {
  .choices {
    .make-row();
    li {
      .make-sm-column(6);
    }
  }
}


// -------------------------------------------------------- Promotional Resources

.radio-info {
  position: relative;
  .contact-us {
    position: absolute;
    right:15px;
    top:20px;
  }

  .tab-content section
  {
    text-align:center;
  }

  .nav-tabs {
    margin-bottom: 0;
    text-align: center;
    @media (max-width: @screen-xs-max) {
      margin-top: @grid-gutter-width*1.5;
    }
    > li {
      float: none;
      display: inline-block;
      min-width: 25%;
      > a {

        text-transform: none;
        letter-spacing: 0;
        @media (min-width: @screen-sm-min) {
          font-size: 1.2em;
          padding-top: @grid-gutter-width/1.5;
          pading-bottom: @grid-gutter-width/1.5;
        }
      }
      &.active {
        > a {
          @media (min-width: @screen-sm-min) {
            background: lighten(@muted-background, 4%);
          }
        }
      }
    }
  }
  .ti-cloud-download {
    width: 34px;
    height: 26px;
    top: 4px;
  }
  .tab-content {
    padding: @grid-gutter-width 0;
    @media (min-width: @screen-sm-min) {
      padding: @grid-gutter-width @grid-gutter-width*2;
      #gradient.vertical(lighten(@muted-background, 4%), #fff);
    }
  }
  .help-block {
    margin:  0 auto 2em;
    text-align: center;
    font-size: 1em;
  }
  .panel {
    // .box-shadow(none);
  }
  ul {
    li {
      margin-bottom: 5px;
      line-height: 1.7em;
    }
  }
  h2 {
    margin-top: 1em;
    font-size: 2.5em;
    .btn {
      font-family: @font-family-base;
      font-size: 16px;
      [class^="ti-"] {
        color: @muted-text;
        margin-right: 3px;
        &:before {
          font-size: inherit;
        }
      }
    }

  }
  .btn {
    white-space: normal;
    // &.btn-default {
    //   #gradient.vertical(lighten(@muted-background, 4%), @muted-background);
    //   .box-shadow(inset 0 1px 0 rgba(255,255,255,.2));

    // }
    &.btn-primary {
      // #gradient.vertical(lighten(@brand-success, 6%), @brand-success);
      padding-top: 16px;
      padding-bottom: 16px;
      .box-shadow(inset 0 1px 0 rgba(255,255,255,.2));
      .subtext {
        font-size: 13px;
        color: rgba(255,255,255,.6);
        display: block;
        margin-top: 5px;
      }
    }
  }
  .well {
    h2 {
      color: inherit;
    }
    p {
      line-height: 1.7em;
    }
    .btn-primary {
      margin-bottom: 0em;
      font-size: 1.5em;
      strong {
        font-weight: normal;
        text-transform: uppercase;

      }
    }
    ul.checks {
      > li {
        &:before {
          top: 4px;
          left: -3px;
          fill: @muted-text;
        }
      }
    }
    .includes {
      clear: both;
      max-width: 40%;
      margin-left: 36%;

      text-align: left;
    }
    &.well-promo-pack {
      padding: 10px 5%;
      .btn-primary {
        width: 100%;
      }
    }
    .help-text {
      color: @muted-text;
      line-height: 1.5;
      clear: both;

    }
  }
  #promo-pack {
    .btn-primary {
      max-width: 350px;
    }
    ul.checks
    {
      max-width:300px;
      margin:0 auto;
      margin-top:1rem;
      text-align:left;
    }
  }
  #packs
  {
    text-align:center;
  }

  #photos {
    .panel {
      .make-sm-column(4);
      position: relative;
    }
    .btn-lg {
      width: 100%;
    }
    .portraits {
      @media (min-width: @screen-sm-min) {
        width: 28%;
        float: left;
      }

      padding: 0 @grid-gutter-width/2;
    }
    .landscapes {
      @media (min-width: @screen-sm-min) {
        width: 44%;
        float: left;
      }

      padding: 0 @grid-gutter-width/2;
    }
    .photo-wrap {
      position: relative;
      display: block;
      margin-bottom: @grid-gutter-width;
      .transition(all .3s);
      .overlay-hover;
      .ti-cloud-download {
        font-size: 36px !important;
      }
      img
      {
        display:block;
        position:relative;
        width:100%
      }
    }
  }
  #logos {
    img {
      width: 100%;
      height: auto;
      max-width: 500px;
      display: inline-block;
      margin-top: 50px;
      box-shadow: 0px 2px 10px @muted-text;
      border-radius: 5px;
    }
    img[src*='white']{
      background: @blue-00;
    }
    .btns {
      margin-top: -20px;
    }
  }
  .table-bordered {
    background-color: #fff;
  }
  // Do NOT DELETE //////////////////
  // #promo-ad-sets {
  //   .panel {
  //     .make-sm-column(6);
  //     &:nth-child(2n+1) {
  //       clear: both;
  //     }
  //     p, li {
  //       font-size: 1em;
  //     }
  //     .btn {
  //       // width: 100%;
  //     }
  //     .banner-wrap {
  //       display: block;
  //       position: relative;
  //       .transition(all .3s);
  //       .overlay-hover;
  //     }
  //   }
  // }
}


// --------------------------------------------------------- Broadcast Distribution

#broadcast-distribution {

  h3 {
    font-family: @font-family-base;
    font-weight: 100;
    font-size: 1.5em;
  }
  h4 {
    text-transform: uppercase;
    font-family: @font-family-base;
    font-size: 1.2em;
    color: @muted-text;
    border-top: 1px solid @muted-background;
    padding-top: @grid-gutter-width/2;
    strong {
      color: @text-color;
    }
  }
  .section {
    padding-bottom: 1em;
  }
  .panel {
    .make-sm-column(6);
    background: transparent;
    &:nth-child(2n+1) {
      clear: both;
    }
    .box-shadow(none);
    margin-bottom:0;
    padding-top:0;
  }
  .table {
    > thead, > tbody {
      > tr {
        > td, > th {
          @media (min-width: @screen-sm-min) {
            padding: 16px;
          }
        }
      }
    }
  }
}

// ------------------------------------------------------------ Broadcaster FAQs

#broadcaster-faqs {
  .question {

    p {
      margin-bottom:0;
      strong {
        font-size: 1.35em;
        font-family: @font-family-secondary;
        font-style: italic;
        line-height: 1.35em;
        display: block;
        margin-bottom: 10px;
      }
    }
    margin-bottom: 1.5em;
  }
}

// ------------------------------------------------------------- SimplePath Modal

#simplePathModal {
  .modal-header {
    border-bottom: 0;
  }
}