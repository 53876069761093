/* Styles for Station Finder Page */
#stationfinder {

  #stationfinder-search {
    padding: 6px 36px;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    max-width: 140px;
    margin-left: 5px;
    @media (max-width: @screen-sm-max) {
      width: inherit;
      float: right;
      margin-top: 4px;
    }
  }
  #stationfinder-country-form .input-wrapper:before {
    content: none;
  }

  #stationfinder-input {
    width: 100%
  }
  .country_select {
    text-align: right;
    margin: 0 0 5px;
  }
  .find_zip {
    margin-top: -15px;
    font-size: 12px;
  }
}

.how_do_you_listen_box {
  .station_information {
    width: 75%;
    float: left;
    .station_details {
      font-weight: bold;
      .station_details__location::before {
        content: " - ";
      }
    }
  }
}


@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .how_do_you_listen_box {
    .station_information {
      width: 100%;
      float: none;
    }
    .change_link {
      width: 100%;
    }
  }
}

.stationfinder {
  .jumbotron {
    background: url('/static/landing/stationfinder/bg-map.png') no-repeat center bottom;
    padding-top: @grid-gutter-width*2;
    padding-bottom: @grid-gutter-width*2;
    position: relative;
    background-size: cover;
    min-height: 120px;
    // @media (max-width: @screen-xs-max) {

    // }
    // @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {

    // }
    h1 {
      color: @muted-text;
      position: relative;
      z-index: 2;
      font-size: 4em;
      font-weight: 100;
      margin-bottom: 30px;
      .ti-map-marker-wide {
        position: relative;
        top: 8px;
        height: 60px;
        width: 60px;
      }
      @media (max-width: @screen-xs-max) {
        font-size: 2em;
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {

        font-size: 2.3em;
        .visible-xs {
          display: inline-block !important;
        }
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        font-size: 3em;
      }
    }
    &:after {
      content: "";
      display: block;
      height: 100%;
      width:100%;
      background-color: rgba(255,255,255,.6);
      position: absolute;
      top:0;
      left: 0;
      z-index: 1;
    }
    .stationfinder-form {
      .make-xs-column(8);
      .make-xs-column-push(2);
      .make-md-column(4);
      .make-md-column-push(4);
      position: relative;
      z-index: 2;
      padding: 0;
      border: none;
      background-color: transparent;
      .form-control {
        padding: 20px;
        height: auto;
        text-transform: lowercase;
        border-color: @border-color;
        margin-bottom: 6px;
        font-weight: 100;
        font-size: 24px;
        color: @muted-text;
      }
      .form-links {
        position: relative;
        z-index: 2;
        > a {
          color: @muted-text;
          &.not-us {
            float: left;
          }
          &.find-zip {
            float: right;
          }
        }
      }
    }
  }
  .station_results_container {
    h3 {
      color: @muted-text;
      font-size: 48px;
      font-weight: 100;
      @media (max-width: @screen-sm-max) {
        font-size: 2em;
      }
    }
    small.results {
      font-size: 120%;
    }
  }
  .podcast-container {
    padding-top: 0;
    .main-content-support {
      &:before {
        border-top: none;
      }
    }
  }
  .sidebar-wrapper {
    h3 {
      color: @muted-text;
      font-size: 30px;
      font-weight: 200;
    }
  }
}

/* Styles for Station Picker */
#stationModal {
  .modal-dialog {
    height: 85%;
  }
  .modal-body {
    ul {
      max-height: 380px;
      min-height: 200px;
      overflow-y: scroll;
      border-bottom: 1px solid @border-color;
    }
    .station_state_filter {
      text-align: center;
      .btn {
        margin-bottom: 3px;
      }
    }
  }
  #stationletusknow {
    white-space: inherit;
  }
  .hidestate {
    display: none;
  }
  .list-group-item:hover {
    background-color: @muted-background;
    text-decoration: none;
    #gradient.vertical(@white-darken-01; @white-darken-02; 0%; 100%);
    background-repeat: repeat-x;
  }
  .location_request {
    display:none;
    .input-group {
      margin: 0px auto;
      width: 90%;
      label {
        text-align: left;
      }
      input {
        width: 70%;
        float:left;
      }
      .btn {
      float: left;
      margin-left: 5px;
      }
    }
  }
  .station_change_country {
    float: right;
    margin-right: 15px;
    font-family: 'Gotham SSm A','Gotham SSm B',"Helvetica Neue",Helvetica,Arial,sans-serif;
  }
  @media (max-width: @screen-tiny-max) {
    .modal-dialog {
      height: 95%;
    }
    .modal-body {
      .stationpick {
        li {
          font-size: 80%;
        }
      }
    }
    .modal-header {
      .station_change_country {
        float: none;
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
}
@media (max-width: @screen-tiny-max) {
  .local_stations {
    li {
      font-size: 85%;
    }
  }
  .station_btn_lbl {
    span {
      font-size: 80%;
    }
  }
  .radio_information_btn {
    font-size: 85%;
  }
}
#onetime_ccradiotab {
  .input-group {
    width: 100%;
  }
}
.local_stations_wrap,
.stationpick {
  padding:0;
  ul.radio {
    padding-top: 0;
  }
  li {
    font-size: 1em;
    border:0;
    border-bottom: 1px dotted darken(@muted-background, 5%);

    label {
      font-weight: normal;
      margin: 0;
    }
  }
  li.grey {
    background: lighten(@muted-background, 2%);
    border-bottom: none;
    margin-bottom: 10.5px !important;
    .transition(all .3s);
    &:hover {
      background: @muted-background;
    }
  }
  .list-group-item {
    background-color: transparent;
    label {
      span {
        color: @btn-default-color;
      }
    }
  }
}
.remove_station {
  cursor: pointer;
  color: lighten(@muted-text, 18%);
}

#stationCountryModal {
  margin-top: 150px;
  .input-group {
    width: 100%;
    margin-bottom: 10px;
  }
  .station_modal_country_zip {
    input {
      margin-right:135px;
    }
    .btn {
      min-width: 135px;
      margin-left:10px;
      margin-top: 10px;
      float:right;
    }
  }
}
.how_do_you_listen_picker {
  .online_stations {
    li {
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
  label.control-label {
    text-align: left;
  }
  .input-group {
    display: block;
  }
  .alert-danger {
    margin-top: 10px;
  }
}

/* store checkout specific rules */
#checkoutform {
  .listen-info__label {
    text-align: left;
    margin-bottom: 5px;
  }
  .how_do_you_listen_picker {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .select_station_lbl {
    padding-top: 30px;
  }
}

/* SE Profile specific rules */
.account-profile-form {
  .stationpicker-wrapper {
    label {
      text-align: left;
    }
  }
}
