// ------------------------------------------------------------------------- Single Page

.header-support {
    .make-row();
    margin-right: 0px;
    position: relative;

    @media (max-width: @screen-xs-max) {
        margin: 0 -30px;
        padding: 0 15px;
    }
}

.header-support:after {
    content: "";
    display: block;
    padding-top: 3px;
    margin-left: 15px;


    @media (max-width: @screen-xs-max) {
        margin-right: 15px;
    }

    @media (min-width: @screen-md-min) {
        margin-right: 0;
    }

}

.main-content-support {
    .make-row();
    padding-bottom: @line-height-computed;
}

.main-content-support:before {
    content: "";
    display: block;
    border-top: 2px solid @taupe-lighten-04;
    margin-left: 15px;
    margin-right: 15px;


    @media (min-width: @screen-sm-min) {
        margin-right: 15px;
    }
}

.post-nav {
    margin-top: 10px;
}

.content-bump-up {
    padding-top: @line-height-computed !important;
}

body.full-width, .full-width {
    .main-content-wrapper {
        .make-sm-column(12);
    }
}

.main-content-wrapper {
    .make-sm-column(8);
    padding-top: @line-height-computed;
    p, address, li, cite {
        font-size: @font-size-base*1.15;
    }
    p.lead {
        font-size: @font-size-base*1.65;
    }
    @media (max-width: @screen-xs-max) {
        p, address, li, cite {
            font-size: @font-size-base*1.15;
        }
        p.lead {
            font-size: @font-size-base*1.75;
        }
    }
    ul,ol {
        margin: (@line-height-computed / 1.395) 0;
        li {
            margin-bottom: (@line-height-computed / 2);
            line-height: @line-height-base*1.3;
        }
    }
    .nav-tabs {
        > li {
            top:1px;
            margin-bottom: 0;
        }
    }
    hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @media (min-width: @screen-md-min) {
        padding-top: (@line-height-computed * 1.9);
    }
}

.sidebar-wrapper {
    .make-sm-column(4);
    .extra-padding-left;
    .extra-padding-right;
    color: @sidebar-nav-color;
    .promotion {
        margin: 0 0 @line-height-computed 0;
    }
    hr {
        margin: @line-height-computed 0;
    }
    h4 {
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            font-size: 1.2em;
        }
    }
}

.donate,
.wider {
    .main-content-wrapper {
        .make-sm-column(12);
        .make-md-column(8);
        .make-lg-column(9);
    }
    .sidebar-wrapper {
        .make-sm-column(12);
        .make-md-column(4);
        .make-lg-column(3);
    }
}

.article-title-wrapper {
    .make-xs-column(12);
    .section-title {
        .hidden-xs;

        .muted-text a{
            .muted-text;
        }
    }
    h1.article-title {
        margin-top: 0;
        margin-bottom: @line-height-computed*1.1;
        @media (max-width: @screen-xs-max) {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 26px;
        }
        .normal {
            font-size: .5em;
            font-family: @font-family-base;
            letter-spacing: 0;
            color: @muted-text;
            margin-left: @grid-gutter-width;
            > a {
                color: @link-color;
                &:hover {
                    color: @link-hover-color;
                }
            }
        }
    }

    .title-subscribe {
        .hidden-xs;
    }

    .sidebar-nav {

        @media (min-width: @screen-sm-min) {
            .hidden;
        }
        @media (max-width: @screen-xs-max) {
            display: block !important;
            visibility: visible;
        }
    }

    .volumes {
        margin-left: 15px;

        @media (max-width: @screen-xs-max) {
            margin-left: 0;
            display: block;
            height: 36px;
            margin-bottom: 5px;
        }
    }
}
@media (max-width: @screen-xs-max) {
    #subnav-toggle {
        margin-bottom: @grid-gutter-width/2;
        margin-top: 0;
    }
}
